import {
  Box,
  Button,
  Paper,
  Table,
  Text,
  Title,
} from "@mantine/core";
import React, {useContext, useEffect, useState} from "react";
import { SourceIcon } from "../../../../utils/SourceIcon";
import AddDomainRow from "./AddDomainRow";
import {
  fetchDomains,
} from "../../../../api/domains";
import { useAuth0 } from "@auth0/auth0-react";
import DomainRow from "./DomainRow";
import { showNotification } from "@mantine/notifications";
import SyncContext from "../../../../context/SyncContext";

interface DomainProps {
  accountId: string;
  name: string;
  value: string;
  _id: string;
}

function ProductDomain() {
  const [addDomainIsOpen, setAddDomainIsOpen] = useState(false);
  const auth0 = useAuth0();
  const [domains, setDomains] = useState<DomainProps[]>([]);
  const [addDomain, setAddDomain] = useState(null);
  const [removedRow, setRemovedRow] = useState(null);
  // const toggleRef = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(false);
  const { owners } = useContext(SyncContext)
  // shouldnt automatically be falso, should be prev choice

  const saveNotification = () => {
    showNotification({
      title: "Your settings have been saved!",
      message: "",
      icon: <SourceIcon sourceName="tick-circle" />,
      autoClose: 1200
    });
  };

  const getDomains = async () => {
    await fetchDomains(auth0).then((res) => {
      setDomains(res.domains);
    });
  };

  // const getToggleData = async () => {
  //   await fetchDomainToggle(auth0).then((res)=>{
  //     const toggle = res?.toggle[0]?.useDomains
  //     setChecked(toggle)
  //   })
  // }

  // const sendToggleData = async (useDomains: boolean) => {
  //   try {
  //     const body = { useDomains };
  //     await upsertDomainToggle(auth0, body).then((res) => {
  //       saveNotification();
  //     });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const handleToggleChange = (event: any) => {
  //   setChecked(event.currentTarget.checked);
  //   sendToggleData(event.currentTarget.checked);

  // };

  // const handleDomainToggle = () => {
  //   console.log({checked})
  //   sendToggleData(checked);
  // };

  // useEffect(() => {
  //   setLoading(true);
  //   getToggleData()
  // }, []);

  useEffect(() => {
    getDomains();
  }, [addDomain, removedRow]);

  return (
    <Paper mt={"lg"} withBorder>
      <Title
        order={3}
        ml="sm"
        pt={"md"}
        pl={"sm"}
        pb={"xl"}
        mb={0}
        sx={{ borderBottom: "1px solid #F1F1F1" }}
      >
        <Text mb={"lg"}>Product Domains</Text>
        {/* <Switch
          size="md"
          onLabel="YES"
          offLabel="NO"
          label="Use Domains for Ideas"
          color="violet"
          // ref={toggleRef}
          checked={checked}
          onChange={(event) => handleToggleChange(event)}
        /> */}
      </Title>
      <Table>
        <thead>
          <tr style={{ backgroundColor: "#f3f3f3" }}>
            <th
              style={{ width: "150px", padding: "12px", textAlign: "center" }}
            >
              Product domain
            </th>
            <th style={{ width: "150px", textAlign: "center" }}>Description</th>
            <th style={{ width: "150px", textAlign: "center" }}>Owner</th>
            <th
              style={{
                width: "60px",
                padding: "12px",
                textAlign: "center",
              }}
            ></th>
          </tr>
        </thead>
        <tbody>
          {domains &&
            domains.map((domain: any) => {
              return (
                <DomainRow
                  domain={domain}
                  members={owners}
                  setRemovedRow={setRemovedRow}
                  key={domain._id}
                />
              );
            })}
          {addDomainIsOpen === true && (
            <AddDomainRow
              members={owners}
              setAddDomainIsOpen={setAddDomainIsOpen}
              setAddDomain={setAddDomain}
              //   setAddFormContent={setAddFormContent}
            />
          )}
        </tbody>
      </Table>
      <Box
        px="xl"
        py="md"
        sx={{
          borderTop: "1px solid #dee2e6",
        }}
      >
        <Button
          bg={"#5C5CEA"}
          sx={{
            fontWeight: 700,
            ":hover": { backgroundColor: "#9f9af8" },
            "&[disabled]": {
              pointerEvents: "all",
              backgroundColor: "#fff",
              cursor: "not-allowed",
              ":hover": {
                backgroundColor: "#fff",
              },
            },
          }}
          color={"#fff"}
          onClick={() => setAddDomainIsOpen(true)}
          disabled={addDomainIsOpen === true ? true : false}
        >
          + Add Domain
        </Button>
      </Box>
    </Paper>
  );
}

export default ProductDomain;
