import React from "react";
import { Group, Loader, Select } from "@mantine/core";
import { useCustomization } from "./_utils/CustomizationContext";

interface ObjectAttributeSelectProps {
  objectValue: string;
  onObjectChange: (value: string) => void;
  attributeValue: string;
  onAttributeChange: (value: string) => void;
  objectPlaceholder?: string;
  attributePlaceholder?: string;
  disabled?: boolean;
}

export const ObjectAttributeSelect: React.FC<ObjectAttributeSelectProps> = ({
  objectValue,
  onObjectChange,
  attributeValue,
  onAttributeChange,
  objectPlaceholder = "Select object",
  attributePlaceholder = "Select attribute",
  disabled = false,
}) => {
  const { customizationData, loading, error } = useCustomization();

  return (
    <Group grow>
      <Select
        data={customizationData ? Object.keys(customizationData) : []}
        sx={{
          "input:disabled": {
            color: "black",
            fontWeight: 600,
          },
        }}
        rightSection={loading.fetch && <Loader size={14} />}
        placeholder={objectPlaceholder}
        value={objectValue}
        onChange={onObjectChange}
        disabled={disabled}
        searchable
      />
      <Select
        data={
          customizationData && objectValue
            ? customizationData?.[objectValue] || []
            : []
        }
        sx={{
          "input:disabled": {
            color: "black",
            fontWeight: 600,
          },
        }}
        rightSection={loading.fetch && <Loader size={14} />}
        placeholder={attributePlaceholder}
        value={attributeValue}
        onChange={onAttributeChange}
        disabled={!customizationData?.[objectValue] || disabled}
        searchable
      />
    </Group>
  );
};
