import React from "react";
import { Table, ActionIcon, Tooltip, Group } from "@mantine/core";
import { IconPencil, IconTrash } from "@tabler/icons";

type TagsTableProps = {
  tags: any;
  onEdit: (tag: any) => void;
  onDelete: (tagId: string) => void;
};

const TagsTable: React.FC<TagsTableProps> = ({ tags, onEdit, onDelete }) => (
  <Table
    highlightOnHover
    withBorder
    sx={{
      tableLayout: "auto",
      width: "100%",
      th: {
        textAlign: "left",
        padding: "10px",
      },
      td: {
        padding: "10px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    }}
  >
    <thead>
      <tr style={{ backgroundColor: "#f3f3f3" }}>
        <th>Label</th>
        <th>Key</th>
        <th>Color</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {tags.map((tag: any) => (
        <tr key={tag._id}>
          <td>{tag.label}</td>
          <td>{tag.key || ""}</td>
          <td>
            <div
              style={{
                width: "24px",
                height: "24px",
                backgroundColor: tag.color,
                borderRadius: "50%",
              }}
            />
          </td>
          <td>
            <Group spacing="xs">
              <Tooltip label="Edit">
                <ActionIcon color="blue" onClick={() => onEdit(tag)}>
                  <IconPencil size={16} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Delete">
                <ActionIcon color="red" onClick={() => onDelete(tag._id)}>
                  <IconTrash size={16} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default TagsTable;
