import {
  Box,
  Flex,
  Modal,
  ScrollArea,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
  
import {  useState } from "react";
import { SourceIcon } from "../../../../../utils/SourceIcon";
import { stripHtml } from "../../../../../utils";
  
const ProductBoardTicket = ({ item }: { item: any }) => {
  
  const [opened, setOpened] = useState(false);
  const cxItemTitle = item?.chat?.title || item?.textItem?.title  || item?.title
  const cxItemText = item?.chat?.text || item?.textItem?.text || item?.text;
  const excerpt = item?.textItem?.excerpt || item?.excerpt
  const cxItem = item?.chat;
  const origin = cxItem?.trueOrigin || cxItem?.origin || item?.origin;
  const textItemTitle = item?.textItem?.title || item?.chat?.title || item?.title
  const textItemText = item?.textItem?.text || item?.chat?.text || item?.text
  
  return (
    <Box mt={15} maw='100%'>
      {opened && <Modal
        opened={opened}
        centered
        onClose={() => setOpened(false)}
        w={500}
        title={
          <Flex align="center" h={70} mx="sm">
            {origin && (
              <Tooltip label={origin || "N/A"}>
                <Stack h={20} w={20} mr={4} justify="center" align="center">
                  <SourceIcon sourceName={origin?.toLowerCase() || "N/A"} />
                </Stack>
              </Tooltip>
            )}
            <Flex pl={35} align="center" w={580}>
              <Tooltip label={cxItemTitle || textItemTitle || "N/A"}>
                <Text ml="3px" fz={17} maw={470} w={470} fw={600} lineClamp={1}>
                  {cxItemTitle || textItemTitle || "N/A"}
                </Text>
              </Tooltip>
            </Flex>
          </Flex>
        }
      >
        <Box h={600} px='md'>
          <Box w={578}>
            <Text px={12} fz={14} fw={600} lineClamp={1} mb={14}>
              {cxItemTitle}
            </Text>
          </Box>
          {cxItemText && (
            <Box
              py={8}
              px={12}
            >
              <Text
                fz={14}
                sx={{
                  whiteSpace: "pre-line",
                }}
              >
                {stripHtml(cxItemText)}
              </Text>
            </Box>
          )}
        </Box>
      </Modal>}
      {textItemTitle && (
        <Text fz={14} fw={600} lineClamp={1} mb={14}>
          {textItemTitle}
        </Text>
      )}
      <ScrollArea mt={15} /* h={"calc(100vh - 700px)"} mah="calc(100vh - 700px)" */>
          
        {textItemText && (
          <Box
            py={8}
            px={12}
            bg="#F8F9FA"
            sx={{ borderLeft: "2px solid rgba(92, 92, 235, 0.6)" }}
          >
            <Text
              fz={12}
              fw={600}
              color="#585C68"
              sx={{
                whiteSpace: "pre-line",
              }}
            >
              {stripHtml(textItemText)}
            </Text>
          </Box>
        )}
        {excerpt && excerpt !== "" && (
          <Box
            py={8}
            px={12}
            bg="#F8F9FA"
            sx={{ borderLeft: "2px solid rgba(92, 92, 235, 0.6)" }}
          >
            <Box fz={12}>
              <Text
                mb="5px"
                fw={600}
                style={{ color: "black", fontSize: "12.5px" }}
              >
                  Excerpt:{" "}
              </Text>
              <Text
                sx={{
                  whiteSpace: "pre-line",
                }}
              >
                {stripHtml(excerpt)}
              </Text>
            </Box>
          </Box>
        )}
      </ScrollArea>
      {
        <Box
          w='100%'
          py={8}
          px={12}
          bg="#F8F9FA"
          sx={{ borderLeft: "2px solid rgba(92, 92, 235, 0.6)" }}
        >
          <Text
            mt={10}
            fz={13}
            color="#5C5CEB"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setOpened(true)}
          >
              View full note
          </Text>
        </Box>
      }
    </Box>
  );
};
  
export default ProductBoardTicket;