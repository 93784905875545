import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import {Box, Button, Grid, Paper, Select, Table, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  JiraIntegrationUpdate,
  updateJiraIntegration
} from "../../../../api/integrations";

export const SetupLists = (props: {
  integration: any;
  customization?: any;
  variant?: "wizard" | "settings";
  reloadIntegration: () => void;
}) => {
  const auth0 = useAuth0();
  const form = useForm<{
    requestListId: string | undefined,
    solutionListId: string | undefined,
  }>({
    initialValues: {
      requestListId: props.integration.requestListId || undefined,
      solutionListId: props.integration.solutionListId || undefined
    },

    validate: {
      requestListId: (value) =>
        value !== null
          ? null
          : "please select the list for Ideas",
      solutionListId: (value) =>
        value !== null
          ? null
          : "please select the list for Ideas",
    },
  });
  const [loading, setLoading] = useState<boolean>(false);

  const isWizard = !props.variant || props.variant === "wizard";
  const saveRequestsClicked = () => {
    if (form.isValid()) {
      setLoading(true);
      const update: JiraIntegrationUpdate = {
        ...form.values
      };
      if (isWizard) {
        update.setupStep = 2;
      }
      updateJiraIntegration(props.integration._id, update, auth0)
        .then(() => {
          props.reloadIntegration();
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
  };

  const fieldsSection = (
    <form onSubmit={form.onSubmit(saveRequestsClicked)}>
      <Box>
        <Grid mt={"15px"}>
          <Grid.Col span={"content"} pl={"25px"}>
            <Text>Ideas</Text>
          </Grid.Col>
          <Grid.Col span={"content"}>
            <Select
              ml={"md"}
              placeholder="Select ideas list"
              searchable
              data={props.integration.info?.projects?.map((project: any) => { return {label: project.name, value: project.id}}) || []}
              {...form.getInputProps("requestListId")}
            />
          </Grid.Col>
        </Grid>
        <Grid mt={"15px"}>
          <Grid.Col span={"content"} pl={"25px"}>
            <Text>Solutions</Text>
          </Grid.Col>
          <Grid.Col span={"content"}>
            <Select
              ml={"md"}
              placeholder="Select solutions list"
              searchable
              data={props.integration.info?.projects?.map((project: any) => { return {label: project.name, value: project.id}}) || []}
              {...form.getInputProps("solutionListId")}
            />
          </Grid.Col>
        </Grid>
      </Box>
      <Button
        mt={"md"}
        ml={isWizard ? "0" : "lg"}
        loading={loading}
        type="submit"
      >
        Save
      </Button>
    </form>
  )


  return (
    <>
      {isWizard ? (
        fieldsSection
      ) : (
        <Paper mt={"44px"} shadow={"lg"} withBorder>
          <Grid p={0} m={0}>
            <Grid.Col span={2} p={"xl"}>
              <Text weight={700} color={"#5C5CEA"} size={"md"}>
                Solutions & Ideas Lists
              </Text>
              <Text size={"sm"} mt={"sm"} mb={"sm"}>
                Select Lists to use for solutions and ideas
              </Text>
            </Grid.Col>
            <Grid.Col
              span={"content"}
              p={"xl"}
              sx={{borderLeft: "1px solid rgb(222, 226, 230)"}}
            >
              {fieldsSection}
            </Grid.Col>
          </Grid>
        </Paper>
      )}
    </>
  )
}