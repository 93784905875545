import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

export const useAuthHeaders = () => {
  const { getAccessTokenSilently } = useAuth0();

  const getAuthHeaders = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      return { headers: { Authorization: `Bearer ${token}` } };
    } catch (error) {
      console.error('Error fetching Auth0 token:', error);
      throw error;
    }
  }, [getAccessTokenSilently]);

  return { getAuthHeaders };
};
