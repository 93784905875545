import React, { useState, useEffect, useRef } from "react";
import {
  Menu,
  Checkbox,
  Text,
  UnstyledButton,
  Group,
  Button,
  Box,
} from "@mantine/core";
import { IconTag, IconX } from "@tabler/icons";
import { OverflownText } from "../OverflownText";

interface TagOption {
  value: string;
  label: string;
  color: string;
}

interface TagMenuProps {
  selectedTags: string[];
  onTagToggle: (tagId: string) => void;
  tagsOptions: TagOption[];
  onClose?: () => void;
  colTitle: string;
  showAddBtn?: boolean;
}

const TagMenu: React.FC<TagMenuProps> = ({
  selectedTags,
  onTagToggle,
  tagsOptions,
  onClose,
  colTitle,
  showAddBtn = false,

}) => {
  const [visibleTags, setVisibleTags] = useState<TagOption[]>([]);
  const [overflowCount, setOverflowCount] = useState<number>(0);
  const targetRef = useRef<HTMLButtonElement>(null);
  const [menuOpened, setMenuOpened] = useState(false);

  useEffect(() => {
    const updateVisibleTags = () => {
      if (!targetRef.current) return;

      const containerWidth = targetRef.current.offsetWidth;
      let totalWidth = 0;
      const visible: TagOption[] = [];
      let overflow = 0;

      selectedTags.forEach((tagId) => {
        const tag = tagsOptions.find((tag) => tag.value === tagId);
        if (tag) {
          const tagWidth = Math.min(tag.label.length * 8 + 24, 100);
          if (totalWidth + tagWidth <= containerWidth - 30) {
            visible.push(tag);
            totalWidth += tagWidth;
          } else {
            overflow += 1;
          }
        }
      });

      setVisibleTags(visible);
      setOverflowCount(overflow);
    };

    updateVisibleTags();
    window.addEventListener("resize", updateVisibleTags);
    return () => window.removeEventListener("resize", updateVisibleTags);
  }, [selectedTags, tagsOptions]);

  return (
    <Menu
      position="bottom-start"
      shadow="sm"
      opened={menuOpened}
      onChange={setMenuOpened}
    >
      <Menu.Target>
        <UnstyledButton
          style={{ width: "100%", textAlign: "left" }}
          ref={targetRef}
          onClick={() => setMenuOpened((o) => !o)}
        >
          <Group
            noWrap
            spacing={6}
            sx={{
              "&:hover": {
                backgroundColor: "#F8F8FB",
              },
              width: "min-content",
              textAlign: "left",
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            <Group
              spacing={6}
              noWrap
              style={{
                flexWrap: "nowrap",
                overflow: "hidden",
                width: "min-content",
              }}
            >
              {visibleTags.map((tag) => (
                <OverflownText
                  key={tag.value}
                  size="sm"
                  style={{
                    backgroundColor:
                      tag.color === "#585C68" ? "#EFEFFD" : `${tag.color}20`,
                    color: tag.color,
                    padding: "0px 8px",
                    borderRadius: "4px",
                    display: "inline-block",
                    maxWidth: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    border: "1px solid transparent"
                  }}
                  // title={tag.label}
                >
                  {tag.label}
                </OverflownText>
              ))}
              {overflowCount > 0 && (
                <Text
                  size="sm"
                  style={{
                    color: "#5C5CEB",
                    marginLeft: 4,
                    whiteSpace: "nowrap",
                  }}
                >
                  +{overflowCount}
                </Text>
              )}
              {showAddBtn && (
                <UnstyledButton
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2px",
                    padding: "0px 4px",
                    borderRadius: 4,
                    color: "#5B60E3",
                    fontWeight: 500,
                    width: 82,
                  }}
                  onClick={() => setMenuOpened((o) => !o)}
                >
                  <IconTag size={12} strokeWidth={2} color="#5B60E3" />
                  <Text lineClamp={1} size="sm">
                    Add tag
                  </Text>
                </UnstyledButton>
              )}
              {selectedTags.length === 0 && (
                <Text
                  w="max-content"
                  size="sm"
                  color="#aeaeae"
                  lineClamp={1}
                  px={3}
                  sx={{
                    border:"1px solid transparent",
                    borderRadius: 4
                  }}
                >
                    Select {colTitle.toLowerCase()}
                </Text>
              )}
            </Group>
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown miw={200} p={0}>
        {tagsOptions.map((tag, index) => (
          <Menu.Item
            key={tag.value}
            w={"-webkit-fill-available"}
            p={8}
            m={4}
            mb={index !== tagsOptions.length - 1 ? 4 : 0}
            onClick={() => onTagToggle(tag.value)}
            closeMenuOnClick={false}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={selectedTags.includes(tag.value)}
                onChange={() => onTagToggle(tag.value)}
                size="sm"
                color="indigo"
                style={{ marginRight: 10 }}
              />
              <Text
                size="sm"
                style={{
                  backgroundColor:
                    tag.color === "#585C68" ? "#EFEFFD" : `${tag.color}20`,
                  color: tag.color,
                  padding: "0px 4px",
                  borderRadius: "4px",
                  display: "inline-block",
                  maxWidth: 300,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                title={tag.label}
              >
                {tag.label}
              </Text>
            </div>
          </Menu.Item>
        ))}
        {tagsOptions.length === 0 && (
          <Menu.Item
            p={4}
            m={4}
            w={"-webkit-fill-available"}
            closeMenuOnClick={false}
          >
            <Text
              size="sm"
              color="#5C5CEB"
              component="a"
              href={"/settings/tags"}
              target="_blank"
              style={{
                color: "#5C5CEB",
                textDecoration: "none",
              }}
            >
              Create new {colTitle.toLowerCase()} +
            </Text>
          </Menu.Item>
        )}
        <Box
          sx={{
            borderRadius: "0 0 4px 4px",
            textAlignLast: "right",
            background: "#F8F9FA",
          }}
          w={"-webkit-fill-available"}
          mt={4}
          p={8}
        >
          <Button
            onClick={() => {
              setMenuOpened(false);
              onClose?.();
            }}
            styles={(theme) => ({
              root: {
                backgroundColor: "#5B60E3",
                border: 0,
                height: 32,
                fontSize: 12,
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: theme.fn.darken("#5B60E3", 0.05),
                },
              },
              label: {
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            })}
            leftIcon={<IconX size={15} />}
          >
            Close
          </Button>
        </Box>
      </Menu.Dropdown>
    </Menu>
  );
};

export default TagMenu;
