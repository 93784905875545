import { Avatar, Box, Flex, Tooltip, Text } from '@mantine/core';
import { getInitials } from '../../../../../../utils/getInitials';
import ReactTimeAgo from 'react-time-ago';
import { stripHtml } from '../../../../../../utils';

const CommentWithExcerpts = ({ sortedItem, excerpts }: { sortedItem: any, excerpts: any[] }) => {
  const text = stripHtml(sortedItem?.text || '');

  // Function to merge overlapping or contiguous ranges
  const mergeRanges = (ranges: any[]) => {
    if (ranges.length === 0) return [];

    // Sort ranges by start position
    ranges.sort((a, b) => a.excerptFirstChar - b.excerptFirstChar);

    const mergedRanges = [ranges[0]];

    for (let i = 1; i < ranges.length; i++) {
      const lastRange = mergedRanges[mergedRanges.length - 1];
      const currentRange = ranges[i];

      if (currentRange.excerptFirstChar <= lastRange.excerptLastChar + 1) {
        // Merge overlapping or contiguous ranges
        lastRange.excerptLastChar = Math.max(lastRange.excerptLastChar, currentRange.excerptLastChar);
      } else {
        mergedRanges.push(currentRange);
      }
    }

    return mergedRanges;
  };

  // Function to highlight excerpts in the text
  const highlightExcerpts = (text: string, excerpts: any[]) => {
    const mergedExcerpts = mergeRanges(excerpts);
    const highlightedText = [];
    let lastIndex = 0;

    mergedExcerpts.forEach((excerpt, index) => {
      const { excerptFirstChar, excerptLastChar } = excerpt;

      // Add text before the excerpt
      if (excerptFirstChar > lastIndex) {
        highlightedText.push(
          <span key={`before-${index}`}>
            {text.slice(lastIndex, excerptFirstChar)}
          </span>
        );
      }

      // Add the excerpt with background
      highlightedText.push(
        <span
          key={`excerpt-${index}`}
          style={{ backgroundColor: "#FFF9A0", color: 'black', borderRadius: 6 }}
        >
          {text.slice(excerptFirstChar, excerptLastChar + 1)}
        </span>
      );

      lastIndex = excerptLastChar + 1;
    });

    // Add remaining text after the last excerpt
    if (lastIndex < text.length) {
      highlightedText.push(
        <span key="after-last">
          {text.slice(lastIndex)}
        </span>
      );
    }

    return highlightedText;
  };

  return (
    <>
      {sortedItem.text && (
        <Flex ml={35} mt={5}>
          <Box mr="xs" w="33px" pt="4.4px">
            {sortedItem?.fromUser?.picture ? (
              <Tooltip
                label={
                  sortedItem?.fromUser?.name || sortedItem?.fromName || "N/A"
                }
              >
                <Avatar
                  src={sortedItem?.fromUser?.picture}
                  radius="xl"
                  size={40}
                >
                  {sortedItem?.fromName &&
                      getInitials(sortedItem?.fromName || "")}
                </Avatar>
              </Tooltip>
            ) : (
              <Tooltip
                label={
                  sortedItem?.fromUser?.name || sortedItem?.fromName || "Unknown user"
                }
              >
                <Avatar radius="xl" size={40}  color="dark" />
              </Tooltip>
            )}
          </Box>
          <Box px={10} py={8} sx={{ borderRadius: "11px" }}>
            <Flex h={25} justify="space-between" w={600} mb={8}>
              <Flex>
                <Flex>
                  {sortedItem?.fromName ? (
                    <Text fw={600} fz={16} >
                      {sortedItem?.fromName}
                    </Text>
                  ): 
                    <Text fw={600} fz={16} h={25}>
                       
                    </Text>}
                  {sortedItem?.fromEmail ? (
                    <Text fz={16} h={25}>{`<${sortedItem?.fromEmail}>`}</Text>
                  ): <Text fz={16} h={25}></Text>}
                </Flex>
              </Flex>
              {
                sortedItem.createdAt && (
                  <Text size="xs" c="dimmed">
                    {sortedItem?.createdAt && (
                      <ReactTimeAgo title="" date={new Date(sortedItem?.createdAt)} />
                    )}
                  </Text>
                )}
            </Flex>
            <Flex h={25} mb={4}>
              {sortedItem?.toName ? (
                <>
                  <Text fw={600} fz={16} mr="xs" >
                      To:
                  </Text>
                  <Text fw={600} fz={16} h={25}>
                    {sortedItem?.toName}
                  </Text>
                </>
              ) : <Text fw={600} fz={16}> </Text>
              }
              {sortedItem?.toEmail ? (
                <Text fz={16} h={25}>{`<${sortedItem?.toEmail}>`}</Text>
              ):<Text fz={16} h={25}></Text>}
            </Flex>
            <Flex justify="space-between" fz={16} mt={7} mb={16}>
              <Text w={470} maw={470} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {highlightExcerpts(text, excerpts)}
              </Text>
            </Flex>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default CommentWithExcerpts;