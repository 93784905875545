import axios from "axios";
import {getAuthHeaders} from "../Api";

export interface ProductArea {
  _id?: string;
  name: string;
  description: string;
  domain: string;
  index: number;
  accountId: string;
  ownerId?: string;
  ownerName?: string;
}

export interface CreateProductArea {
  name: string;
  description: string;
  domain: string;
  index: number;
  ownerId?: string;
  ownerName?: string;
}

export interface UpdateProductArea {
  description?: string,
  domain?: string,
  name?: string,
  ownerId?: string,
  ownerName?: string,
  index?: number,
}

const ROUTE_PATH = "product-areas";
export const deleteProductArea = (
  oldItemId: string,
  newItemId: any,
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.delete(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/${ROUTE_PATH}/${oldItemId}`,
        {...config, data: { productAreaForReassignment: newItemId } }
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};



export const listProductAreas = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/${ROUTE_PATH}`,
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const updateProductArea = (
  auth0: any,
  id: string,
  update: UpdateProductArea
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.put(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + `/${ROUTE_PATH}/${id}`,
        update,
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const createProductArea = (
  auth0: any,
  productArea: CreateProductArea
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      console.log({productArea})
      const response = await axios.post(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/${ROUTE_PATH}`,
        productArea,
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const changeParent = (
  auth0: any,
  body: { componentId: string; child: boolean }
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.put(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/${ROUTE_PATH}/reassign`,
        body,
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};
