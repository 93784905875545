import { Buffer } from "buffer";
import { Button, Text, TextInput } from "@mantine/core";
import { useState } from "react";

export const SetupAuth = () => {

  const getAuthUri = () => {
    const metadata = { integrationType: "gong" };
    const base64State = Buffer.from(JSON.stringify(metadata)).toString("base64");
    return `https://app.gong.io/oauth2/authorize?client_id=${
      window.__RUNTIME_CONFIG__.REACT_APP_GONG_CLI_ID
    }&response_type=code&scope=${
      window.__RUNTIME_CONFIG__.REACT_APP_GONG_SCOPES
    }&redirect_uri=${
      "" + window.__RUNTIME_CONFIG__.REACT_APP_OAUTH_CALLBACK
    }&state=${base64State}`;
  };

  return (
    <>
      <Button mt={"md"} component={"a"} href={getAuthUri()}>
        Authorize
      </Button>
    </>
  );
};
