import {
  ActionIcon,
  Button,
  Flex,
  Grid,
  Paper,
  Select,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import SalesforceIcon from "../../../../utils/icons/SalesforceIcon";
import { IconX } from "@tabler/icons";
import React, {useContext, useEffect, useState} from "react";
import {
  SFIntegrationUpdate,
  updateSalesforceIntegration,
} from "../../../../api/integrations";
import { useAuth0 } from "@auth0/auth0-react";
import { useForm } from "@mantine/form";
import LogoIcon from "../../../layouts/LogoIcon";
import SyncContext from "../../../../context/SyncContext";

export const SetupAccounts = (props: {
  integration: any;
  variant?: "wizard" | "settings";
  reloadIntegration: () => void;
}) => {
  const auth0 = useAuth0();
  const { objectCustomizations } = useContext(SyncContext);
  const accountCustomizations = objectCustomizations.account
  const form = useForm({
    initialValues: {
      arr: accountCustomizations?.fields?.arr?.name || null,
      plan: accountCustomizations?.fields?.plan?.name || null,
      tier: accountCustomizations?.fields?.tier?.name || null,
    },
    validate: {
      arr: (value) =>
        value !== null
          ? null
          : "please select amount field to use for Curr. ARR",
      tier: (value) =>
        value !== null ? null : "please select tier field name",
      plan: (value) =>
        value !== null ? null : "please select plan field name",
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [blackListedAccountIds, setBlackListedAccountIds] = useState<string[]>(
    props.integration.blackListedAccountIds
      ? props.integration.blackListedAccountIds
      : []
  );
  const [accountTypesMap, setAccountTypesMap] = useState<{[name: string]: string}>(accountCustomizations?.accountTypesMap || {})
  const [blacklistAccountInputValue, setBlacklistAccountInputValue] =
    useState<string>("");


  const addBlacklistedClicked = () => {
    if (
      blacklistAccountInputValue.length > 0 &&
      blackListedAccountIds.indexOf(blacklistAccountInputValue) === -1
    ) {
      setBlackListedAccountIds([
        ...blackListedAccountIds,
        blacklistAccountInputValue,
      ]);
      setBlacklistAccountInputValue("");
    }
  };

  const removeBlacklistedClicked = (accountId: string) => {
    setBlackListedAccountIds([
      ...blackListedAccountIds.filter((id) => id !== accountId),
    ]);
  };
  const isWizard = !props.variant || props.variant === "wizard";
  const saveAccountClicked = () => {
    if (form.isValid()) {
      setLoading(true);
      const update: SFIntegrationUpdate = {
        accountFields: { ...form.values },
        accountTypesMap,
        blackListedAccountIds,
      };
      if (isWizard) {
        update.setupStep = 2;
      }
      updateSalesforceIntegration(props.integration._id, update, auth0)
        .then(() => {
          props.reloadIntegration();
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
  };
  const onAccountTypeSelectChange = (accountType: string, value: string) => {
    const accountTypes = {...accountTypesMap}
    accountTypes[accountType] = value
    setAccountTypesMap(accountTypes)
  };

  const fieldsSection = (
    <>
      <Text mt="md" color={"rgba(22, 24, 50, 0.8)"}>
        Select the amount field to use for Curr. ARR
      </Text>
      <Grid mt={"md"}>
        <Grid.Col span={"content"} pl={"md"}>
          <SalesforceIcon width={"30"} height={"auto"} />
        </Grid.Col>
        <Grid.Col span={"content"}>
          <Select
            ml={"md"}
            searchable
            clearable
            placeholder="Select Amount field"
            data={props.integration.info.accounts.fieldNames}
            {...form.getInputProps("arr")}
          />
        </Grid.Col>
      </Grid>

      <Text mt="md" color={"rgba(22, 24, 50, 0.8)"}>
        Plan field name
      </Text>
      <Grid mt={"md"}>
        <Grid.Col span={"content"} pl={"md"}>
          <SalesforceIcon width={"30"} height={"auto"} />
        </Grid.Col>
        <Grid.Col span={"content"}>
          <Select
            ml={"md"}
            searchable
            clearable
            placeholder="Select plan field"
            data={props.integration.info.accounts.fieldNames}
            {...form.getInputProps("plan")}
          />
        </Grid.Col>
      </Grid>

      <Text mt="md" color={"rgba(22, 24, 50, 0.8)"}>
        Tier field name
      </Text>
      <Grid mt={"md"}>
        <Grid.Col span={"content"} pl={"md"}>
          <SalesforceIcon width={"30"} height={"auto"} />
        </Grid.Col>
        <Grid.Col span={"content"}>
          <Select
            ml={"md"}
            searchable
            clearable
            placeholder="Select tier field"
            data={props.integration.info.accounts.fieldNames}
            {...form.getInputProps("tier")}
          />
        </Grid.Col>
      </Grid>
    </>
  );

  const accountTypeSection = (
    <>
      <Text mt={"lg"} ml={isWizard ? "0" : "lg"}>
        Map account types
      </Text>
      <Table
        mt={"md"}
        sx={{
          thead: {
            background: "#F3F4F6",
          },
          "tr:first-child": {
            borderTop: 0,
            fontSize: "12px",
          },
          "tbody tr td": {
            borderBottom: "1px solid #fff",
            fontSize: "12px",
          },
        }}
      >
        <thead>
          <tr>
            <th>
              <Flex>
                <SalesforceIcon width={"30"} height={"auto"} />
                <Text ml={"md"}>Account Type</Text>
              </Flex>
            </th>
            <th>
              <Flex>
                <LogoIcon />
                <Text ml={"md"} mt={isWizard ? "0" : "5px"}>
                Account Type
                </Text>
              </Flex>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.integration.info.accounts?.accountTypeValues?.map(
            (accountType: string) => (
              <tr key={accountType}>
                <td>
                  <Text>{accountType}</Text>
                </td>
                <td>
                  <Select
                    ml={"md"}
                    sx={{ width: "200px" }}
                    placeholder="Choose One..."
                    value={ accountTypesMap[accountType] || "Customer" }
                    defaultValue={"Customer"}
                    data={["Customer", "Prospect", "Churned"]}
                    onChange={(value) =>
                      onAccountTypeSelectChange(accountType, `${value}`)
                    }
                  />
                </td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    </>
  );

  const blackListSection = (
    <>
      <Text mt={"lg"} ml={isWizard ? "0" : "lg"}>
        Accounts to exclude:
      </Text>
      <Table
        mt={"md"}
        sx={{
          thead: {
            borderTop: "1px solid #dee2e6",
            height: "54px",
            background: "#F3F4F6",
          },
          // 'thead tr th':{
          //   color: "#6875F5",
          //   fontSize: "12px",
          // },
          "tr:first-child": {
            borderTop: 0,
            fontSize: "12px",
          },
          "tbody tr td": {
            borderBottom: "1px solid #fff",
            fontSize: "12px",
          },
        }}
      >
        <thead>
          <tr>
            <th>
              <Flex>
                <SalesforceIcon width={"30"} height={"auto"} />
                <Text ml={"md"}>Account ID</Text>
              </Flex>
            </th>
          </tr>
        </thead>
        <tbody>
          {blackListedAccountIds.map((accountId) => (
            <tr key={accountId}>
              <td>
                <Flex>
                  <Text size={"md"} color={"rgba(22, 24, 50, 0.8)"}>
                    {accountId}
                  </Text>
                  <ActionIcon ml={"md"}>
                    <IconX
                      onClick={() => removeBlacklistedClicked(accountId)}
                    />
                  </ActionIcon>
                </Flex>
              </td>
            </tr>
          ))}
          <tr>
            <td>
              <Flex justify="space-between" align="flex-end">
                <TextInput
                  ml={"md"}
                  size={"md"}
                  placeholder={"e.g. 001Dn0000092a86IAA"}
                  value={blacklistAccountInputValue}
                  onChange={(e) =>
                    setBlacklistAccountInputValue(e.currentTarget.value)
                  }
                />
                <Button
                  variant={"subtle"}
                  mr={"50px"}
                  onClick={() => addBlacklistedClicked()}
                >
                  + Add Record
                </Button>
              </Flex>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
  return (
    <form onSubmit={form.onSubmit(saveAccountClicked)}>
      {isWizard ? (
        fieldsSection
      ) : (
        <Paper mt={"44px"} shadow={"lg"} withBorder>
          <Grid m={0} p={0}>
            <Grid.Col span={2} p={"xl"}>
              <Text weight={700} color={"#5C5CEA"} size={"md"}>
                Mapping Rules
              </Text>
              <Text size={"sm"} mt={"sm"} mb={"sm"}>
                Configure field mapping rules
              </Text>
            </Grid.Col>
            <Grid.Col
              span={"content"}
              p={"xl"}
              sx={{ borderLeft: "1px solid rgb(222, 226, 230)" }}
            >
              {fieldsSection}
            </Grid.Col>
          </Grid>
        </Paper>
      )}
      {isWizard ? (
        accountTypeSection
      ) : (
        <>
          <Paper mt={"44px"} p={0} shadow={"lg"} withBorder pb={"sm"}>
            {accountTypeSection}
          </Paper>
        </>
      )}
      {isWizard ? (
        blackListSection
      ) : (
        <>
          <Paper mt={"44px"} p={0} shadow={"lg"} withBorder pb={"sm"}>
            {blackListSection}
          </Paper>
        </>
      )}
      <Button mt={"md"} type="submit" loading={loading}>
        Save
      </Button>
    </form>
  );
};
