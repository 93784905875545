import { Box, Flex, Stack, Text } from "@mantine/core";
import HourDisplay from "../../HourDisplay";

const GongCallPart = ({
  callItem,
  createdAtDate,
}: {
  callItem: any;
  createdAtDate: any;
}) => {
  return (
    <Box
      w={620}
      px="sm"
      bg={callItem.isExcerpt ? "#FFFDD4" : ""}
      py="xs"
      sx={{ borderRadius: "6px" }}
      my={6}
    >
      <Flex>
        <Stack align="start" spacing={0}>
          <HourDisplay
            date={createdAtDate}
            milliseconds={callItem?.startMs}
            link={callItem?.link}
          />
        </Stack>
        <Text fw={400} color="dimmed" fz={13}>
          {callItem?.speakerName || callItem?.speakerEmail || ""}
        </Text>
      </Flex>
      <Stack align="start" spacing={0} ml={50} mt="3px">
        <Text align="left" w={540} fz={14} maw={540} style={{ overflow: 'hidden'}}>
          {callItem?.cleanText || ""}
        </Text>
      </Stack>
    </Box>
  );
};

export default GongCallPart;
