import { DataTableSortStatus } from "mantine-datatable";
import { ReactNode } from "react";

export const SolutionStatus: any = {
  new: "New",
  backlog: "Backlog",
  planned: "Planned",
  "in-progress": "In Progress",
  beta: "Beta",
  production: "Production",
  canceled: "Canceled",
};

export const RequestStatus: any = {
  validated: "Validated",
  new: "New",
  rejected: "Rejected",
  "loop-closed": "Loop Closed",
};

export const SolutionPriority: any = {
  urgent: "Urgent",
  high: "High",
  medium: "Medium",
  low: "Low",
};

export const IdeaPriority: any = {
  urgent: "Urgent",
  high: "High",
  medium: "Medium",
  low: "Low",
};

export const RequestPriority: any = {
  "deal-breaker": "Deal Breaker",
  important: "Important",
  medium: "Medium",
  "nice-to-have": "Nice To Have",
};

export interface Column {
  enabled: boolean;
  /* index: number; */
  accessor: string;
  /* title: string; */
  sortable: boolean;
  /* textAlignment: string;
  renderComponent: string;
  renderParams: any; */
  draggable?: boolean;
  resizable?: boolean;
  width?: number | string;
  ellipsis?: boolean;

}



export type syncContextType = {
  fieldsLoaded: boolean;
  deliveryPlatform: any;
  fields: any;
  components: any;
  ideaStatuses: any;
  ideaPriorities: any;
  requestPriorities: any;
  logout: any;
  members: any;
  owners: any;
  objectCustomizations: any;
  editCustomizations: any;
  defaultPriority: any;
  defaultStatus: any;
  getFields: any;
  priorityData: any;
  showDomain: boolean;
  domains: any[];
  requestPrioritiesColors: any;
  defaultRequestPriority: any;
  accountAdditionalInfo: any;
  opportunityAdditionalInfo: any;
  evidenceDomain: boolean;
  displayState: any;
  allComponents: any[];
  businessCategoryMapping: any;
  currentAccount: any;
  ideasTableDisplay: any;
  customEvidenceObj: any;
  accountDoc: any;
  allAggregationProperties: any;
  impactAggregationProperties: any;
  perspectiveConfigurations: any;
  ideaColumns: any[];
  setIdeasFiltersConfig: React.Dispatch<React.SetStateAction<{
    evidence: any;
    idea: any;
  }>> | null;
  ideasFiltersConfig: {
    evidence:any,
    idea:any
  };
  setAllComponents: (components: any[]) => void;
  ideaInfoTabsConfig: any;
  addedAttributes: any[];
  tagsList: any[];
  tagsOptions: any[];
  fetchTags: any;
};

export type ChildProps = {
  children?: React.ReactNode;
};

export const jiraTypes: any = [
  {
    label: "cascadingselect",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:cascadingselect",
  },
  {
    label: "datepicker",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:datepicker",
  },
  {
    label: "datetime",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:datetime",
  },
  {
    label: "float",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:float",
  },
  {
    label: "grouppicker",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:grouppicker",
  },
  {
    label: "importid",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:importid",
  },
  {
    label: "labels",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:labels",
  },
  {
    label: "multicheckboxes",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes",
  },
  {
    label: "multigrouppicker",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:multigrouppicker",
  },
  {
    label: "multiselect",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:multiselect",
  },
  {
    label: "multiuserpicker",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker",
  },
  {
    label: "multiversion",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:multiversion",
  },
  {
    label: "project",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:project",
  },
  {
    label: "radiobuttons",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:radiobuttons",
  },
  {
    label: "readonlyfield",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:readonlyfield",
  },
  {
    label: "select",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:select",
  },
  {
    label: "textarea",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:textarea",
  },
  {
    label: "textfield",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:textfield",
  },
  {
    label: "url",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:url",
  },
  {
    label: "userpicker",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:userpicker",
  },
  {
    label: "version",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:version",
  },
];

export const jiraSearcherKeys: any = [
  {
    label: "cascadingselectsearcher",
    value:
      "com.atlassian.jira.plugin.system.customfieldtypes:cascadingselectsearcher",
    jiraTypes: [
      "com.atlassian.jira.plugin.system.customfieldtypes:cascadingselect",
    ],
  },
  {
    label: "daterange",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:daterange",
    jiraTypes: ["com.atlassian.jira.plugin.system.customfieldtypes:datepicker"],
  },
  {
    label: "datetimerange",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:datetimerange",
    jiraTypes: ["com.atlassian.jira.plugin.system.customfieldtypes:datetime"],
  },
  {
    label: "exactnumber",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:exactnumber",
    jiraTypes: [
      "com.atlassian.jira.plugin.system.customfieldtypes:float",
      "com.atlassian.jira.plugin.system.customfieldtypes:importid",
    ],
  },
  {
    label: "grouppickersearcher",
    value:
      "com.atlassian.jira.plugin.system.customfieldtypes:grouppickersearcher",
    jiraTypes: [
      "com.atlassian.jira.plugin.system.customfieldtypes:grouppicker",
    ],
  },
  {
    label: "labelsearcher",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:labelsearcher",
    jiraTypes: ["com.atlassian.jira.plugin.system.customfieldtypes:labels"],
  },
  {
    label: "multiselectsearcher",
    value:
      "com.atlassian.jira.plugin.system.customfieldtypes:multiselectsearcher",
    jiraTypes: [
      "com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes",
      "com.atlassian.jira.plugin.system.customfieldtypes:multigrouppicker",
      "com.atlassian.jira.plugin.system.customfieldtypes:multiselect",
      "com.atlassian.jira.plugin.system.customfieldtypes:radiobuttons",
      "com.atlassian.jira.plugin.system.customfieldtypes:select",
    ],
  },
  {
    label: "userpickergroupsearcher",
    value:
      "com.atlassian.jira.plugin.system.customfieldtypes:userpickergroupsearcher",
    jiraTypes: [
      "com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker",
      "com.atlassian.jira.plugin.system.customfieldtypes:userpicker",
    ],
  },
  {
    label: "versionsearcher",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:versionsearcher",
    jiraTypes: [
      "com.atlassian.jira.plugin.system.customfieldtypes:multiversion",
      "com.atlassian.jira.plugin.system.customfieldtypes:version",
    ],
  },
  {
    label: "projectsearcher",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:projectsearcher",
    jiraTypes: ["com.atlassian.jira.plugin.system.customfieldtypes:project"],
  },
  {
    label: "textsearcher",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:textsearcher",
    jiraTypes: [
      "com.atlassian.jira.plugin.system.customfieldtypes:readonlyfield",
      "com.atlassian.jira.plugin.system.customfieldtypes:textarea",
      "com.atlassian.jira.plugin.system.customfieldtypes:textfield",
    ],
  },
  {
    label: "numberrange",
    value: "com.atlassian.jira.plugin.system.customfieldtypes:numberrange",
    jiraTypes: ["com.atlassian.jira.plugin.system.customfieldtypes:float"],
  },
  {
    label: "exacttextsearcher",
    value:
      "com.atlassian.jira.plugin.system.customfieldtypes:exacttextsearcher",
    jiraTypes: ["com.atlassian.jira.plugin.system.customfieldtypes:url"],
  },
];

export interface BarProps {
  displayContent: ReactNode;
  barWidthPercantage: number

}

export type IdeasContextType = {
  location: any;
  page: string | null;
  filters: any;
  sortStatus: any;
  savedViews: any[];
  selectedView: any;
  requestsTotalCount: number;
  setRequestsTotalCount: React.Dispatch<React.SetStateAction<number>>;
  setPage: React.Dispatch<React.SetStateAction<string | null>>;
  setFilters: React.Dispatch<React.SetStateAction<string | null>>;
  setSortStatus: React.Dispatch<React.SetStateAction<DataTableSortStatus>>;
  setSelectedView: React.Dispatch<React.SetStateAction<any>>;
  loadViews: (deleted?: boolean) => void;
  upsertView: (body: any, callback: (res: any) => void) => void;
  sortState: string;
  setSortState: React.Dispatch<React.SetStateAction<string>>;
  isTagsHovered: boolean;
  hoverTags: (e: any, id: string) => void;
  hoveredId: string;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  ideas: any[];
  setIdeas: React.Dispatch<React.SetStateAction<any[]>>;
  getFilteredIdeas: () => void;
  ideaColumns: any[];
};

export const initialState: IdeasContextType = {
  location: null,
  page: null,
  filters: null,
  sortStatus: null,
  savedViews: [],
  selectedView: {},
  requestsTotalCount: 0,
  setRequestsTotalCount: () => null,
  setPage: () => null,
  setFilters: () => null,
  setSortStatus: () => null,
  setSelectedView: () => null,
  loadViews: () => null,
  upsertView: () => null,
  sortState: "",
  setSortState: () => null,
  isTagsHovered: false,
  hoverTags: () => null,
  hoveredId: "",
  search:'',
  setSearch: () => null,
  loading: false,
  setLoading: () => null,
  ideas: [],
  setIdeas: () => null,
  getFilteredIdeas: () => null,
  ideaColumns: [],
};

