import { UseListStateHandlers } from "@mantine/hooks";
import { ModalContainer, ModalContainerInterface } from "./ModalContainer";
import { ModalContent } from "./ModalContent";
import { ModalHeader } from "./ModalHeader";
import { Box } from "@mantine/core";

export interface RequestModalInterface extends ModalContainerInterface {
  idea: any;
  request: any;
  setValidatedRequests: React.Dispatch<React.SetStateAction<any>>;
  onUpdated: (useLoading?: boolean) => void;
  requestsHandlers?: UseListStateHandlers<any[]> | undefined;
  customizations: any;
}

export function NewRequestModal({
  idea,
  request,
  setValidatedRequests,
  onUpdated,
  requestsHandlers,
  customizations,
  children,
  matches,
  setMatches,
  
}: {
  idea: any;
  request: any;
  setValidatedRequests: React.Dispatch<React.SetStateAction<any>>;
  onUpdated: (useLoading?: boolean) => void;
  requestsHandlers?: UseListStateHandlers<any[]> | undefined;
  customizations: any;
  children?: React.ReactNode;
  matches: any;
  setMatches: React.Dispatch<React.SetStateAction<any>>;
  
}) {
  const company = idea?.company ? idea?.company : undefined;
  const customer = company?.name;
  const customerName = customer ? `- ${customer}` : "";
  let requestType = idea?.modalType === "request" ? idea?.type || "" : "";
  if (requestType.length > 0) {
    requestType = `(${
      requestType?.charAt(0)?.toUpperCase() + requestType.slice(1)
    })`;
  }
  const popupTitle = `Request ${requestType} ${customerName}`;

  return (
    <Box 
    // h= "calc(100vh - 70px)"
    >
      <ModalHeader
        title={popupTitle}
        idea={idea}
        request={request}
        setValidatedRequests={setValidatedRequests}
        onUpdated={onUpdated}
        requestsHandlers={requestsHandlers}
        customizations={customizations}
      />
      <ModalContent
        idea={idea}
        request={request}
        setValidatedRequests={setValidatedRequests}
        onUpdated={onUpdated}
        requestsHandlers={requestsHandlers}
        customizations={customizations}
        matches={matches}
        setMatches={setMatches}
      />
    </Box>
  );
}
