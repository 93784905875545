import React, { useState } from "react";
import { Button, Box, Group, Modal } from "@mantine/core";
import { useTags } from "../../hooks/useTags";
import TagsTable from "./TagsTable";
import TagModal from "./TagModal";

const TagsManager: React.FC = () => {
  const { tags, saveTag, removeTag } = useTags();
  const [modalOpen, setModalOpen] = useState(false);
  const [editingTag, setEditingTag] = useState<any | null>(null);

  const handleEdit = (tag: any) => {
    if (tag._id) {
      setEditingTag(tag);
    } else {
      console.error("Invalid tag: Missing _id for editing.");
    }
    setModalOpen(true);
  };

  const handleNewTag = () => {
    setEditingTag({ label: "", color: "" });
    setModalOpen(true);
  };

  const handleDelete = async (tagId: string) => {
    if (window.confirm("Are you sure you want to delete this tag?")) {
      await removeTag(tagId);
    }
  };

  return (
    <Box      
      sx={{
        padding: "15px",
      }}>
      <Group position="apart" mb="md">
        <h2>Manage Tags</h2>
        <Button onClick={handleNewTag}>New Tag</Button>
      </Group>
      <TagsTable tags={tags} onEdit={handleEdit} onDelete={handleDelete} />
      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        title={editingTag && editingTag._id ? "Edit Tag" : "Create Tag"}
      >
        <TagModal
          tag={editingTag}
          onSave={saveTag}
          onClose={() => setModalOpen(false)}
        />
      </Modal>
    </Box>
  );
};

export default TagsManager;
