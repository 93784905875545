import { useState, useEffect } from "react";
import {
  fetchTags,
  createTag,
  updateTag,
  deleteTag,
} from "../services/tagsService";
import { useAuthHeaders } from "./useAuthHeaders";

export const useTags = () => {
  const { getAuthHeaders } = useAuthHeaders();
  const [tags, setTags] = useState<any[]>([]);

  const loadTags = async () => {
    try {
      const fetchedTags = await fetchTags(getAuthHeaders);
      setTags(fetchedTags.tags);
    } catch (error) {
      console.error("Error loading tags:", error);
    }
  };

  const saveTag = async (tag: any) => {
    try {
      if (tag._id && tag._id !== "") {
        await updateTag(
          tag._id,
          { label: tag.label, color: tag.color },
          getAuthHeaders
        );
      } else {
        await createTag(
          { label: tag.label, color: tag.color, index: 0 },
          getAuthHeaders
        );
      }
      await loadTags();
    } catch (error: any) {
      console.error("Error saving tag:", error.response?.data || error.message);
    }
  };

  const removeTag = async (tagId: string) => {
    try {
      await deleteTag(tagId, getAuthHeaders);
      await loadTags();
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };

  useEffect(() => {
    loadTags();
  }, []);

  return {
    tags,
    loadTags,
    saveTag,
    removeTag,
  };
};
