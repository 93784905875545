import { Box, Flex, Menu, Stack, Text } from "@mantine/core";
import { IconCheck, IconCopy, IconX } from "@tabler/icons";
import { useContext, useState } from "react";
import IdeaContext from "../../IdeaContext";
import { useNavigate } from "react-router-dom";

const CardHeader = ({ navToPerspective }: { navToPerspective: any }) => {
  
  const { setChosenEvidence, chosenEvidence } = useContext(IdeaContext);
 
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const requestId = chosenEvidence?._id;

  const handleCopy = async () => {
    try {
      const newPath = new URL(window.location.href);
      const originStr = newPath?.origin?.toString()

      await navigator.clipboard.writeText(
        `${originStr}/evidence/${requestId}`
      );

      setTimeout(() => setOpened(false), 1500);

    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  const status = chosenEvidence?.status;
  const isRequest = status === "new" || status === "validated";

  return (
    <Flex justify="space-between" w="100%" h={52} align="center">
      <Stack fz="20px" fw={600} h="20px" ta="center" justify="center">
        Evidence details
      </Stack>

      <Flex>
        {isRequest && (
          <Menu opened={opened} onChange={setOpened} position="top" /* transition="fade" */ /* transitionDuration={292} */>
            <Menu.Target>
              <Flex
                mr="lg"
                p={6}
                bg="#F0F0FA"
                sx={{ cursor: "pointer", borderRadius: 6 }}
                onClick={() => {
                  handleCopy();
                }}
                w={96}
              >
                <IconCopy size={16} color="#212529" />
                <Text ml="3px" color="#212529" fz={12} lineClamp={1}>
              Copy link
                </Text>
              </Flex>
            </Menu.Target>
            <Menu.Dropdown>
              <Flex p={6} bg="#212529" sx={{borderRadius: 4}}>
                <IconCheck size={14} color="#F9F7F5"/>
                <Text color="#F9F7F5" fz={13} ml={4}>
               Link copied to clipboard
                </Text> 
              </Flex>
            
            </Menu.Dropdown>
          </Menu>
          
        )}

        <Flex
          mb={2}
          mr="8px"
          sx={{ cursor: "pointer" }}
          align="center"
          onClick={() => {
            navToPerspective();
            setChosenEvidence(null);
            const newPath = new URL(window.location.href);
            newPath.searchParams.delete("requestId");
            console.log(newPath.toString().substring(newPath.origin.length), {
              replace: true,
            });
            navigate(newPath.toString().substring(newPath.origin.length), {
              replace: true,
            });
          }}
        >
          <IconX size={14} color="#5C5CEB" />
          <Text ml="4px" color="#5C5CEB" fz={12} py={6} >
            Close
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CardHeader;
