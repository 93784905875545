import { Box, Button, Flex, Tooltip } from "@mantine/core";
import { useContext } from "react";
import IdeaContext from "../../../../IdeaContext";
import { IconCheck, IconPlus } from "@tabler/icons";

const BagelUsersActions = ({
  labelMatchBagelUser,
  labelingDisabaled,
}: {
  labelMatchBagelUser: (sameRequest: boolean) => void;
  labelingDisabaled: boolean;
}) => {
  const { chosenEvidence } = useContext(IdeaContext);

  return (
    <Flex justify='space-around' w='100%'>
      <Tooltip label="Same Epic">
        <Button
          disabled={
            chosenEvidence?.manualLabel === "sameRequest" ||
            chosenEvidence?.manualLabel === "sameEpic" ||
            labelingDisabaled
          }
          w="27px"
          mr="12px"
          sx={{ cursor: " pointer" }}
          onClick={() => labelMatchBagelUser(false)}
          styles={(theme) => ({
            root: {
              height: "32px",
              backgroundColor: "#5C5CEB",
              border: 0,
              fontWeight: 400,
              padding: 0,
              "&:hover": {
                backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
              },
            },

            leftIcon: {
              marginRight: 15,
            },
          })}
        >
          <Box>
            <IconPlus size={14} />
          </Box>
        </Button>
      </Tooltip>

      <Tooltip label="Same Request">
        <Button
          disabled={
            chosenEvidence?.manualLabel === "sameRequest" ||
            chosenEvidence?.manualLabel === "sameEpic" ||
            labelingDisabaled
          }
          w="27px"
          /* mr="12px" */
          sx={{ cursor: " pointer" }}
          onClick={() => labelMatchBagelUser(true)}
          styles={(theme) => ({
            root: {
              height: "32px",
              backgroundColor: "#5C5CEB",
              border: 0,
              fontWeight: 400,
              padding: 0,
              "&:hover": {
                backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
              },
            },

            leftIcon: {
              marginRight: 15,
            },
          })}
        >
          <Box>
            <IconCheck size={14} />
          </Box>
        </Button>
      </Tooltip>
    </Flex>
  );
};

export default BagelUsersActions;
