import { Flex, Text } from '@mantine/core';
import { IconArrowDown, IconArrowUp } from '@tabler/icons';

const JumpToExcerpt = ({ onClick, isLast } : { onClick: ()=> void, isLast: boolean }) => {
  return (
    <Flex
      sx={{ cursor: "pointer" }}
      ml={50}
      pb="sm"
      mt="sm"
      onClick={onClick}
    >
      {isLast? <IconArrowUp size={16} color="#5C5CEB" />  :  <IconArrowDown size={16} color="#5C5CEB" />}
      <Text ml={4} color="#5C5CEB">
        { isLast ? 'Back to top' : 'Next' }
      </Text>
    </Flex>
  )
}

export default JumpToExcerpt