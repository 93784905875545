// src/components/settings/PropertyManager/_utils/CustomizationContext.tsx

import React, { createContext, useContext, useEffect, useState, ReactNode, useMemo } from "react";
import { useAuth0, Auth0ContextInterface } from "@auth0/auth0-react";
import {
  getCustomizationsPropertyInit,
  addProperty as apiAddProperty,
  updateProperty as apiUpdateProperty,
  deleteProperty as apiDeleteProperty,
  runAggregations as apiRunAggregations,
} from "../../../../Api";
import {
  IdeaProperty,
  IdeaPropertyToSave,
  GroupsRecord,
  ExtendedIdeaProperty,
} from "../types";
import {
  buildGroupsFromProperties,
  getGroupId,
  parseDBFieldPath,
  parseDBGroupBy,
  toDBFieldPath,
  toDBGroupBy,
  toDBCondition,
} from "./helpers";

interface CustomizationContextProps {
  customizationData: Record<string, string[]>;
  propertiesData: IdeaProperty[];
  groups: GroupsRecord;
  loading: {
    fetch:boolean
    add:boolean,
    update:boolean,
    delete:boolean,
    runAggregations:boolean,
  };
  error: string | null;
  addGroup: (object: string, attribute: string) => void;
  updateGroupKey: (oldGroupId: string, newObject: string, newAttribute: string) => Promise<void>;
  addPropertyToGroup: (groupId: string, property: ExtendedIdeaProperty) => Promise<void>;
  updateProperty: (propertyName: string, updatedProperty: ExtendedIdeaProperty) => Promise<void>;
  deleteProperty: (propertyName: string) => Promise<void>;
  deleteGroup: (groupId: string) => Promise<void>;
  runAggregations: () => Promise<void>;
  refreshCustomizationData: () => Promise<void>;
}

const CustomizationContext = createContext<CustomizationContextProps | undefined>(undefined);

export const useCustomization = (): CustomizationContextProps => {
  const context = useContext(CustomizationContext);
  if (!context) {
    throw new Error("useCustomization must be used within a CustomizationProvider");
  }
  return context;
};

export const CustomizationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { isLoading } = useAuth0();
  const [customizationData, setCustomizationData] = useState<Record<string, string[]>>({});
  const [propertiesData, setPropertiesData] = useState<IdeaProperty[]>([]);
  const [loading, setLoading] = useState<{
    fetch:boolean
    add:boolean,
    update:boolean,
    delete:boolean,
    runAggregations:boolean,
  }>({
    fetch:false,
    add:false,
    update:false,
    delete:false,
    runAggregations:false,
  });
  const [error, setError] = useState<string | null>(null);
  const auth0 = useAuth0();

  // Local ephemeral empty groups
  const [localEmptyGroups, setLocalEmptyGroups] = useState<GroupsRecord>({});

  /**
   * Fetches customization data + properties from the backend
   */
  const fetchCustomizationData = async (auth0: Auth0ContextInterface) => {
    setLoading((prev)=> ({...prev, fetch:true}));

    setError(null);
    try {
      const response = await getCustomizationsPropertyInit(auth0);
      if (response.success) {
        // e.g. response.data => { <objectName>: [attributes...] }
        // e.g. response.properties => array of property objects
        setCustomizationData(response.data);
        setPropertiesData(response.properties);
      } else {
        setError("Failed to fetch customization data.");
      }
    } catch (err: any) {
      console.error("Error fetching customization data:", err);
      setError("An error occurred while fetching customization data.");
    } finally {
      setLoading((prev)=> ({...prev, fetch:false}));

    }
  };

  useEffect(() => {
    const initialize = async () => {
      if (!isLoading) {
        try {
          await fetchCustomizationData(auth0);
        } catch (err) {
          console.error("Error initializing customization data:", err);
          setError("An error occurred while initializing customization data.");
        }
      }
    };
    initialize();
  }, [isLoading, auth0]);

  // Build groups from propertiesData
  const groupsFromDB: GroupsRecord = useMemo(() => buildGroupsFromProperties(propertiesData), [propertiesData]);

  // Merge groups from DB and localEmptyGroups
  const groups: GroupsRecord = useMemo(() => {
    return { ...groupsFromDB, ...localEmptyGroups };
  }, [groupsFromDB, localEmptyGroups]);

  /**
   * Add an empty group locally
   */
  const addGroup = (object: string, attribute: string) => {
    const groupId = getGroupId(object, attribute);

    setLocalEmptyGroups((prev) => {
      if (groups[groupId]) return prev; // already exists
      return {
        ...prev,
        [groupId]: { object, attribute, properties: [] },
      };
    });
  };

  /**
   * Update group key (object and/or attribute) for empty groups
   * Only allowed if the group has no properties
   */
  const updateGroupKey = async (oldGroupId: string, newObject: string, newAttribute: string) => {
    const newGroupId = getGroupId(newObject, newAttribute);
  
    if (oldGroupId === newGroupId) {
      // No change
      return;
    }
  
    if (groups[newGroupId]) {
      setError("A group with the new object and attribute already exists.");
      return;
      // throw new Error("A group with the new object and attribute already exists.");

    }
  
    const group = groups[oldGroupId];
    if (!group) {
      throw new Error("Group not found.");
    }
  
    if (group.properties.length > 0) {
      throw new Error("Cannot rename a group that has properties.");
    }
  
    // Proceed to rename the empty group
    setLocalEmptyGroups((prev) => {
      const { [oldGroupId]: oldGroup, ...rest } = prev;
      return {
        ...rest,
        [newGroupId]: { object: newObject, attribute: newAttribute, properties: [] },
      };
    });
  };

  /**
   * Add a property to a group (calls API and refreshes data)
   */
  const addPropertyToGroup = async (groupId: string, property: ExtendedIdeaProperty) => {
    setLoading((prev)=> ({...prev, add:true}));
    setError(null);
    try {
      // Convert ExtendedIdeaProperty to IdeaPropertyToSave
      const group = groups[groupId];
      if (!group) {
        setError("Group does not exist.");
        return;
      }

      const dbFieldPath = toDBFieldPath(group.object, group.attribute);
      const dbGroupBy =  toDBGroupBy(property?.groupObject, property.groupAttribute);
      const dbCondition = toDBCondition(property?.condition, property.conditionValue);

      const newProp: IdeaPropertyToSave = {
        label: property.label,
        propertyName: property.propertyName,
        operation: property.operation,
        fieldPath: dbFieldPath,
        groupBy: dbGroupBy,
        condition: dbCondition,
        fieldDefaultValue: property.fieldDefaultValue && property.fieldDefaultValue !== '' ? property.fieldDefaultValue : null
      };

      await apiAddProperty(auth0, newProp);

      // Refresh data
      await fetchCustomizationData(auth0);

      // Remove the group from localEmptyGroups if it was empty before
      setLocalEmptyGroups((prev) => {
        const { [groupId]: _, ...rest } = prev;
        return rest;
      });
    } catch (err: any) {
      console.error("Error adding property:", err);
      setError("An error occurred while adding the property.");
    } finally {
      setLoading((prev)=> ({...prev, add:false}));
    }
  };

  /**
   * Update a property (calls API and refreshes data)
   */
  const updateProperty = async (propertyName: string, updatedProperty: ExtendedIdeaProperty) => {
    setLoading((prev)=> ({...prev, update:true}));

    setError(null);
    try {
      // Convert ExtendedIdeaProperty to IdeaPropertyToSave
      const group = groups[getGroupId(updatedProperty.parentGroupInit.object, updatedProperty.parentGroupInit.attribute)];
      console.log({groups, propertyName, updatedProperty, group, mmm: getGroupId(updatedProperty.parentGroupInit.object, updatedProperty.parentGroupInit.attribute)})

      if (!group) {
        setError("Group does not exist.");
        return;
      }
      console.log({group})

      const dbFieldPath = toDBFieldPath(updatedProperty.parentGroup.object, updatedProperty.parentGroup.attribute);
      const dbGroupBy = toDBGroupBy(updatedProperty.groupObject, updatedProperty.groupAttribute);
      const dbCondition = toDBCondition(updatedProperty.condition, updatedProperty.conditionValue);

      const updatedProp: IdeaPropertyToSave = {
        label: updatedProperty.label,
        propertyName: updatedProperty.propertyName,
        operation: updatedProperty.operation,
        fieldPath: dbFieldPath,
        groupBy: dbGroupBy,
        condition: dbCondition,
        fieldDefaultValue: updatedProperty.fieldDefaultValue && updatedProperty.fieldDefaultValue !== '' ? updatedProperty.fieldDefaultValue : null
      };

      await apiUpdateProperty(auth0, propertyName, updatedProp);

      // Refresh data
      await fetchCustomizationData(auth0);
    } catch (err: any) {
      console.error("Error updating property:", err);
      setError("An error occurred while updating the property.");
    } finally {
      setLoading((prev)=> ({...prev, update:false}));

    }
  };

  /**
   * Delete a property (calls API and refreshes data)
   */
  const deleteProperty = async (propertyName: string) => {
    setLoading((prev)=> ({...prev, delete:true}));

    setError(null);
    try {
      await apiDeleteProperty(auth0, propertyName);

      // Refresh data
      await fetchCustomizationData(auth0);
    } catch (err: any) {
      console.error("Error deleting property:", err);
      setError("An error occurred while deleting the property.");
    } finally {
      setLoading((prev)=> ({...prev, delete:false}));

    }
  };

  /**
   * Delete a group
   * If the group is local (empty), remove it from localEmptyGroups
   * If the group has properties, delete all properties in it
   */
  const deleteGroup = async (groupId: string) => {
    const group = groups[groupId];
    if (!group) return;

    if (localEmptyGroups[groupId]) {
      // Remove from localEmptyGroups
      setLocalEmptyGroups((prev) => {
        const { [groupId]: _, ...rest } = prev;
        return rest;
      });
    } else {
      // Delete all properties in the group
      setError(null);
      try {
        const propertyNames = group.properties.map((prop) => prop.propertyName);
        for (const propName of propertyNames) {
          await deleteProperty(propName);
        }
      } catch (err: any) {
        console.error("Error deleting group properties:", err);
        setError("An error occurred while deleting the group properties.");
      }
    }
  };

  const runAggregations = async () => {
    setLoading((prev)=> ({...prev, runAggregations:true}));

    setError(null);
    try {
      await apiRunAggregations(auth0);

    } catch (err: any) {
      console.error("Error running aggregations:", err);
      setError("An error occurred while running the aggregations.");
    } finally {
      setLoading((prev)=> ({...prev, runAggregations:false}));

    }
  };

  /**
   * Refresh customization data
   */
  const refreshCustomizationData = async () => {
    try {
      await fetchCustomizationData(auth0);
    } catch (err) {
      console.error("Error refreshing customization data:", err);
      setError("An error occurred while refreshing customization data.");
    }
  };

  return (
    <CustomizationContext.Provider
      value={{
        customizationData,
        propertiesData,
        groups,
        loading,
        error,
        addGroup,
        updateGroupKey,
        addPropertyToGroup,
        updateProperty,
        deleteProperty,
        deleteGroup,
        runAggregations,
        refreshCustomizationData,
      }}
    >
      {children}
    </CustomizationContext.Provider>
  );
};