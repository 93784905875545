import axios from "axios";
import { getAuthHeaders } from "../Api";

export interface JiraIntegrationUpdate {
    requestFields?: { component?: string; subComponent?: string };
    solutionFields?: { component?: string; subComponent?: string };
    requestListId?: string;
    solutionListId?: string;
    disabled?: boolean;
    setupStep?: number;
    syncType?: string;
  }

export interface SFIntegrationUpdate {
    disabled?: boolean;
    syncType?: string;
    setupStep?: number;
    blackListedAccountIds?: string[];
    accountTypesMap?: { [name: string]: string };
    accountFields?: {
      arr?: string | null;
      plan?: string | null;
      tier?: string | null;
    };
    caseFields?: { showOpType?: boolean };
    opFields?: { amount?: string; renewalDate?: string };
  }

export interface IntegrationUpdate {
    disabled?: boolean;
    setupStep?: number;
    syncType?: string;
  }

export interface ClickupIntegrationUpdate {
    requestFields?: { component?: string; subComponent?: string };
    solutionFields?: { component?: string; subComponent?: string };
    requestListId?: string;
    solutionListId?: string;
    disabled?: boolean;
    setupStep?: number;
    syncType?: string;
  }
  
export interface ZendeskIntegrationUpdate {
    disabled?: boolean;
    setupStep?: number;
    syncType?: string;
  }
const API_BASE_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/integrations";


// GET: Fetch all integrations
export const getIntegrations = async (auth0: any) => {
  try {
    const config = await getAuthHeaders(auth0);
    const response = await axios.get(`${API_BASE_URL}/`, config);
    return response.data.integrations;
  } catch (e) {
    console.error("Error fetching integrations", e);
    throw e;
  }
};

// GET: Fetch Jira custom fields
export const getJiraCustomFields = async (auth0: any) => {
  try {
    const config = await getAuthHeaders(auth0);
    const response = await axios.get(`${API_BASE_URL}/jira/customFields`, config);
    return response.data;
  } catch (e) {
    console.error("Error fetching Jira custom fields", e);
    throw e;
  }
};

// POST: Update a generic integration
export const updateIntegration = async (
  integrationId: string,
  update: IntegrationUpdate,
  auth0: any
) => {
  try {
    const config = await getAuthHeaders(auth0);
    const response = await axios.post(
      `${API_BASE_URL}/update`,
      { integrationId, update },
      config
    );
    return response.data;
  } catch (e) {
    console.error("Error updating integration", e);
    throw e;
  }
};

// POST: Update Salesforce integration
export const updateSalesforceIntegration = async (
  integrationId: string,
  update: SFIntegrationUpdate,
  auth0: any
) => {
  try {
    const config = await getAuthHeaders(auth0);
    const response = await axios.post(
      `${API_BASE_URL}/sf/update`,
      { integrationId, update },
      config
    );
    return response.data;
  } catch (e) {
    console.error("Error updating Salesforce integration", e);
    throw e;
  }
};

// POST: Update Clickup integration
export const updateClickupIntegration = async (
  integrationId: string,
  update: ClickupIntegrationUpdate,
  auth0: any
) => {
  try {
    const config = await getAuthHeaders(auth0);
    const response = await axios.post(
      `${API_BASE_URL}/clickup/update`,
      { integrationId, update },
      config
    );
    return response.data;
  } catch (e) {
    console.error("Error updating Clickup integration", e);
    throw e;
  }
};

// POST: Update Jira integration
export const updateJiraIntegration = async (
  integrationId: string,
  update: JiraIntegrationUpdate,
  auth0: any
) => {
  try {
    const config = await getAuthHeaders(auth0);
    const response = await axios.post(
      `${API_BASE_URL}/jira/update`,
      { integrationId, update },
      config
    );
    return response.data;
  } catch (e) {
    console.error("Error updating Jira integration", e);
    throw e;
  }
};

// POST: Save Jira API token
export const saveJiraApiToken = async (
  auth0: any,
  apiToken: string,
  orgId?: string,
  onPremiseUrl?: string
) => {
  try {
    const config = await getAuthHeaders(auth0);
    const response = await axios.post(
      `${API_BASE_URL}/jiraToken`,
      { orgId, apiToken, onPremiseUrl },
      config
    );
    return response.data;
  } catch (e) {
    console.error("Error saving Jira API token", e);
    throw e;
  }
};

// POST: Create Jira custom field
export const createJiraCustomField = async (
  customFieldData: any,
  auth0: any
) => {
  try {
    const config = await getAuthHeaders(auth0);
    const response = await axios.post(
      `${API_BASE_URL}/jira/createCustomField`,
      customFieldData,
      config
    );
    return response.data;
  } catch (e) {
    console.error("Error creating Jira custom field", e);
    throw e;
  }
};

// DELETE: Delete Jira custom field
export const deleteJiraCustomField = async (
  customFieldId: string,
  auth0: any
) => {
  try {
    const config = await getAuthHeaders(auth0);
    const response = await axios.delete(
      `${API_BASE_URL}/jira/customField/${customFieldId}`,
      config
    );
    return response.data;
  } catch (e) {
    console.error("Error deleting Jira custom field", e);
    throw e;
  }
};

export const updateZendeskIntegration = async (
  integrationId: string,
  update: ZendeskIntegrationUpdate,
  auth0: any
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/integrations/zendesk/update";
    const response = await axios.post(url, { integrationId, update }, config);
    return response.data;
  } catch (e: any) {
    console.error("Error updating Zendesk integration", e);
    throw e;
  }
};
  
export const fetchIntegration = async (
  auth0: any,
  type: string
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const response = await axios.get(
      `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/integrations/${type}`,
      config
    );
    return response.data.integration;
  } catch (e) {
    console.error("Error fetching integration", e);
    throw e;
  }
};
  
export const createToken = async (
  auth0: any,
  type: string,
  accountId: string
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const response = await axios.post(
      `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/integrations/${type}/createToken`,
      { accountId },
      config
    );
    return response.data;
  } catch (e) {
    console.error("Error creating token", e);
    throw e;
  }
};

export const getToken = async (auth0: any, type: string): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const response = await axios.get(
      `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/integrations/${type}/getToken`,
      config
    );
    return response.data.tokenDoc;
  } catch (e) {
    console.error("Error fetching token", e);
    throw e;
  }
};

export const getDeliveryPlatformType = async (auth0: any): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const response = await axios.get(
      `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/integrations/delivery`,
      config
    );
    return response.data.deliveryIntegrationType;
  } catch (e) {
    console.error("Error fetching token", e);
    throw e;
  }
};