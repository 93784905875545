import {Button} from "@mantine/core";
import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import { updateZendeskIntegration, ZendeskIntegrationUpdate} from "../../../../api/integrations";

export const SetupImport = (props: { integration: any, reloadIntegration: () => void, variant?: 'wizard'|'settings' }) => {
  const auth0 = useAuth0()
  // const [syncType, setSyncType] = useState<string>(props.integration.syncType ? props.integration.syncType : 'auto');
  const isWizard = !props.variant || props.variant === 'wizard'
  const importClicked = () => {
    const update: ZendeskIntegrationUpdate = { syncType: 'auto' };
    if (isWizard) {
      update.disabled = false
      update.setupStep = -1
    }
    updateZendeskIntegration(props.integration._id, update, auth0).then(() => {
      props.reloadIntegration();
    }).catch(e => console.log(e))
  }
  return <>
    {/*<Radio.Group*/}
    {/*  mt={"lg"}*/}
    {/*  name="syncType"*/}
    {/*  label="Set sync frequency"*/}
    {/*  value={syncType}*/}
    {/*  onChange={setSyncType}*/}
    {/*>*/}
    {/*  <Radio value="auto" label="Automatic sync" checked />*/}
    {/*  <Radio value="once" label="One-time import" />*/}
    {/*</Radio.Group>*/}
    <Button mt={"md"} onClick={() => importClicked()}>{ isWizard ? 'Import now': 'Save' }</Button>
  </>
}