import {
  Group,
  Text,
  Anchor,
  Box,
  Flex,
  Accordion,
  Title,
  ScrollArea,
  Avatar,
  Spoiler,
} from "@mantine/core";

import moment from "moment";
import { SourceIcon } from "../../../utils/SourceIcon";
import { ModalContentInterface } from "./ModalContent";
import { EvidenceCard } from "./EvidenceCard";

import wrapLinksWithElement from "../../../utils/WrapLinksWithElement";
import { stripHtml } from "../../../utils";

import { IdeaTextEditor } from "../IdeaTextEditor";
import { useContext, useEffect, useState } from "react";
import SyncContext from "../../../context/SyncContext";
import { getInitials } from "../../../utils/getInitials";
import ReactTimeAgo from "react-time-ago";
import { AccountCard } from "./AccountCard";
import { ContactCard } from "./ContactCard";


function replaceNewlineWithBr(input: string): string {
  return input.replace(/\n+/g, '<br>');
}

export function RequestDetails(props: ModalContentInterface) {
  const { members } = useContext(SyncContext);
  const [sortedTextItems, setSortedTextItems] = useState<any[]>([]);
  const [highestScoreIndex, setHighestScoreIndex] = useState(0);
  const cxItem = props.request?.chat;
  const contact =
    props.request && props.request?.contact
      ? props.request?.contact
      : props.request?.internalContact;   
  const company = props.request?.chatCompany || props.request?.company

  const origin = props.request?.chat?.trueOrigin || props.request?.chat?.origin || props.request?.origin || 'bagel'

  const cxItemText = cxItem?.text ? cxItem.text : "";
  // const htmlToText = stripHtml(cxItemText ?? "");
  const hasIdeaLink =
    props.idea &&
    (props.idea.origin === "jira" || props.idea.origin === "clickup") &&
    props.idea.link;

  const isGongCall = cxItem?.origin === 'gong' && cxItem?.itemType === 'conversation'
  const isSupportTicket = cxItem?.origin && ['zendesk', 'intercom'].includes(cxItem.origin)  && cxItem?.itemType === 'conversation'

  // const element = <span style={{ color: "blue" }} />;
  // const wrappedText = wrapLinksWithElement({ text: htmlToText, element });
  const jiraOrigin: boolean = props.idea?.origin === "jira";
  // const status = props.request.status || null
  useEffect(() => {
    if(props.request?.relatedTextItems?.length > 0){
      const sortedItems: any[] = props.request?.relatedTextItems?.sort(
        (a: any, b: any) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      ) || [];
      const highestScore: number = sortedItems.findIndex((s: any)=>
        s._id === props.request?.textId
      )
      setHighestScoreIndex(highestScore)
      sortedItems?.forEach((s: any)=> {
        if(s?.fieldName){
          const number = s?.fieldName?.split(".")[1]
          const comments = cxItem?.comments || {}
          const comment = comments[number] || {}
          if(comment){
            console.log(comment?.fromEmail)
            s.comment = comment
            const memberFromEmail = members.find((m: any)=> m.email === comment?.fromEmail)
            if(memberFromEmail){
              s.fromUser = memberFromEmail 
            }
            else if(!memberFromEmail){
              const memberFromName = members.find((m: any)=> m.email === comment?.fromName)
              s.fromUser = memberFromName
            }
            
          }  
        }
      })
      setSortedTextItems(sortedItems)
    } 
  },[])

  return (
    <ScrollArea type="scroll" h="calc(100vh - 187px)" scrollbarSize={5}>
      <Box mt="sm">
        {props.request.textItem ?
          <Box  mb={3} pl={35} mr='xl'>
            {/* <Title order={5}>Submitter's notes:</Title> */}
            <Text>{props.request?.textItem?.title}</Text>
            {props.request?.textItem?.text && 
            <Spoiler maxHeight={130} showLabel="see more"
              hideLabel="Hide"> 
              <Text sx={{ cursor: "default", whiteSpace: "pre-line"}}>{stripHtml(props.request?.textItem?.text)}</Text>
            </Spoiler>} 
          </Box>:
          <Box  mb={3} pl={35} mr='xl'>
            {/* <Title order={5}>Submitter's notes:</Title> */}
            <Text>{props.request?.title}</Text>
            {props.request?.text && 
            <Spoiler maxHeight={130} showLabel="see more"
              hideLabel="Hide"> 
              <Text sx={{ cursor: "default", whiteSpace: "pre-line"}}>{stripHtml(props.request?.text)}</Text>
            </Spoiler>} 
          </Box>

        }
        {isGongCall && (
          <Box pl={35} mt='xs'>
            <Title order={5} mb='3px'>Evidence:</Title>
            <Flex mb='1px'>
              <Text fw={500} fz={15} mb='4px'>
                {isGongCall  && `Call:`}
              </Text>
              <Text ml='3px'>{cxItem?.title || 'N/A'}</Text>
            </Flex>
            
          </Box>)}
        {isGongCall && sortedTextItems && sortedTextItems.length > 0 && 
          sortedTextItems.map((item: any, index: number)=>
            <>
              {item.excerpt && <Flex ml={35} mt={5}>
                {sortedTextItems[0]?.excerpt && <Box mr='xs' w='33px' pt='4.4px'>
                  {item?.fromUser?.picture ? <Avatar  src={item?.fromUser?.picture} radius="xl" size={30}>
                    {getInitials(item?.comment?.fromName)}
                  </Avatar> : <Avatar radius="xl" size={30}/>}
                </Box>}
                <Box px={10} py={8}  sx={{borderRadius: '11px'}} bg={highestScoreIndex === index ? "#FFFFED": ''}>
                  <Flex justify='space-between'>
                    <Flex>
                      <Flex w={720}>
                        {item.comment?.fromName && <Text fw={600} fz='lg'>
                          {item.comment?.fromName}
                        </Text>}
                        {item.comment?.fromEmail && <Text fz='lg'>
                          {`<${item.comment?.fromEmail}>`}
                        </Text>}
                      </Flex>
                    </Flex>
                    {(item.comment?.fromName || item.comment?.fromEmail) && item.createdAt &&
                <Text size="xs" c="dimmed">
                  <ReactTimeAgo date={new Date(item.createdAt)} />
                </Text>}
                  </Flex>
                  <Flex>
                    {item.comment?.toName && <>
                      <Text fw={600} fz='lg' mr='xs'>To:</Text>
                      <Text fw={600} fz='lg'>
                        {item.comment?.toName}
                      </Text></>}
                    {item.comment?.toEmail && <Text fz='lg'>
                      {`<${item.comment?.toEmail}>`}
                    </Text>}
                  </Flex>
                  <Flex justify='space-between'>
                    <Text w={720}>
                      {stripHtml(item.excerpt)}
                    </Text>
                    {!item.comment?.fromName && !item.comment?.fromEmail && item.createdAt &&
                <Text size="xs" c="dimmed">
                  <ReactTimeAgo date={new Date(item.createdAt)}/>
                </Text>}
                  </Flex>        
                </Box>
              </Flex>}</>)}

        {isSupportTicket && sortedTextItems && sortedTextItems.length > 0 &&
          sortedTextItems.map((item: any, index: number)=>
            <>
              {item.text && <Flex ml={35} mt={5}>
                {sortedTextItems[0]?.text && <Box mr='xs' w='33px' pt='4.4px'>
                  {item?.fromUser?.picture ? <Avatar  src={item?.fromUser?.picture} radius="xl" size={30}>
                    {getInitials(item?.comment?.fromName)}
                  </Avatar> : <Avatar radius="xl" size={30}/>}
                </Box>}
                <Box px={10} py={8}  sx={{borderRadius: '11px'}} bg={highestScoreIndex === index ? "#FFFFED": ''}>
                  <Flex justify='space-between'>
                    <Flex>
                      <Flex w={720}>
                        {item.comment?.fromName && <Text fw={600} fz='lg'>
                          {item.comment?.fromName}
                        </Text>}
                        {item.comment?.fromEmail && <Text fz='lg'>
                          {`<${item.comment?.fromEmail}>`}
                        </Text>}
                      </Flex>
                    </Flex>
                    {(item.comment?.fromName || item.comment?.fromEmail) && item.createdAt &&
                <Text size="xs" c="dimmed">
                  <ReactTimeAgo date={new Date(item.createdAt)} />
                </Text>}
                  </Flex>
                  <Flex>
                    {item.comment?.toName && <>
                      <Text fw={600} fz='lg' mr='xs'>To:</Text>
                      <Text fw={600} fz='lg'>
                        {item.comment?.toName}
                      </Text></>}
                    {item.comment?.toEmail && <Text fz='lg'>
                      {`<${item.comment?.toEmail}>`}
                    </Text>}
                  </Flex>
                  <Flex justify='space-between'>
                    <Text w={720}>
                      {stripHtml(item.text)}
                    </Text>
                    {!item.comment?.fromName && !item.comment?.fromEmail && item.createdAt &&
                <Text size="xs" c="dimmed">
                  <ReactTimeAgo date={new Date(item.createdAt)}/>
                </Text>}
                  </Flex>        
                </Box>
              </Flex>}</>)}

        <Box pl={35}>
          <Box mb="xs" mt='sm'>
            {cxItem && (
              <Group>
                {props.request?.chat && (
                  <Box>
                    <Title order={5} mb="6px">
                        Original item:
                    </Title>
                    <Flex mb='5px'>
                      <SourceIcon sourceName={origin} />
                      <Text
                        ml={8}
                        fz="xs"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {origin}
                        {cxItem?.link && (
                          <Anchor
                            ml="lg"
                            mt={"md"}
                            size={"xs"}
                            component={"a"}
                            target={"_blank"}
                            href={cxItem.link}
                          >
                              Go To {cxItem.originId}
                          </Anchor>
                        )}
                      </Text>
                    </Flex>
                    {/* <Text>{cxItem.title ?? ""}</Text>
                    <IdeaTextEditor
                      updateDescriptionRequest={()=>console.log()}
                      text={replaceNewlineWithBr(cxItemText) || ''}
                      editable={false}
                    /> */}
                  </Box>
                )}
              </Group>
            )}
            {!cxItem && origin && props.request.link && (
              <Group mt={"sm"}>
                {origin && (
                  <SourceIcon sourceName={origin} />
                )}
                <Text fz="sm" sx={{ textTransform: "capitalize" }}>
                  {origin || "bagel"}
                  {props.request?.updatedAt && (
                    <Text ml="lg" size="xs" c="dimmed" span tt={"lowercase"}>
                      {moment(new Date(props.request.updatedAt)).fromNow()}
                    </Text>
                  )}
                  {props.request?.link && (
                    <Anchor
                      ml="lg"
                      mt={"md"}
                      size={"sm"}
                      component={"a"}
                      target={"_blank"}
                      href={props.request.link}
                    >
                        Go To {props.request.itemKey || props.request.originId}
                    </Anchor>
                  )}
                </Text>
              </Group>
            )}
          </Box>
          <Box mb="md">
            {!jiraOrigin && hasIdeaLink && (
              <Group mt={"sm"}>
                {props.idea.origin && (
                  <SourceIcon sourceName={props.idea.origin} />
                )}
                <Text fz="sm" sx={{ textTransform: "capitalize" }}>
                  {props.idea.origin || "bagel"}
                  {props.idea?.updatedAt && (
                    <Text ml="lg" size="xs" c="dimmed" span tt={"lowercase"}>
                      {moment(new Date(props.idea.updatedAt)).fromNow()}
                    </Text>
                  )}
                  {props.idea?.link && (
                    <Anchor
                      ml="lg"
                      mt={"md"}
                      size={"sm"}
                      component={"a"}
                      target={"_blank"}
                      href={props.idea.link}
                    >
                        Go To {props.idea.itemKey || props.idea.originId}
                    </Anchor>
                  )}
                </Text>
              </Group>
            )}
            
          </Box>
        </Box>

        <Accordion
          mr='xl'
          pt={20}
          pl={35}
          sx={{ borderRadius: "8px" }}
          variant="filled"
          multiple
          chevronPosition="left"
        >
          {/* EvidenceType row */}
          {cxItem && (
            <EvidenceCard
              cxItem={cxItem}
              contact={contact}
              company={company}
              opType={cxItem?.opportunityType}
              requestText={cxItemText}
            />
          )}
          {company && <AccountCard company={company} />}
          {contact && <ContactCard contact={contact} />}
        </Accordion>
        {/*isRequest && (
            <IdeaCard
              idea={props.idea}
              isAllowToEditDates={isAllowedToEditDates(props.customizations)}
            />
          )} */}
      </Box>
    </ScrollArea>
  );
}
