import axios from 'axios';

const API_BASE_URL = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/tags/`;

export const fetchTags = async (getAuthHeaders: () => Promise<any>) => {
  try {
    const config = await getAuthHeaders();
    const response = await axios.get(API_BASE_URL, config);
    return response.data;
  } catch (e: any) {
    console.error('Error fetching tags:', e);
    throw e;
  }
};
  

export const createTag = async (
  tagData: { label: string; color: string; index: number },
  getAuthHeaders: () => Promise<any>
) => {
  try {
    const config = await getAuthHeaders();
    const response = await axios.post(API_BASE_URL, tagData, config);
    return response.data.tag;
  } catch (e: any) {
    console.error('Error creating tag:', e);
    throw e;
  }
};

export const updateTag = async (
  id: string,
  updatedTag: { label: string; color: string; index?: number },
  getAuthHeaders: () => Promise<any>
) => {
  const config = await getAuthHeaders();
  const response = await axios.put(`${API_BASE_URL}${id}`, updatedTag, config);
  return response.data;
};
  
  
export const deleteTag = async (id: string, getAuthHeaders: () => Promise<any>) => {
  try {
    const config = await getAuthHeaders();
    const response = await axios.delete(`${API_BASE_URL}${id}`, config);
    return response.data;
  } catch (error: any) {
    console.error("Error deleting tag:", error);
    throw error;
  }
};