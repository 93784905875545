
import CommentWithExcerpts from "./CommentWithExcerpts";
import CommentWithoutExcerpts from "./CommentWithoutExcerpts";

const TicketCommentItem = ({
  sortedItem,
  excerpts,
}: {
  sortedItem: any;
  excerpts: any[];
}) => {

  const isExcerpt = sortedItem.isExcerpt;

  const excerptsPerComment =
    isExcerpt &&
    excerpts.filter(
      (excerptItem: any) =>
        excerptItem.excerptCommentId === sortedItem.commentId
    );

  return (
    <>
      {isExcerpt ? (
        <CommentWithExcerpts
          sortedItem={sortedItem}
          excerpts={excerptsPerComment}
        />
      ) : (
        <CommentWithoutExcerpts sortedItem={sortedItem} />
      )}
    </>
  );
};

export default TicketCommentItem;
