import { Box, Stack, Text } from "@mantine/core";
import { BarProps } from "../../../../../types/types";
import { LIGHT_GREY_BG, MAIN_DARK_PRIMARY, PRIMARY_OPACITY_20, PRIMARY_OPACITY_40 } from "../../../../../constants/colors";

const Bar: React.FC<BarProps> = ({ displayContent, barWidthPercantage }) => {

  const width = `${barWidthPercantage}%`

  return (
    <Stack p={4} spacing={0} justify="center" sx={{ 
      position: 'relative' }}>
      <Box style={{ width: '100%', backgroundColor: LIGHT_GREY_BG, position: 'absolute', height: '100%', borderRadius: 4 }} />
      <Box sx={{ 
        width,
        backgroundColor: PRIMARY_OPACITY_20,
        position: 'absolute',
        height: '100%', borderRadius: 4, border: barWidthPercantage === 0 ? 'none' : `1px solid ${PRIMARY_OPACITY_40}` }} />
      <Text 
        color={MAIN_DARK_PRIMARY}
        pl={4}
        sx={{ 
          position: 'relative',
          zIndex: 1
        }}>{displayContent}</Text>
    </Stack>
  );
};

export default Bar;