import { useContext, useEffect, useState } from "react";
import {Box, Flex, Group, HoverCard, Text } from "@mantine/core";
import IdeaContext from "../IdeaContext";
import ImpactPieChart from "./ImpactPieChart";
import CompaniesList from "./Companies/CompaniesList";
import SyncContext from "../../../context/SyncContext";
import { calculateImpactResults, getNestedFieldValue } from "../helpers/calculateImpactResults";
import DiscoveryContext from "../../Discovery/DiscoveryContext";
import ImpactTooltip from "./ImpactTooltip/ImpactTooltip";

interface Company {
  _id: string;
  arr?: number;
  name: string;
}

interface Chat {
  _id: string;
  itemType?: string;
  amount?: number;
}

interface Request {
  company?: Company | null;
  chat?: Chat | null;
}

const Impact = ({ideaPage}:{ideaPage: boolean}) => {

  const { impactAggregationProperties } = useContext(SyncContext);

  const updatedImpactAggregationProperties = impactAggregationProperties.map((prop:any) => ({
    ...prop,
    propertyName: prop.propertyName.replace('cxItem', 'chat'),
    fieldPath: prop.fieldPath.replace('cxItem', 'chat'),
    groupBy: prop.groupBy ? prop.groupBy.replace('cxItem', 'chat') : prop.groupBy,
  }));


  const ContextToUse: React.Context<any> = 
  ideaPage ? IdeaContext : DiscoveryContext;
  const {
    displayedRequests,
    displayedMatches,
    /* displayedRequestsMatches, */
    ideaId,
    idea,
    showmatches,
    showValidated,
    showPending,
    activeInboxTab,
  } = useContext(ContextToUse);

  const [impactData, setImpactData] = useState<Record<string, any>>({});
  const [dynamicCount, setDynamicCount] = useState<number>(0);
  const [dynamicOrgIds, setDynamicOrgIds] = useState<string[]>([]);

  useEffect(() => {
    const _pendingRequests = displayedRequests?.filter(
      (r: any) => r.status !== "validated"
    ) || [];
    const _validatedRequests = displayedRequests?.filter(
      (r: any) => r.status === "validated"
    ) || [];

    const matchesCopy = !ideaPage ? [...displayedMatches/* , ...displayedRequestsMatches */] : activeInboxTab
      ? [ /* ...displayedRequestsMatches, */  ..._pendingRequests]
      : showmatches && !showValidated
        ? [...displayedMatches]
        : showmatches && showValidated
          ? [
            /* ...displayedRequestsMatches, */ 
            ..._pendingRequests,
            ...displayedMatches,
          ]
          : [];

    const validatedCopy = showValidated ? [..._validatedRequests] : [];

    // Collect company IDs from both pending and validated requests
    const allCompanyIdsSet = new Set<string>();

    const collectCompanyIds = (requests: Request[]) => {
      requests.forEach((request) => {
        const companyId = getNestedFieldValue(request, "company._id");
        if (companyId && companyId !== 'N/A') {
          allCompanyIdsSet.add(companyId);
        }
      });
    };

    collectCompanyIds(matchesCopy);
    collectCompanyIds(validatedCopy);

    // Update state with the total count and list of company IDs
    setDynamicCount(allCompanyIdsSet.size);
    setDynamicOrgIds(Array.from(allCompanyIdsSet));

    const updatedImpactAggregationProperties = impactAggregationProperties.map((prop:any) => ({
      ...prop,
      propertyName: prop.propertyName.replace('cxItem', 'chat'),
      fieldPath: prop.fieldPath.replace('cxItem', 'chat'),
      groupBy: prop.groupBy ? prop.groupBy.replace('cxItem', 'chat') : prop.groupBy,
    }));

    // console.log({ updatedImpactAggregationProperties });

    const validatedDeepCopy = structuredClone(validatedCopy)
    const matchesDeepCopy = structuredClone(matchesCopy)
  
    const companiesIds = new Set<string>();

    // Process validatedCopy
    validatedDeepCopy.forEach(item => {
      const companyId = item.company?._id;
      
      if (companyId) {
        
        if (companyId === 'N/A'){
          item.company = null;
        }
        if (companiesIds.has(companyId)) {
          item.company = null;
        } else {
          companiesIds.add(companyId);
        }
      }
    });

    // Process matchesCopy
    matchesDeepCopy.forEach(item => {
      const companyId = item.company?._id;
      
      if (companyId) {

        if (companyId === 'N/A'){
          item.company = null;
        }

        if (companiesIds.has(companyId)) {
          item.company = null;
        } else {
          companiesIds.add(companyId);
        }
      }
    });

    // Proceed with calculating impact

    const impactResults = calculateImpactResults(validatedDeepCopy, matchesDeepCopy, updatedImpactAggregationProperties, idea);
    setImpactData(impactResults);
  }, [
    displayedRequests,
    displayedMatches,
    /* displayedRequestsMatches, */
    ideaId,
    showmatches,
    showValidated,
    activeInboxTab,
  ]);

  return (
    <Box px={36} py={24}>
      <Flex mb={4}>
        <Text fw={600} fz={14} color="#212529" mb={15} mr={4}>
            Total:
        </Text>
        <CompaniesList
          dynamicCount={dynamicCount}
          dynamicOrgIds={dynamicOrgIds}
          ideaPage={ideaPage}
        />
      </Flex>

      <Group position="left" spacing={40}>
        {updatedImpactAggregationProperties
          .sort((a: any, b: any) => (a.impact?.index || 0) - (b.impact?.index || 0))
          .map((property: any) => {
            const { label, propertyName, impact } = property;

            const tooltip = impact?.tooltip
            const impactResult = impactData[propertyName] || {};

            return (
              <HoverCard
                disabled={!tooltip || activeInboxTab || !ideaPage}
                position="top"
                radius="md"
                shadow="md"
                styles={(theme) => ({
                  dropdown: {
                    transition: 'transform 0.1s ease, opacity 0.1s ease', // Custom transition for the dropdown
                    transitionDuration: '104ms', // Custom duration for the dropdown
                  },
                  arrow: {
                    transition: 'opacity 0.1s ease', // Optional: add transition for the arrow (if needed)
                  },
                })}
              >
                <HoverCard.Target>
                  <Box>
                    <ImpactPieChart
                      key={propertyName}
                      title={[label]}
                      validatedColor={
                        showValidated ? "#5C5CEB" : "rgba(92, 92, 235, 0.6)"
                      }
                      pendingColor={
                        showmatches || showPending
                          ? "rgba(92, 92, 235, 0.6)"
                          : "#5C5CEB"
                      }
                      monetaryValue={impactResult.total}
                      validatedMonetaryValue={impactResult.validatedTotal}
                      customerCount={impactResult.customerCount}
                      validatedCustomerCount={impactResult.validatedCustomerCount}
                      prefix={impact?.prefix}
                    />
                  </Box>
                </HoverCard.Target>
                <HoverCard.Dropdown bg="rgba(245, 245, 255, 1)">
                  <ImpactTooltip tooltip={tooltip} ideaPage={ideaPage}/>
                </HoverCard.Dropdown> 
              </HoverCard>   
            );
          })}
      </Group>
    </Box>
  );
};

export default Impact;