import axios from 'axios';
import { getAuthHeaders } from '../Api';

// Get the list of tags
export const getTags = (auth0: any): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/tags/`;
      const response = await axios.get(url, config);
      resolve(response.data.tags);
    } catch (e: any) {
      console.error(e);
      reject(e);
    }
  });
};

// Create a new tag
export const createTag = (
  tagData: { label: string; color: string, index: number },
  auth0: any
): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/tags/`;
      const response = await axios.post(url, tagData, config);
      resolve(response.data.tag);
    } catch (e: any) {
      console.error(e);
      reject(e);
    }
  });
};

// Get a specific tag by ID
export const getTagById = (id: string, auth0: any): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/tags/${id}`;
      const response = await axios.get(url, config);
      resolve(response.data.tag);
    } catch (e: any) {
      console.error(e);
      reject(e);
    }
  });
};

// Update a tag
export const updateTag = (
  id: string,
  tagData: { label?: string; color?: string, index?: number },
  auth0: any
): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/tags/${id}`;
      const response = await axios.put(url, tagData, config);
      resolve(response.data.tag);
    } catch (e: any) {
      console.error(e);
      reject(e);
    }
  });
};

// Delete a tag
export const deleteTag = (id: string, auth0: any): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/tags/${id}`;
      const response = await axios.delete(url, config);
      resolve(response.data.message);
    } catch (e: any) {
      console.error(e);
      reject(e);
    }
  });
};