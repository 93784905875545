import { Stack } from "@mantine/core";
import BodyHeader from "./BodyHeader";
import CardAttributes from "./CardAttributes";
import SupportTicket from "./calls-tickets-modals/Support-ticket/SupportTicket";
import GongCall from "./calls-tickets-modals/Gong-call/GongCall";
import ProductBoardTicket from "./calls-tickets-modals/ProductBoardTicket";
import CardTextArea from "./CardTextArea";

const CardBody = ({ item }: { item: any }) => {

  const origin = item?.chat?.origin || item?.origin || item?.textItem?.origin 
  const isGongCall = origin === "gong";
  const cxItem = item?.chat;
  const isSupportTicket = ['zendesk', 'intercom'].includes(cxItem?.origin) && cxItem?.itemType === "conversation";
  const isProductBoardTicket = origin === 'productboard'

  return (
    <Stack>
      <BodyHeader item={item} />
      <CardAttributes item={item} />
      {isSupportTicket ? (
        <SupportTicket item={item} />
      ) : isGongCall ? (
        <GongCall item={item} />
      ): 
        isProductBoardTicket ? (
          <ProductBoardTicket item={item} />)
          : (
            <CardTextArea item={item} />
          )}
    </Stack>
  );
};

export default CardBody;
