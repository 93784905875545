import React from "react";
import { useForm } from "@mantine/form";
import { TextInput, ColorInput, Button, Group } from "@mantine/core";
import { TAG_COLORS } from "../../constants/tags";

type TagModalProps = {
  tag?: any | null;
  onSave: (values: { _id?: string; label: string; color: string }) => void;
  onClose: () => void;
};

const TagModal: React.FC<TagModalProps> = ({ tag, onSave, onClose }) => {
  const form = useForm({
    initialValues: {
      _id: tag?._id || "",
      label: tag?.label || "",
      color: tag?.color || Object.values(TAG_COLORS)[0],
    },
    validate: {
      label: (value: any) => (value ? null : "Label is required"),
      color: (value: any) => (value ? null : "Color is required"),
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    onSave(values);
    onClose();
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <TextInput
        label="Label"
        placeholder="Enter tag label"
        {...form.getInputProps("label")}
        required
        mb="sm"
      />
      <ColorInput
        label="Color"
        placeholder="Pick a color"
        disallowInput
        withPicker={false}
        swatches={Object.values(TAG_COLORS)}
        rightSection
        {...form.getInputProps("color")}
        required
        mb="sm"
      />
      <Group position="right" mt="md">
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit">{tag?._id ? "Update" : "Create"}</Button>
      </Group>
    </form>
  );
};

export default TagModal;
