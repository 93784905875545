import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mantine/core";
import { useAuth0 } from "@auth0/auth0-react";
import { saveIdea } from "../../Api";
import SyncContext from "../../context/SyncContext";
import { getNestedFieldValue } from "../ideaNiv/helpers/calculateImpactResults";
import TagMenu from "./TagMenu";

export const IdeaTags: React.FC<any> = ({ idea, showAddBtn }) => {
  const { tagsOptions } = useContext(SyncContext);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [initialTags, setInitialTags] = useState<string[]>([]);

  const auth0 = useAuth0();

  useEffect(() => {
    setSelectedTags(idea?.tags || []);
    setInitialTags(idea?.tags || []);
  }, [idea]);

  const handleTagToggle = async (tagId: string) => {
    let updatedTags;
    if (selectedTags.includes(tagId)) {
      updatedTags = selectedTags.filter((id) => id !== tagId);
    } else {
      updatedTags = [...selectedTags, tagId];
    }

    setSelectedTags(updatedTags);

    if (JSON.stringify(updatedTags) !== JSON.stringify(initialTags)) {
      try {
        await saveIdea({ id: idea._id, tags: updatedTags }, auth0);
        setInitialTags(updatedTags);
      } catch (error) {
        console.error("Error updating idea:", error);
      }
    }
  };

  const handleMenuClose = () => {
    // Any additional logic when menu closes can be added here
    // For example, you might want to reset some state or trigger a re-render
  };

  return (
    <TagMenu
      selectedTags={selectedTags}
      onTagToggle={handleTagToggle}
      tagsOptions={tagsOptions}
      onClose={handleMenuClose}
      colTitle={"Tag"}
      showAddBtn={showAddBtn}
    />
  );
};
