import React from 'react';
import { Modal, Button, Text, Group, useMantineTheme } from '@mantine/core';

interface ConfirmOwnerChangeModalProps {
  opened: boolean;
  elementName: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmOwnerChangeModal: React.FC<ConfirmOwnerChangeModalProps> = ({
  opened,
  elementName,
  onConfirm,
  onCancel,
}) => {
  const theme = useMantineTheme();
  return (
    <Modal
      opened={opened}
      onClose={onCancel}
      overlayOpacity={0.55}
      overlayBlur={3}
      overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
    >
      <Text fw={700}>
        Changing the owner of the Product Area{' '}
        <Text fs="italic" span>
          {elementName}
        </Text>{' '}
        will also reassign all Ideas under this area to the new owner.{' '}
        <Text color="red">This is irreversible.</Text>
      </Text>
      <Text>Are you sure this is what you want? </Text>
      <Group position="right" mt="md">
        <Button
          bg="#5C5CEA"
          sx={{
            fontWeight: 700,
            ':hover': { backgroundColor: '#9f9af8' },
          }}
          color="#fff"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          bg="#5C5CEA"
          sx={{
            ':hover': { backgroundColor: '#9f9af8' },
            fontWeight: 700,
          }}
          color="#fff"
          onClick={onConfirm}
        >
          Save
        </Button>
      </Group>
    </Modal>
  );
};

export default ConfirmOwnerChangeModal;
