import { Box, Flex, Text } from "@mantine/core"

const CallTopInfo = (

  { dateDisplay,
    system,
    callDuration,
    bagelUsers,
    externalUsers
  }
     : 
     { 
        dateDisplay: string, 
        system: string, 
        callDuration: any,
        bagelUsers: any[],
        externalUsers: any[]
    }

) => {
  return (
    <Box mb="md" w={480} px='sm'>
      <Flex mb="sm">
        <Text mr={5} color="#5A5A5A">Recorded on </Text>
        <Text mr={5} color="#5A5A5A">{dateDisplay} </Text>
        <Text mr={5} color="#5A5A5A">via </Text>
        <Text mr={5} color="#5A5A5A">{system}, </Text>
        <Text color="#5A5A5A">{callDuration} </Text>
      </Flex>
    
      <Text color="#5A5A5A" mb='4px'>Participants:</Text>
      <Flex>
        <Text w={86}>
          {" "}
                      Getbagel:{" "}
        </Text>
    
        {/* bagelUsers */}
    
        <Box w={400}>
          {bagelUsers &&
                        bagelUsers?.length > 0 &&
                        bagelUsers?.map((user: any, index: number) => (
                          <Box key={index}>
                            {user?.name && <Text mr="3px" color="#5A5A5A">{user?.name || 'N/A'}</Text>}
                            {user?.emailAddress && <Flex>
                              <Text color="#5A5A5A">({user?.emailAddress || 'N/A'})</Text>
                              {index !== bagelUsers?.length - 1 && <Text color="#5A5A5A">,</Text>}
                            </Flex>}
                            
                          </Box>
                        ))}{" "}
        </Box>
      </Flex>
    
      {/* externalUsers */}
    
      <Flex>
        <Text w={86}>
          {" "}
                      External:
        </Text>
        <Box w={400}>
          {externalUsers &&
                        externalUsers?.length > 0 &&
                        externalUsers?.map((user: any, index: number) => (
                          <Box>
                            {user?.name && <Text mr="3px" color="#5A5A5A">{user?.name || 'N/A'}</Text>}
                            {user?.emailAddress && <Flex>
                              <Text color="#5A5A5A"> ({user?.emailAddress || 'N/A'})</Text>
                              {index !== externalUsers?.length - 1 && <Text color="#5A5A5A">,</Text>}
                            </Flex>}
                            
    
                          </Box>
                        ))}{" "}
        </Box>
      </Flex>
    </Box>
  )
}

export default CallTopInfo