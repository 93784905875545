import { IdeaProperty, GroupsRecord, ExtendedIdeaProperty } from "../types";


/**
 * Build a groupId from object + attribute
 */
export function getGroupId(object: string, attribute: string) {
  return `${object}.${attribute}`;
}

/** 
 * Convert a DB fieldPath to object + attribute.
 * - "cxItem.something" => { object: "cxItem", attribute: "something" }
 * - "company.foo.bar" => { object: "company", attribute: "foo.bar" }
 * - "priority" => { object: "request", attribute: "priority" }
 */
export function parseDBFieldPath(fieldPath: string): { object: string; attribute: string } {
  if (fieldPath.startsWith("cxItem.")) {
    return {
      object: "cxItem",
      attribute: fieldPath.substring("cxItem.".length),
    };
  }
  if (fieldPath.startsWith("company.")) {
    return {
      object: "company",
      attribute: fieldPath.substring("company.".length),
    };
  }
  // Otherwise, treat everything as the attribute, with "request" as object
  return {
    object: "request",
    attribute: fieldPath,
  };
}

/** 
 * Convert our (object, attribute) pair back to a DB fieldPath.
 * If object = "request", the DB fieldPath is just the attribute.
 * Otherwise, it's "object.attribute".
 */
export function toDBFieldPath(object: string, attribute: string): string {
  if (object === "request") {
    return attribute; 
  }
  return `${object}.${attribute}`;
}

/** 
 * Convert a DB groupBy string into (groupObject, groupAttribute) for the UI.
 * e.g. "company.plan" => { groupObject: "company", groupAttribute: "plan" },
 *      "status" => { groupObject: "request", groupAttribute: "status" },
 *      "" or undefined => none
 */
export function parseDBGroupBy(groupBy?: string): { groupObject?: string; groupAttribute?: string } {
  if (!groupBy) {
    return { groupObject: "", groupAttribute: "" };
  }
  if (groupBy.startsWith("cxItem.")) {
    return {
      groupObject: "cxItem",
      groupAttribute: groupBy.substring("cxItem.".length),
    };
  }
  if (groupBy.startsWith("company.")) {
    return {
      groupObject: "company",
      groupAttribute: groupBy.substring("company.".length),
    };
  }
  // Otherwise, "request"
  return {
    groupObject: "request",
    groupAttribute: groupBy,
  };
}

/** 
 * Convert (groupObject, groupAttribute) back to a DB groupBy string.
 * If groupObject = "request", groupBy = groupAttribute only.
 */
export function toDBGroupBy(groupObject?: string | null, groupAttribute?: string| null): string | undefined {
  if (!groupObject && !groupAttribute) return undefined;
  if (!groupObject || !groupAttribute) return undefined;
  if (groupObject === "request") {
    return groupAttribute || undefined;
  }
  return `${groupObject}.${groupAttribute}`;
}

/**
 * Convert DB condition object => front-end fields condition + conditionValue
 * e.g. { "$eq": "match" } => { condition: "$eq", conditionValue: "match" }
 * If condition is undefined, return { condition: undefined, conditionValue: undefined }
 */
export function parseDBCondition(dbCondition?: Record<string, any>| null): {
  condition?: '$eq' | '$ne' | '$gte' | '$lte';
  conditionValue?: any;
} {
  if (!dbCondition) {
    return { condition: undefined, conditionValue: undefined };
  }

  const keys = Object.keys(dbCondition);
  if (keys.length !== 1) {
    // In case there's more than one operator, handle or ignore
    // For simplicity, we handle the first
    console.warn("Multiple operators found in condition. Using the first one.");
  }
  const op = keys[0] as '$eq' | '$ne' | '$gte' | '$lte' | undefined;
  const val = dbCondition[op as string];
  return {
    condition: op,
    conditionValue: val,
  };
}

/**
 * Convert front-end fields condition + conditionValue => DB condition object
 * e.g. condition="$eq", conditionValue="match" => { "$eq": "match" }
 * If condition or conditionValue is missing, return undefined
 */
export function toDBCondition(condition?: string| null, conditionValue?: any| null): Record<string, any> | undefined {
  if (!condition || conditionValue === undefined || conditionValue === '') {
    return undefined;
  }
  return {
    [condition]: conditionValue,
  };
}

/** 
 * Build a dictionary of groups from the array of DB properties.
 * We parse fieldPath => (object, attribute)
 * We parse groupBy => (groupObject, groupAttribute)
 * We parse condition => (condition, conditionValue)
 */
export function buildGroupsFromProperties(propertiesData: IdeaProperty[]): GroupsRecord {
  const groups: GroupsRecord = {};

  for (const prop of propertiesData) {
    const rawFieldPath = prop.fieldPath || "";
    const { object, attribute } = parseDBFieldPath(rawFieldPath);

    // Parse groupBy
    const { groupObject, groupAttribute } = parseDBGroupBy(prop.groupBy);

    // Parse condition
    const parsedCondition = parseDBCondition(prop.condition);

    // Create an "Extended" property for the front-end
    const uiProp: ExtendedIdeaProperty = {
      ...prop,
      // groupBy fields
      groupObject,
      groupAttribute,
      // condition fields
      //@ts-ignore
      condition: parsedCondition.condition,
      conditionValue: parsedCondition.conditionValue,
    };

    const groupId = getGroupId(object, attribute);
    if (!groups[groupId]) {
      groups[groupId] = {
        object,
        attribute,
        properties: [],
      };
    }
    groups[groupId].properties.push(uiProp);
  }

  // Sort the group keys and reconstruct the sorted object
  const sortedGroups = Object.keys(groups)
    .sort()
    .reduce((sorted: GroupsRecord, key) => {
      sorted[key] = groups[key];
      return sorted;
    }, {});

  return sortedGroups;
}
