import { Flex, Text } from "@mantine/core";
import { getNestedFieldValue } from "../../../ideaNiv/helpers/calculateImpactResults";
import { IconAlertOctagon } from "@tabler/icons";
import { DEFAULT_PRIORITY_BACKGROUND_COLOR, DEFAULT_PRIORITY_COLOR } from "../../../../constants/colors";

// PriorityCell Component
export const PriorityCell: React.FC<any> = ({ data, ideaPriorities, col }) => {
  const priority = getNestedFieldValue(data, col.accessor);
  const defaultPriority = ideaPriorities?.find((p: any) => p?.isDefault);
  const selectedPriority = priority
    ? ideaPriorities?.find(
      (p: any) =>
        p?.key?.toLowerCase() === priority?.toLowerCase() ||
            p?.label?.toLowerCase() === priority?.toLowerCase()
    )
    : {
      backgroundColor: DEFAULT_PRIORITY_BACKGROUND_COLOR,
      color: DEFAULT_PRIORITY_COLOR,
      index: 1,
      key: "Unassigned",
      label: "Unassigned",
    };

  const color = selectedPriority?.color || defaultPriority?.color || DEFAULT_PRIORITY_COLOR
  const backgroundColor = selectedPriority?.backgroundColor || defaultPriority?.backgroundColor || DEFAULT_PRIORITY_BACKGROUND_COLOR

  return (
    <Flex >
      
      <Flex  sx={{backgroundColor, borderRadius : 4.7}} p={3}>
        <IconAlertOctagon color={color} size={14} />
        <Text color={color}  ml={4} >
          {selectedPriority?.label ||  defaultPriority?.label ||  "Unassigned"}
        </Text>
      </Flex>
      
    </Flex>
  );
};