import { useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useClickOutside, useElementSize } from "@mantine/hooks";
import { Calendar } from "@mantine/dates";
import { IconCheck, IconExternalLink, IconSearch } from "@tabler/icons";
import {
  Grid,
  Box,
  Divider,
  Card,
  Group,
  Tabs,
  Menu,
  UnstyledButton,
  Modal,
  Autocomplete,
  Button,
  Paper,
  LoadingOverlay,
  Flex,
  ActionIcon,
  Text,
  Tooltip,
  Textarea,
} from "@mantine/core";
import { IdeaActions } from "./IdeaActions";
import { IdeaTextEditor } from "./IdeaTextEditor";
import { InsightsTable } from "./InsightsTable";
import {
  getIdea,
  getIdeaMatches,
  getRequest,
  saveIdea,
  searchMembers,
} from "../../Api";

import { useLocation, useParams } from "react-router-dom";
import RightBar from "./RightBar";
import { getQuarter } from "../../utils";
import { InfoCard } from "./InfoCard";
import { Highlights } from "./Highlights";
import { SourceIcon } from "../../utils/SourceIcon";
import moment from "moment";
import { NewRequestModal } from "./RequestModal";
import AutoCompleteInnerItem from "./AutoCompleteInnerItem";
import { AddRequest } from "./AddRequest";
import { isAllowedToEditDates } from "../../utils/isAllowedToEditDates";
import { ProductLink } from "./ProductLink";
import SyncContext from "../../context/SyncContext";
import { openModal } from "@mantine/modals";
import BagelAutocomplete from "../general/BagelAutocomplete";
import { showNotification } from "@mantine/notifications";

export const parseQueryParams = (search: string) => {
  return new URLSearchParams(search);
};

interface DisplayState {
  [value: string]: boolean;
}

export function IdeaPage() {
  const auth0 = useAuth0();
  const {
    deliveryPlatform,
    fields,
    components,
    allComponents,
    ideaStatuses,
    ideaPriorities,
    editCustomizations,
    domains,
    displayState,
  } = useContext(SyncContext);
  console.log({ editCustomizations });
  const location = useLocation();
  const { id: ideaId } = useParams();
  const { ref, height } = useElementSize();
  const [ideaLoading, setIdeaLoading] = useState<boolean>(false);
  const [matchesLoading, setMatchesLoading] = useState<boolean>(false);
  const [showRequestProductLink, setShowRequestProductLink] =
    useState<boolean>(false);
  const [idea, setIdea] = useState<any>(null);
  const [domain, setDomain] = useState();
  const [title, setTitle] = useState<string>("");
  const [matches, setMatches] = useState<any[]>([]);
  const [requests, setRequests] = useState<any[]>([]);
  const [validatedRequests, setValidatedRequests] = useState<any[]>([]);
  const [modalOpened, setModalOpened] = useState({
    rolloutDate: false,
    owner: false,
    request: false,
  });

  const minSelectableDate = new Date();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const refDatePicker = useClickOutside(() => setIsDatePickerOpen(false));

  const [ownerValue, setOwnerValue] = useState<string>("");
  const [usersList, setUsersList] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [rolloutDateSelect, onChangeRolloutDate] = useState<Date | null>(
    new Date()
  );
  
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [displayDate, setDisplayDate] = useState(
    moment(new Date()).format("D MMM YY")
  );

  const [loading, setLoading] = useState({
    setOwner: false,
    rolloutDate: false,
    sync: false,
  });
  const [loadingSync, setLoadingSync] = useState(true);
  const [trigger, setTrigger] = useState(0);
  const [computed, setComputed] = useState("");
  const [customMatches, setCustomMatches] = useState("");
  const [componentsByDomain, setComponentsByDomain] = useState<any[]>([]);

  const loadFields = () => {
    const flags = fields?.flags ?? [];
    flags.forEach((flag: any) => {
      if (flag.name === "showRequestProductLink" && flag.value === true) {
        setShowRequestProductLink(true);
      }
    });
  };

  const [requestsList, setRequestsList] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string | null>("requests");
  const selectableStatuses =
    ideaStatuses?.filter((i: any) => !i?.isDefaultDelivery) || [];

  const handleModalOpen = (id: string, _bool: boolean, modalType: string) => {
    const allRequests = requests
      .concat(validatedRequests)
      .concat(matches)
      .concat(customMatches);
    const clickedRequest = allRequests.filter((request) => request._id == id);
    openModal({
      id: "NewRequestModal",
      size: "calc(100vw - 40px)",
      // h: "100vh",
      withCloseButton: false,
      padding: 0,
      children: (
        <>
          <NewRequestModal
            idea={idea}
            request={{ ...clickedRequest[0], modalType }}
            setValidatedRequests={setValidatedRequests}
            onUpdated={reloadIdea}
            customizations={editCustomizations}
            matches={matches}
            setMatches={setMatches}
          />
        </>
      ),
    });
  };

  const fetchRequest = async (requestId: string) => {
    try {
      const request = await getRequest(requestId, auth0);
      console.log({ fetchRequest: request });
      return request;
    } catch (error) {
      console.error("Error fetching the request:", error);
    }
  };

  /* useEffect(() => {
    const queryParams = parseQueryParams(location.search);
    const requestId = queryParams.get("requestId");

    if (requestId) {
      (async () => {
        const requestRes = await fetchRequest(requestId);

        if (requestRes) {
          openModal({
            id: "useEffectNewRequestModal",
            size: "calc(100vw - 40px)",
            withCloseButton: false,
            padding: 0,
            children: (
              <>
                <NewRequestModal
                  idea={requestRes?.idea}
                  request={{ ...requestRes, modalType: "request" }}
                  setValidatedRequests={setValidatedRequests}
                  onUpdated={reloadIdea}
                  customizations={editCustomizations}
                  matches={matches}
                  setMatches={setMatches}
                />
              </>
            ),
          });
        }
      })();
    }
  }, []); */

  const getSuggestions = () => {
    if (ideaId) {
      setMatchesLoading(true);
      getIdeaMatches(ideaId, auth0)
        .then((res: any) => {
          setMatches(res?.matches || []);
          setCustomMatches(res?.custom || []);
          setComputed(res?.computed || "");
        })
        .catch((e: any) => console.log(e))
        .finally(() => {
          setMatchesLoading(false);
        });
    }
  };

  const getSuggestionsUpdate = () => {
    if (ideaId && trigger > 0) {
      setMatchesLoading(true);
      getIdeaMatches(ideaId, auth0)
        .then((res: any) => {
          setMatches(res?.matches || []);
          setComputed(res?.computed || "");
        })
        .catch((e: any) => console.log(e))
        .finally(() => {
          setMatchesLoading(false);
        });
    }
  };

  const reloadIdea = (useLoading?: boolean) => {
    ideaId && useLoading && setIdeaLoading(true);
    ideaId &&
      getIdea(ideaId, auth0)
        .then((ideaData) => {
          if (ideaData.domain) {
            ideaData.domain = null;
          }
          setIdea(ideaData);
          if (ideaData.componentObj?.domain) {
            setDomain(ideaData.componentObj?.domain);
          }
          setTitle(ideaData.title);
          if (ideaData.components) {
            ideaData.components.sort((a: any, b: any) => a.index - b.index);
            setComponentsByDomain(ideaData.components);
          }
          if (ideaData.requests.length > 0) {
            setRequests(
              ideaData.requests.filter(
                (request: any) => request.status !== "validated"
              )
            );
            setValidatedRequests(
              ideaData.requests.filter(
                (request: any) => request.status == "validated"
              )
            );
          } else {
            setRequests([]);
            setRequestsList([]);
            setValidatedRequests([]);
          }
          if (ideaData.matchesComputeStatus === "done") {
            setComputed("done");
          }

          getSuggestions();
        })
        .catch((e) => console.log(e))
        .finally(() => useLoading && setIdeaLoading(false));
  };
  const updateIdeaTitle = (title: string) => {
    setTrigger(0);
    if (idea && idea.title !== title) {
      const ideaObj = {
        id: idea._id,
        title: title,
      };
      saveIdea(ideaObj, auth0)
        .then((response) => {
          setIdea((prevState: any) => ({
            ...prevState,
            title,
          }));
        })
        .catch((e: any) => console.log(e));
    }
  };

  const updateDescriptionRequest = (html: string) => {
    setTrigger(0);
    if (idea && idea.description !== html) {
      const requestObj = {
        id: idea._id,
        description: html,
      };
      saveIdea(requestObj, auth0)
        .then((response) => {
          setIdea((prevState: any) => ({
            ...prevState,
            description: html,
          }));
        })
        .catch((e: any) => console.log(e));
    }
  };

  const onSelectSave = (type: string, value: string) => {
    if (idea) {
      const ideaObj = {
        id: idea._id,
        [type]: value,
      };
      saveIdea(ideaObj, auth0)
        .then((response) => {
          if (type === "componentId") {
            const found = allComponents.find((c: any) => c._id === value);
            setIdea((prevState: any) => ({
              ...prevState,
              componentObj: found,
            }));
          }
          setIdea((prevState: any) => ({
            ...prevState,
            [type]: value,
          }));
        })
        .catch((e: any) => console.log(e));
    }
  };

  const onChangeOwnerAutocomplete = (value: string) => {
    setOwnerValue(value);
    searchMembers(value, auth0)
      .then((response) => {
        setUsersList(
          response.map((item: any) => ({
            value: item.name,
            sources: item.sources,
            email: item.email,
            _id: item._id,
            name: item.name,
            key: item._id,
          }))
        );
      })
      .catch((e: any) => console.log(e));
  };

  const connectOwner = () => {
    setLoading((prevState) => ({
      ...prevState,
      setOwner: true,
    }));
    const requestObj = {
      id: idea._id,
      ownerName: selectedUser.name,
      ownerId: selectedUser._id,
    };
    saveIdea(requestObj, auth0)
      .then((response) => {
        setIdea((prevState: any) => ({
          ...prevState,
          ownerId: selectedUser._id,
          owner: { ...selectedUser },
        }));
      })
      .catch((e: any) => console.log(e))
      .finally(() => {
        setLoading((prevState) => ({
          ...prevState,
          setOwner: false,
        }));
        setModalOpened((prevState) => ({
          ...prevState,
          owner: false,
        }));
      });
  };

  const updateRolloutDate = (newDate: Date) => {
    if (idea) {
      setLoading((prevState) => ({
        ...prevState,
        rolloutDate: true,
      }));

      const requestObj = {
        id: idea._id,
        rolloutDate: newDate,
      };
      saveIdea(requestObj, auth0)
        .then((response) => {
          setIdea((prevState: any) => ({
            ...prevState,
            rolloutDate: newDate,
          }));
          onChangeRolloutDate(newDate);
          setIsDatePickerOpen(false);
          showNotification({
            title: "Exp. delivery date updated",
            color: "teal",
            message: ``,
            icon: <IconCheck size="1.1rem" />,
            autoClose: 1200
          });
        })
        .catch((e: any) => console.log(e))
        .finally(() => {
          setLoading((prevState) => ({
            ...prevState,
            rolloutDate: false,
          }));
          setModalOpened((prevState) => ({
            ...prevState,
            rolloutDate: false,
          }));
        });
    }
  };
 

  const timer = () => {
    setTimeout(
      () => {
        setTrigger(trigger + 1);
      },
      trigger < 2 ? 15000 : 60000
    );
  };

  useEffect(() => {
    if (idea?.domain && displayState?.domain && !idea?.componentId) {
      filterComponentByDomain(idea?.domain);
    }
    loadFields();
  }, []);

  useEffect(() => {
    if (deliveryPlatform) {
      setLoadingSync(false);
    }
  }, [deliveryPlatform]);

  console.log({ displayState });
  const filterComponentByDomain = (domainValue: string) => {
    const copy = [...components];
    const arr: any[] = copy.filter((s: any) => s.domain === domainValue) || [];
    setComponentsByDomain(arr);
    const ids = arr.map((a: any) => a._id?.toLowerCase());
    if (!ids.includes(idea?.componentObj?._id?.toLowerCase())) {
      setIdea((prevState: any) => ({
        ...prevState,
        componentObj: { name: "Unassigned", domain: "Unassigned" },
        componentId: null,
      }));
    }
  };

  useEffect(() => {
    reloadIdea();
    onChangeOwnerAutocomplete(ownerValue);
  }, [location]);

  const defaultPriority = ideaPriorities?.find((p: any) => p?.isDefault);
  const selectedPriority = ideaPriorities?.find(
    (priority: any) =>
      priority.key?.toLowerCase() == idea?.priority?.toLowerCase()
  );
  const selectedStatus = ideaStatuses?.find(
    (status: any) => status.key?.toLowerCase() == idea?.status?.toLowerCase()
  );

  const isStatusInDelivery: boolean =
    selectedStatus?.key ==
    ideaStatuses?.filter((s: any) => s.isDefaultDelivery)?.[0]?.key;

  const hasOrigin: boolean =
    idea?.origin === "jira" ||
    idea?.origin === "clickup" ||
    idea?.origin === "productboard";

  const hideaMatchUponaddingAsEvidence = (index: number) => {
    const copy = [...matches];
    copy.splice(index, 1);
    setMatches(copy);
  };

  return (
    <>
      {idea && (
        <Box bg={"#f9fafb"}>
          <Modal
            centered
            opened={modalOpened["owner"]}
            onClose={() =>
              setModalOpened((prevState) => ({
                ...prevState,
                owner: false,
              }))
            }
            title="Search for owner"
          >
            <Flex>
              <Autocomplete
                mr="5px"
                w="350px"
                placeholder="Pick an owner"
                value={ownerValue}
                itemComponent={AutoCompleteInnerItem}
                onChange={onChangeOwnerAutocomplete}
                limit={50}
                maxDropdownHeight={300}
                data={usersList}
                onItemSubmit={(item) => setSelectedUser(item)}
                filter={(value, user) =>
                  user?.name
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase().trim()) ||
                  user.email
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase().trim())
                }
              />
              <IconSearch size={18} />
            </Flex>

            <Button
              styles={(theme) => ({
                root: {
                  backgroundColor: "#5B60E3",
                  border: 0,
                  height: 35,
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#5B60E3", 0.05),
                  },
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
              mt="sm"
              px="xl"
              radius="md"
              onClick={connectOwner}
              loading={loading["setOwner"]}
            >
              Assign owner
            </Button>
          </Modal>
          <Box bg={"#fff"} ref={ref}>
            <Box pt="sm" pb="md">
              <Grid>
                <Grid.Col span={9} p={0} pt={4}>
                  {hasOrigin ? (
                    <Flex>
                      {" "}
                      <Tooltip label={title}>
                        <Text
                          truncate
                          pl="lg"
                          mr="sm"
                          fz="22px"
                          fw="bold"
                          lh="1.4"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          {title}
                        </Text>
                      </Tooltip>
                      {(idea.origin === "jira" ||
                        idea.origin === "clickup" ||
                        idea.origin === "productboard") && (
                        <Menu>
                          <Menu.Target>
                            <Tooltip
                              label={
                                idea.itemKey || idea.originId || "Unlinked"
                              }
                            >
                              <Box mt="2px">
                                <SourceIcon
                                  sourceName={idea.origin}
                                  width={25}
                                  height={25}
                                />
                              </Box>
                            </Tooltip>
                          </Menu.Target>

                          {hasOrigin && (
                            <Menu.Dropdown>
                              <Menu.Item>
                                <Button
                                  component="a"
                                  variant="outline"
                                  styles={() => ({
                                    root: {
                                      borderColor: "#5B60E3",
                                      color: "#5B60E3",
                                      height: 35,
                                    },
                                  })}
                                  radius="md"
                                  href={idea.link}
                                  size="sm"
                                  tt={"capitalize"}
                                  fullWidth
                                  rightIcon={<IconExternalLink size={16} />}
                                  target="_blank"
                                >
                                  {`Open in ${idea.origin}`}
                                </Button>
                              </Menu.Item>
                            </Menu.Dropdown>
                          )}
                        </Menu>
                      )}
                    </Flex>
                  ) : (
                    <Flex>
                      <Textarea
                        autosize
                        maxRows={4}
                        w="100%"
                        ml="4px"
                        placeholder="Title"
                        styles={() => ({
                          input: {
                            border: "none",
                            fontSize: "22px",
                            lineHeight: "1.4",
                            fontWeight: "bold",
                            "&:focus-within": {
                              border: "none",
                            },
                          },
                        })}
                        value={title}
                        onChange={(e: any) => {
                          setTitle(e.target.value);
                        }}
                        onBlur={(e: any) => {
                          updateIdeaTitle(title);
                        }}
                      />
                    </Flex>
                  )}
                </Grid.Col>
                <Grid.Col span={3} p={0} pt={4} pr={6}>
                  <Flex justify="flex-end" pr={6}>
                    <IdeaActions
                      idea={idea}
                      validatedRequestsLength={validatedRequests.length}
                    />
                  </Flex>
                </Grid.Col>
                <Grid.Col span={9}>
                  <IdeaTextEditor
                    updateDescriptionRequest={updateDescriptionRequest}
                    text={idea.description}
                    editable={!hasOrigin}
                  />
                </Grid.Col>
              </Grid>
            </Box>
          </Box>
          <Divider m="0px !important" my="0" />
          <Grid mih={`calc(100vh - ${height}px - 63px)`} m="0px">
            <LoadingOverlay visible={ideaLoading} />
            <Grid.Col span={9} p={0} mah={`calc(100vh - ${height}px - 63px)`}>
              <Box
                p="lg"
                pt={"md"}
                sx={{
                  height: "100%",
                  overflow: "hidden",
                  overflowY: "scroll",
                }}
              >
                <Group spacing="md" mb="md" grow>
                  {displayState?.status && (
                    <Menu shadow="md" width={200}>
                      <Menu.Target>
                        <UnstyledButton
                          sx={
                            hasOrigin || isStatusInDelivery
                              ? { cursor: "default" }
                              : { cursor: "pointer" }
                          }
                          disabled={isStatusInDelivery}
                        >
                          <InfoCard
                            label="Status"
                            text={selectedStatus?.key ?? "Unassigned"}
                            width="100%"
                            cardProps={{
                              opacity: isStatusInDelivery ? 0.5 : 1,
                            }}
                          />
                        </UnstyledButton>
                      </Menu.Target>

                      {!hasOrigin && (
                        <Menu.Dropdown>
                          <Menu.Label>Choose one...</Menu.Label>
                          {selectableStatuses &&
                            selectableStatuses.length > 0 &&
                            selectableStatuses?.map((status: any) => (
                              <Menu.Item
                                key={status.key}
                                onClick={() =>
                                  onSelectSave("status", status.key)
                                }
                                tt={"capitalize"}
                              >
                                {status.label}
                              </Menu.Item>
                            ))}
                        </Menu.Dropdown>
                      )}
                    </Menu>
                  )}

                  {displayState.rolloutDate && isAllowedToEditDates(editCustomizations)  ? (
                    <div ref={refDatePicker}>
                      <Menu shadow="md" withArrow opened={isDatePickerOpen}>
                        <Menu.Target>
                          <UnstyledButton
                            onClick={() => setIsDatePickerOpen((o) => !o)}
                            style={{ width: "100%" }}
                          >
                            <InfoCard
                              isAllowedToEditDates={true}
                              label="Exp. delivery date"
                              text={
                                idea.rolloutDate
                                  ? moment(new Date(idea.rolloutDate)).format(
                                    "D MMM YY"
                                  )
                                  : "Unassigned"
                              }
                            />
                          </UnstyledButton>
                        </Menu.Target>
                        {isDatePickerOpen && (
                          <Menu.Dropdown maw={500}>
                            <LoadingOverlay visible={loading.rolloutDate} />

                            <Calendar
                              size="xs"
                              /* value={rolloutDateSelect} */
                              /* onChange={updateRolloutDate} */
                            />
                          </Menu.Dropdown>
                        )}
                      </Menu>
                    </div>
                  ) : (
                    displayState.rolloutDate && (
                      <InfoCard
                        label="Exp. delivery date"
                        text={
                          idea.rolloutDate
                            ? getQuarter(new Date(idea.rolloutDate))
                            : "Unassigned"
                        }
                      />
                    )
                  )}

                  {displayState.priority && (
                    <Menu shadow="md" width={200}>
                      <Menu.Target>
                        <UnstyledButton
                          sx={
                            hasOrigin
                              ? { width: "calc(20% - 12px)", cursor: "default" }
                              : { width: "calc(20% - 12px)" }
                          }
                        >
                          <InfoCard
                            label="Priority"
                            text={
                              selectedPriority?.label ||
                              defaultPriority?.label ||
                              "Unassigned"
                            }
                            icon={
                              <div
                                style={{
                                  borderRadius: "8px",
                                  width: "15px",
                                  height: "15px",
                                  backgroundColor:
                                    selectedPriority?.color ||
                                    defaultPriority?.color ||
                                    "#ccc",
                                }}
                              ></div>
                            }
                            width="100%"
                          />
                        </UnstyledButton>
                      </Menu.Target>
                      {!hasOrigin && (
                        <Menu.Dropdown>
                          <Menu.Label>Choose one...</Menu.Label>
                          {ideaPriorities &&
                            ideaPriorities?.map((priority: any) => (
                              <Menu.Item
                                key={priority.key}
                                onClick={() =>
                                  onSelectSave("priority", priority.key)
                                }
                              >
                                {priority.label}
                              </Menu.Item>
                            ))}
                        </Menu.Dropdown>
                      )}
                    </Menu>
                  )}
                  {hasOrigin ? (
                    <UnstyledButton sx={{ cursor: "default" }}>
                      <InfoCard
                        label="Owner"
                        text={idea.owner?.name ?? "Unassigned"}
                      />
                    </UnstyledButton>
                  ) : (
                    <UnstyledButton
                      onClick={() => {
                        onChangeOwnerAutocomplete("");
                        setModalOpened((prevState) => ({
                          ...prevState,
                          owner: true,
                        }));
                      }}
                    >
                      <InfoCard
                        label="Owner"
                        text={
                          idea.owner?.name ?? idea.ownerName ?? "Unassigned"
                        }
                      />
                    </UnstyledButton>
                  )}
                  {hasOrigin ||
                    (displayState.domain && (
                      <BagelAutocomplete
                        items={domains}
                        selected={domain}
                        onSelect={(item) => {
                          setDomain(item.name);
                          filterComponentByDomain(item.name);
                        }}
                      >
                        <UnstyledButton
                          sx={{
                            width: "calc(20% - 12px)",
                          }}
                        >
                          <InfoCard
                            label="Domain"
                            text={domain || "Unassigned"}
                            width="100%"
                          />
                        </UnstyledButton>
                      </BagelAutocomplete>
                    ))}
                  {displayState.component && (
                    <BagelAutocomplete
                      disabled={hasOrigin || (displayState.domain && !domain)}
                      items={
                        displayState.domain ? componentsByDomain : components
                      }
                      selected={idea.componentObj?.name}
                      onSelect={(item) => {
                        onSelectSave("componentId", item?._id || ""); 
                      }}
                    >
                      <UnstyledButton
                        sx={
                          idea.itemKey ||
                          (!domain && displayState.domain) ||
                          domain === "Unassigned"
                            ? { width: "calc(20% - 12px)", cursor: "default" }
                            : { width: "calc(20% - 12px)" }
                        }
                      >
                        <InfoCard
                          label="Product area"
                          text={idea.componentObj?.name || "Unassigned"}
                          width="100%"
                          sx={{
                            opacity: !domain && displayState.domain ? 0.5 : 1,
                          }}
                        />
                      </UnstyledButton>
                    </BagelAutocomplete>
                  )}

                  {displayState.delivery && deliveryPlatform ? (
                    <ProductLink
                      request={idea}
                      showRequestProductLink={showRequestProductLink}
                      getRequestFn={reloadIdea}
                      itemKey={idea.itemKey ?? ""}
                      originType={idea.originType}
                    />
                  ) : (
                    displayState.delivery && (
                      <ActionIcon loading={loadingSync}>
                        <InfoCard
                          label={deliveryPlatform}
                          text="Link"
                          icon={
                            <SourceIcon
                              sourceName={deliveryPlatform}
                              width={25}
                              height={25}
                            />
                          }
                          cardProps={{ opacity: 0.5 }}
                        />
                      </ActionIcon>
                    )
                  )}
                </Group>
                <Card p="lg" radius="md" mb="lg" withBorder>
                  <Card.Section>
                    <Highlights
                      idea={idea}
                      matches={matches}
                      isAllowedToEditDates={isAllowedToEditDates(
                        editCustomizations
                      )}
                    />
                  </Card.Section>
                </Card>
                <Paper p="sm" radius="md" withBorder mt="lg" bg={"#F1F3F5"}>
                  <Tabs value={activeTab} onTabChange={setActiveTab}>
                    <Group position="apart">
                      <Tabs.List
                        sx={{ borderBottom: 0, cursor: "default" }}
                        mb="md"
                      >
                        <Tabs.Tab
                          sx={{ borderBottom: 0, cursor: "default" }}
                          value="evidence"
                        >
                          Evidence
                        </Tabs.Tab>
                      </Tabs.List>

                      <AddRequest
                        requestId={ideaId ?? ""}
                        idea={idea}
                        attachedRequestIds={validatedRequests
                          .concat(requests)
                          .map((feedback) => feedback)}
                        addElementToRequestsList={(x: any) => console.log(x)}
                        requestsList={requestsList}
                        setRequestsList={setRequestsList}
                        onRequestUpdated={reloadIdea}
                        customizations={editCustomizations}
                      />
                    </Group>
                    <Box>
                      <InsightsTable
                        idea={idea}
                        validatedRequests={validatedRequests}
                        handleModalOpen={handleModalOpen}
                        isAllowToEditDates={isAllowedToEditDates(
                          editCustomizations
                        )}
                      />
                    </Box>
                  </Tabs>
                </Paper>
              </Box>
            </Grid.Col>
            <Grid.Col
              span={3}
              pt="0"
              p="0"
              mah={`calc(100vh - ${height}px - 1000px)`}
            >
              <Box>
                <RightBar
                  ideaId={`${ideaId}`}
                  matches={matches}
                  setMatches={setMatches}
                  requests={requests}
                  onUpdated={reloadIdea}
                  handleModalOpen={handleModalOpen}
                  ideaLoading={ideaLoading}
                  customizations={editCustomizations}
                  height={height}
                  idea={idea}
                  hideMatch={hideaMatchUponaddingAsEvidence}
                  trigger={trigger}
                  timer={timer}
                  computed={computed}
                  matchesUpdate={getSuggestionsUpdate}
                  matchesLoading={matchesLoading}
                />
              </Box>
            </Grid.Col>
          </Grid>
        </Box>
      )}
    </>
  );
}
