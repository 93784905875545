import {
  Box,
  Button,
  Flex,
  Loader,
  Menu,
  Modal,
  Text,
  useMantineTheme,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { SourceIcon } from "../../../utils/SourceIcon";
import { useAuth0 } from "@auth0/auth0-react";
import SyncContext from "../../../context/SyncContext";
import {deleteProductArea} from "../../../api/product-areas";

interface ProductAreaModalProps {
  openProductAreaModal: any;
  setOpenProductAreaModal: any;
  currentOption: any;
  currentId: string;
  // formValues: any;
  setRemovedRow: any;
  elements: any;
  loadPage: boolean;
  setLoadPage: React.Dispatch<React.SetStateAction<boolean>>;
  
}

interface FilteredProductAreas {
  name: string;
  value: string;
  ownerName: string;
  _id: string;
}

function ReassignProductAreaModal({
  openProductAreaModal,
  setOpenProductAreaModal,
  currentOption,
  currentId,
  // formValues,
  setRemovedRow,
  elements,
  loadPage,
  setLoadPage, 
}: ProductAreaModalProps) {
  const auth0 = useAuth0();
  const theme = useMantineTheme();
  const { allComponents } = useContext(SyncContext)
  const [formLoader, setFormLoader] = useState(false);
  
  const [newId, setNewId] = useState("");
  const [filteredProductAreas, setFilteredProductAreas] = useState<
    FilteredProductAreas[]
  >([]);
  const [productArea, setProductArea] = useState<any>()

  const cancelAndClose = () => {
    setOpenProductAreaModal(false);
    setNewId("");
  };

  const removeProductArea = (productAreaToDelete: string, productAreaForReassignment: string)=>{
    productArea && deleteProductArea(productAreaToDelete, productAreaForReassignment, auth0).then((res)=>{
      console.log(res)
      setOpenProductAreaModal(false)
      setLoadPage(!loadPage)
      setProductArea(null)
    }).catch((e) => console.log(e))
  }
  useEffect(() => {
    if (Array.isArray(allComponents) && currentOption?._id) {
      const filteredProductAreas = allComponents.filter(
        (item: any) => item._id !== currentOption._id
      );
      setFilteredProductAreas(filteredProductAreas);
    } else {
      setFilteredProductAreas([]); 
    }
  }, [allComponents, currentOption]);
  console.log({currentOption, filteredProductAreas, productArea})
  return (
    <Modal
      opened={openProductAreaModal}
      onClose={() => setOpenProductAreaModal(false)}
      overlayProps={{opacity: 0.55, blur: 3, color: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2]}}
    >
      {currentOption?.name && (
        <Box>
          <Text fw={700}>
            To remove Product Area{" "}
            <Text fs="italic" span>
              {currentOption.name}
            </Text>
            , you need to reassign the ideas which are linked to it.{" "}
            <Text color="red">This is irreversible.</Text>
          </Text>
          <Text>
            Please click "Reassign" to reassign all ideas under{" "}
            <Text fs="italic" span>
              {currentOption.name}
            </Text>
          </Text>

          {newId.length > 0 && (
            <Text my={"md"}>
              Please click "Reassign" to reassign all ideas under{" "}
              <Text fs="italic" span>
                {currentOption?.name}
              </Text>{" "}
              to{" "}
              <Text fs="italic" span>
                {newId}
              </Text>
            </Text>
          )}

          <Menu shadow="md" width={200} position="top" withArrow>
            <Menu.Target>
              <Button
                rightIcon={<SourceIcon sourceName="dropdown" />}
                variant="subtle"
                color="dark"
                px={"md"}
              >
                {productArea?._id ? productArea.name :"Reassign"}
              </Button>
            </Menu.Target>

            <Menu.Dropdown sx={{ maxHeight: '130px', overflowY: 'auto', marginTop:'10px'}}>
              <Menu.Label>Choose from the list below</Menu.Label>

              {filteredProductAreas &&
                filteredProductAreas.length > 0 &&
                filteredProductAreas.map((item: any) => (
                  <>
                    <Menu.Item
                      w="180px"
                      key={item.name}
                      pl={item.parentId ? 'md' : 'sm'}
                      sx={{
                        backgroundColor:item._id == productArea?._id ? "#f1f3f5" : "none"
                      }}
                      onClick={() => {
                        console.log({item});
                        setProductArea(item); 
                        
                        /* setCloseOnBlur(true); */
                      }}
                    >
                      {item.parentId? "- " + item.name : item.name}
                    </Menu.Item>
                  </>
                ))}
            </Menu.Dropdown>
          </Menu>
          <Flex
            mt={"md"}
            gap="sm"
            justify="flex-end"
            align="center"
            direction="row"
          >
            <Button
              bg={"#5C5CEA"}
              sx={{
                fontWeight: 700,
                ":hover": { backgroundColor: "#9f9af8" },
              }}
              color={"#fff"}
              onClick={() => { cancelAndClose();
                setProductArea(null)
              }
              }
            >
              Cancel
            </Button>
            <Button
              bg={"#5C5CEA"}
              sx={{
                fontWeight: 700,
                ":hover": { backgroundColor: "#9f9af8" },
                "&[disabled]": {
                  pointerEvents: "all",
                  backgroundColor: "#fff",
                  cursor: "not-allowed",
                  ":hover": {
                    backgroundColor: "#fff",
                  },
                },
              }}
              color={"#fff"}
              onClick={()=>{
                removeProductArea(currentOption._id, productArea._id)}
              }
              disabled={!productArea?._id}
            >
              {formLoader === false ? (
                "Save"
              ) : (
                <Loader color="violet" variant="dots" />
              )}
            </Button>
          </Flex>
        </Box>
      )}
    </Modal>
  );
}

export default ReassignProductAreaModal;
