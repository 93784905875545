import { useEffect, useState } from "react";
import { ScrollArea } from "@mantine/core";
import { useAuth0 } from "@auth0/auth0-react";
import { listTokens, createAPIToken, deleteToken } from "../../Api";
import {
  Title,
  Box,
  Text,
  Paper,
  Table,
  Flex,
  Menu,
  ActionIcon,
  TextInput,
  Button
} from "@mantine/core";
import {
  IconCopy
} from "@tabler/icons";
import { openConfirmModal } from "@mantine/modals";
import {
  IconTrash,
} from "@tabler/icons";
import { getConnectedUserRole } from "../../utils";
import { showNotification } from "@mantine/notifications";

const Tokens = () => {
  const auth0 = useAuth0();
  const [tokens, setTokens] = useState<any[]>([]);
  const [createdToken, setCreatedToken] = useState<string | null>(null);
  const [tokenName, setTokenName] = useState<string>("");

  const loadTokens = () => {
    listTokens(auth0 ,'api')
      .then((tokens) => {
        setTokens(tokens || []);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    loadTokens();
  }, []);

  const openDeletePopup = (tokenId: string) => {
    openConfirmModal({
      title: "Delete Token",
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete this token?</Text>
      ),
      labels: { confirm: "Delete token", cancel: "No, don't delete it" },
      confirmProps: { color: "red" },
      closeOnCancel: true,
      closeOnConfirm: true,
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        deleteToken(auth0, tokenId)
          .then(() => {
            loadTokens();
            showNotification({
              title: "Token Deleted",
              message: "Token deleted successfully",
              autoClose: 1200
            });
          })
          .catch((error) => console.log(error));
      },
    });
  };

  const onCreateToken = () => {
    if (!tokenName) return;

    createAPIToken(auth0, tokenName)
      .then((response) => {
        const token = response.token;
        setCreatedToken(token); // Save the created token
        setTokenName(""); // Clear the input field
        loadTokens(); // Reload tokens list
        showNotification({
          title: "Token Created",
          message: "Token created successfully",
          autoClose: 1200,
        });
      })
      .catch((e) => console.log(e));
  };

  const copyToClipboard = () => {
    if (createdToken) {
      navigator.clipboard.writeText(createdToken);
      showNotification({
        title: "Copied to Clipboard",
        message: "Token copied successfully!",
        autoClose: 1500,
        color: "green",
      });
      setTokenName(""); // Clear the input field
      setCreatedToken(null);
    }
  };

  const role = getConnectedUserRole(auth0);
  const canEdit = role === "admin";
  const tokenRows = tokens.map((token: any) => {
    return (
      <tr key={token._id}>
        <td>
          <Flex gap="md" justify="space-between" align={"center"}>
            <Flex align={"center"}>
              <Box ml="md" miw={150} mih={44}>
                <Text weight={"bold"} color={"#111928"}>
                  {token.name}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </td>
        <td>
          <Flex gap="md" justify="space-between" align={"center"}>
            <Flex align={"center"}>
              <Box ml="md" miw={150} mih={44}>
                <Text weight={"bold"} color={"#111928"}>
                  {/* {'•'.repeat(token.token.length)} */}
                  {'••••••••••••••••'}
                </Text>
              </Box>
            </Flex>
          </Flex>
          
        </td>
        <td>
          <Flex gap="md" justify="space-between" align={"center"}>
            <Flex align={"center"}>
              <Box ml="md" miw={150} mih={44}>
                <Text weight={"bold"} color={"#111928"}>
                  {token.username}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </td>
        <td>
          <Flex gap="md" justify="space-between" align={"center"}>
            <Flex align={"center"}>
              <Box ml="md" miw={150} mih={44}>
                <Text weight={"bold"} color={"#111928"}>
                  {token.createdAt}
                </Text>
              </Box>
            </Flex>
            <Flex direction="row" align={"center"}>
              <Menu trigger="hover" openDelay={150} closeDelay={50}>
                <Menu.Dropdown>
                  <ScrollArea.Autosize
                    maxHeight={250}
                    style={{ overflowX: "unset" }}
                    w={180}
                  >
                  </ScrollArea.Autosize>
                </Menu.Dropdown>
              </Menu>
              <ActionIcon
                sx={{
                  backgroundColor: "transparent",
                  ":hover": {
                    backgroundColor: "transparent",
                  },
                }}
                mx={"lg"}
                onClick={() => openDeletePopup(token._id)}
              >
                <IconTrash color={"red"} size={16} />
              </ActionIcon>
            </Flex>
          </Flex>
        </td>
      </tr>
    );
  });

  return (
    <Box pt={"md"}>
      <Title order={3} p={"sm"}>
          Tokens
      </Title>
      {canEdit && (
        <Box mb="sm" p="md" bg="#f9f9f9" pt="sm">
          <Flex align="center" gap="sm">
            <TextInput
              mt={30}
              ml={30}
              mb={15}
              size="sm"
              // radius="md"
              onChange={(event) => setTokenName(event.currentTarget.value)}
              label="Token Name"
              placeholder="Ex. Sales"
              sx={{
                input: {
                  backgroundColor: "#F9FAFB",
                  ":focus": {
                    borderColor: "#b1b1b1;",
                  },
                },
              }}
            />
            <Button
              onClick={onCreateToken}
              bg="#5C5CEA"
              color="white"
              disabled={!tokenName}
              sx={{
                marginTop: "38px",
              }}
            >
              Create Token
            </Button>
          </Flex>

          {createdToken && (
            <Box mt="md">
              <Flex align="center" justify="space-between">
                <Flex align="center" gap="sm">
                  <Text size="sm" color="green">
                    Token Created: {createdToken}
                  </Text>
                  <ActionIcon onClick={copyToClipboard} title="Copy Token">
                    <IconCopy size={18} color="grey" />
                  </ActionIcon>
                </Flex>
              </Flex>
              <Text size="sm" color="red" mt="xs">
                Make sure to copy the token now as you will not be able to see this again!
              </Text>
            </Box>
          )}
        </Box>
      )}
      <Box p={"xl"} mt={"xs"} bg={"#F1F3F5"}>
        <Paper
          p="md"
          radius="md"
          mt={"lg"}
          withBorder
          sx={{ padding: "0 !important" }}
        >
          <Table
            sx={{
              "thead tr:first-of-type": {
                borderColor: "#dee2e6",
                background: "#F3F4F6",
              },
              "tbody tr:last-of-type": {
                borderBottom: " 1px solid #dee2e6",
              },
              td: {
                textAlign: "center",
              },
              "tbody tr td": {
                padding: "13px 95px",
                borderBottom: "1px solid #dee2e6",
                fontSize: "14px",
              },
              "thead: tr th": {
                padding: "13px 10px",
                textAlign: "center",
                borderBottom: "1px solid #dee2e6",
                fontSize: "14px",
              },
              "tbody tr:hover .flag:after": {
                borderRight: " 25px solid #f5f5f5",
              },
            }}
          >
            <thead>
              <tr>
                <th style= {{textAlign: "center"}}>Token Name</th>
                <th style= {{textAlign: "center"}}>Token</th>
                <th style= {{textAlign: "center"}}>Created By</th>
                <th style= {{textAlign: "center"}}>Created At</th>
              </tr>
            </thead>
            <tbody>{tokenRows}</tbody>
          </Table>
        </Paper>
      </Box>
    </Box>
  );
};

export default Tokens;
