import { createStyles, Button, Menu, Group, ActionIcon, Flex, Text } from "@mantine/core";
import { IconCheck, IconChevronDown } from "@tabler/icons";

const useStyles = createStyles((theme) => ({
  button: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: "#5B60E3",
    ":hover": {
      backgroundColor: "#474bb0",
    },
    height: 32,
  },

  menuControl: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    border: 0,
    borderLeft: "1px solid #474bb0",
    backgroundColor: "#5B60E3",
    ":hover": {
      backgroundColor: "#474bb0",
    },
    height: 30,
    minHeight: 31.5,
  },
}));

interface SplitButtonProps {
  isSplit: boolean;
  onClick: () => void;
  disabled?: boolean | undefined;
  lable: string;
  items: any[];
}

export function SplitButton({
  isSplit,
  onClick,
  disabled,
  lable,
  items,
}: SplitButtonProps) {
  const { classes, theme } = useStyles();

  return (
    <Group noWrap spacing={0} mr={5.8}>
      <Button
        variant="default"
        color="gray"
        styles={(theme) => ({
          root: {
            width:'140px',
            height: "31.8px",
            backgroundColor: "#5C5CEB",
            border: 0,
            fontWeight: 400,
            padding: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative", 
            "&:hover": {
              backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
            },
          },

          loader: {
            position: "absolute", 
            color: theme.white,
          },

          /* leftIcon: {
            marginRight: 15,
          }, */
        })}
        /* w={132} */

        /* className={classes.button} */ 
        disabled={disabled}
        sx={
          !isSplit
            ? {fontSize: 13,
              fontWeight:500,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }
            : {fontSize: 13, fontWeight:500, marginRight: -5}
        }
        onClick={onClick}
        leftIcon={
          <IconCheck 
            color="white"
            size={14} 
            strokeWidth={1.74} 
            style={{ color: 'rgba(10, 10, 10, 1)', 
              transform: 'scale(0.95, 1)' 
            }}/>}
        
      >
        <Text color="white" fz={12}>
          {lable}
        </Text> 
      </Button>
      {isSplit && (
        <Menu
          position="bottom-end"
          withinPortal
          shadow="sm"
          radius="md"
          withArrow
        >
          <Menu.Target>
            <ActionIcon
              variant="filled"
              color={theme.primaryColor}
              size={36}
              className={classes.menuControl}
            >
              <IconChevronDown size="1rem" stroke={1.5} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            {items.map((item) => (
              <Menu.Item onClick={item.onClick} key={item.id}>
                {item.name}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      )}
    </Group>
  );
}
