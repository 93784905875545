import { Flex, Stack, Tooltip, Text } from "@mantine/core"
import { SourceIcon } from "../../../../../../utils/SourceIcon"


const TicketTitle = ( {item, cxItem, origin} : {item: any, cxItem: any, origin: string} ) => {
  return (
    <Flex align="center" h={70} mx="sm">
      {origin && (
        <Tooltip label={origin || "N/A"}>
          <Stack h={20} w={20} ml={10} justify="center" align="center">
            <SourceIcon sourceName={origin?.toLowerCase() || "N/A"} />
          </Stack>
        </Tooltip>
      )}
      <Flex pl={12} align="center" w={600}>
        <Tooltip label={cxItem?.title || item?.textItem?.title || "N/A"}>
          <Text ml="3px" fz={17} maw={600} w={600} fw={600} lineClamp={1}>
            { item?.textItem?.title || cxItem?.title || "N/A"}
          </Text>
        </Tooltip>
      </Flex>
    </Flex>
  )
}

export default TicketTitle