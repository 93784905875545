import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mantine/core";
import { saveIdea } from "../../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import SyncContext from "../../../../context/SyncContext";
import { getNestedFieldValue } from "../../../ideaNiv/helpers/calculateImpactResults";
import TagMenu from "../../../Tags-old/TagMenu";

export const TagsCell: React.FC<any> = ({ data, col, renderParams }) => {
  const { tagsOptions } = useContext(SyncContext);

  // console.log({data})

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [initialTags, setInitialTags] = useState<string[]>([]);

  const auth0 = useAuth0();

  useEffect(() => {
    const value = getNestedFieldValue(data, col.accessor) || [];
    setSelectedTags(value);
    setInitialTags(value);
  }, [data, col.accessor]);

  const handleTagToggle = async (tagId: string) => {
    let updatedTags;
    if (selectedTags.includes(tagId)) {
      updatedTags = selectedTags.filter((id) => id !== tagId);
    } else {
      updatedTags = [...selectedTags, tagId];
    }

    setSelectedTags(updatedTags);

    if (JSON.stringify(updatedTags) !== JSON.stringify(initialTags)) {
      try {
        await saveIdea({ id: data._id, [col.accessor]: updatedTags }, auth0);
        setInitialTags(updatedTags);
      } catch (error) {
        console.error("Error updating idea:", error);
      }
    }
  };

  return (
    <Box p={0} m={0} h="100%" w="100%" onMouseMove={(e) => e.stopPropagation()}>
      <TagMenu
        selectedTags={selectedTags}
        onTagToggle={handleTagToggle}
        tagsOptions={tagsOptions}
        colTitle={col?.title || "Tag"}
      />
    </Box>
  );
};
