import { useContext, useState } from "react";
import IdeaContext from "../IdeaContext";
import { Box, Button, Flex, ScrollArea, Skeleton, Text } from "@mantine/core";
import EvidenceCardNiv from "./EvidenceCardNiv";
import EvidencesHeader from "./EvidencesHeader";
import RequestCardNiv from "./RequestCardNiv";
import { IconCaretDown, IconCaretUp } from "@tabler/icons";


const FilteredSortedEvidence = ({
  displayedRequests,
  chooseMatch,
  chosenEvidence,
}: {
  displayedRequests: any[];
  chooseMatch: any;
  chosenEvidence: any
}) => {

  return (
   
    <Box pt="16px" px="20px" w="100%">
      {displayedRequests?.length > 0 &&
          displayedRequests?.map((evidence: any, index: number) => (
            <EvidenceCardNiv
              key={evidence._id}
              item={evidence}
              chooseMatch={chooseMatch}
              index={index}
              fromSearch={false}
              chosenEvidence={chosenEvidence}
              ideaPage
            />
          )) 
      }
    </Box>
   
  );
};

export default FilteredSortedEvidence