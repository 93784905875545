import { useAuth0 } from "@auth0/auth0-react";
import React, {useContext, useState} from "react";
import {
  SFIntegrationUpdate,
  updateSalesforceIntegration,
} from "../../../../api/integrations";
import {Box, Button, Grid, Paper, Select, Table, Text } from "@mantine/core";
import SalesforceIcon from "../../../../utils/icons/SalesforceIcon";
import { useForm } from "@mantine/form";
import SyncContext from "../../../../context/SyncContext";

export const SetupOpportunities = (props: {
  integration: any;
  variant?: "wizard" | "settings";
  reloadIntegration: () => void;
}) => {
  const auth0 = useAuth0();
  const { objectCustomizations } = useContext(SyncContext);
  const opCustomizations = objectCustomizations.opportunity
  const form = useForm<{
    amount: string,
    renewalDate?: string
  }>({
    initialValues: {
      amount: opCustomizations?.fields?.amount?.name || null,
      renewalDate: opCustomizations?.fields?.renewalDate?.name || null
    },

    validate: {
      amount: (value) =>
        value !== null
          ? null
          : "please select the Amount field name to use"
    },
  });
  const [loading, setLoading] = useState<boolean>(false);

  const isWizard = !props.variant || props.variant === "wizard";
  const saveOpportunityClicked = () => {
    if (form.isValid()) {
      setLoading(true);
      const update: SFIntegrationUpdate = {
        opFields: form.values,
      };
      if (isWizard) {
        update.setupStep = 3;
      }
      updateSalesforceIntegration(props.integration._id, update, auth0)
        .then(() => {
          props.reloadIntegration();
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
  };

  const fieldNamesSection = (
    <form onSubmit={form.onSubmit(saveOpportunityClicked)}>
      <Box>
        <Text mt="20px" color={"rgba(22, 24, 50, 0.8)"}>
          Select the amount field name to use
        </Text>
        <Grid mt={"15px"}>
          <Grid.Col span={"content"} pl={"25px"}>
            <SalesforceIcon width={"30"} height={"auto"}/>
          </Grid.Col>
          <Grid.Col span={"content"}>
            <Select
              ml={"md"}
              placeholder="Select Amount field"
              searchable
              data={props.integration.info.opportunities.fieldNames}
              {...form.getInputProps("amount")}
            />
          </Grid.Col>
        </Grid>
      </Box>
      <Box>
        <Text mt="20px" color={"rgba(22, 24, 50, 0.8)"}>
          Select the Renewal Date field name to use
        </Text>
        <Grid mt={"15px"}>
          <Grid.Col span={"content"} pl={"25px"}>
            <SalesforceIcon width={"30"} height={"auto"}/>
          </Grid.Col>
          <Grid.Col span={"content"}>
            <Select
              ml={"md"}
              placeholder="Select Renewal Date"
              searchable
              allowDeselect
              data={props.integration.info.opportunities.fieldNames}
              {...form.getInputProps("renewalDate")}
            />
          </Grid.Col>
        </Grid>
      </Box>
      <Button
        mt={"md"}
        ml={isWizard ? "0" : "lg"}
        loading={loading}
        type="submit"
      >
        Save
      </Button>
    </form>
  )


  return (
    <>
      {isWizard ? (
        fieldNamesSection
      ) : (
        <Paper mt={"44px"} shadow={"lg"} withBorder>
          <Grid p={0} m={0}>
            <Grid.Col span={2} p={"xl"}>
              <Text weight={700} color={"#5C5CEA"} size={"md"}>
                Mapping Rules
              </Text>
              <Text size={"sm"} mt={"sm"} mb={"sm"}>
                Configure field mapping rules
              </Text>
            </Grid.Col>
            <Grid.Col
              span={"content"}
              p={"xl"}
              sx={{borderLeft: "1px solid rgb(222, 226, 230)"}}
            >
              {fieldNamesSection}
            </Grid.Col>
          </Grid>
        </Paper>
      )}
    </>
  )
}