import { Menu, Button, Text, Stack, Flex, ActionIcon, Box } from '@mantine/core';
import { IconSettings, IconSearch, IconPhoto, IconMessageCircle, IconTrash, IconArrowsLeftRight, IconDots } from '@tabler/icons';
import { useContext, useState } from 'react';
import IdeaContext from '../IdeaContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { closeAllModals, openConfirmModal, openModal } from '@mantine/modals';
import { deleteIdea } from '../../../Api';

export function DeleteIdea() {

  const { idea, requestsCount } = useContext(IdeaContext);
  const auth0 = useAuth0();
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  
  const openDeleteRequest = () =>{
    if(requestsCount > 0){
      openModal({
        modalId: "decline",
        title: <Text fz={20} fw={600}>Delete idea</Text>,
        centered: true,
        size: 600,
        children: (
          <Stack>
            <Flex>There are Requests assigned to this Idea. Please re-assign them before deleting the Idea</Flex>
            <Flex justify="center" mt="sm">
              <Button
                onClick={() => {
                  closeAllModals();
                }}
                w={100}
                styles={(theme) => ({
                  root: {
                    backgroundColor: "#5B60E3",
                    border: 0,
                    height: 35,
                    "&:hover": {
                      backgroundColor: theme.fn.darken("#5B60E3", 0.05),
                    },
                  },
                })}
              >
                Ok
              </Button>
            </Flex>
          </Stack>
        ),
      });
    }
    else{
      openConfirmModal({
        title: <Text fz={20} fw={600}>Delete idea</Text>,
        centered: true,
        children: (
          <Text size="sm">Are you sure you want to delete this idea?</Text>
        ),
        labels: { confirm: "Delete idea", cancel: "No don't delete it" },
        confirmProps: { color: "red" },
        onCancel: () => console.log("Cancel"),
        onConfirm: () => {
          deleteIdea(idea?._id, auth0)
            .then((response) => {
              navigate("/ideas");
            })
            .catch((e: any) => console.log(e));
        },
      });
    }

  }
   

  const hasOrigin: boolean = idea?.origin === 'jira' || idea?.origin === 'clickup'

  return (
    <Menu /* shadow="md" */ width={200} opened={opened} onChange={setOpened} /* position='le' */>
      <Menu.Target>
        {/* <Stack align='center'  bg='rgba(92, 92, 235, 0.1)' h={24}> */}
          
        <Button styles={(theme) => ({
          root: {
            height: "24px",
            width: "24px",
            backgroundColor: opened ?'rgba(92, 92, 235, 0.4)' : 'rgba(92, 92, 235, 0.1)',
            border: 0,
            fontWeight: 400,
            padding: 0,
            borderRadius:"4px",
            "&:hover": {
              backgroundColor: opened ?'rgba(92, 92, 235, 0.4)' : 'rgba(92, 92, 235, 0.1)',
            },
          },

          leftIcon: {
            marginRight: 15,
          },
        })}>
          <IconDots size={18} color='black'/>
        </Button>
          
          
          
        {/* </Stack> */}
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          disabled={hasOrigin}
          onClick={openDeleteRequest}
          color={hasOrigin ? "#808080" : "red"}
          icon={<IconTrash size={14} />}
        >
              Delete idea
        </Menu.Item> 
        
      </Menu.Dropdown>
    </Menu>
  );
}