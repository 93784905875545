import { Flex, Group, TextInput } from '@mantine/core';
import { useContext } from 'react';
import FiltersContext from '../../../ideas/filters/context/FiltersContext';
import FilterBarDiscovery from './FilterBarDiscovery';
import { IconSearch } from '@tabler/icons';
import DiscoveryContext from '../../DiscoveryContext';
import posthog from 'posthog-js';

const EvidencesHeaderDiscovery: React.FC = () => {

  const {
    activeFilters,
    setActiveFilters
  } = useContext(FiltersContext);

  const {
    searchEvidence, query, idea
  } = useContext(DiscoveryContext);

  return (
    <Group /* mt={24} */ /* mb={12} */ position='left' px={36}>
      <TextInput
        styles={{
          wrapper:{
            border: "1px solid #D8D8DB",
            borderRadius: "7px",
            backgroundColor: "#fff"
          },
          input:{
            height:32
          }
        }}
        mr={8}
        variant="unstyled"
        placeholder="Search evidence..."
        icon={<IconSearch size={14} />}
        value={query}
        onChange={(event) => {
          searchEvidence(event.currentTarget.value);
          posthog.capture('search_evidence_in_discovery', {
            ideaId: idea._id,
            query: event.currentTarget.value
          })
        }}
      />
      <Group spacing={8}>
        <FilterBarDiscovery 
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
        />
      </Group>

      <Flex>
        {/* <Sort /> */}
      </Flex>

    </Group>
  );
};

export default EvidencesHeaderDiscovery