import moment from "moment";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Tabs,
  Text,
  ActionIcon,
  Button,
  Group,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import { ExplainCard } from "./ExplainCard";
import { ZendeskSetup } from "./ZendeskSetup";
import { useAuth0 } from "@auth0/auth0-react";
import { getConnectedUserRole } from "../../../../utils";
import { getToken, createToken } from "../../../../api/integrations";
import { IconEye, IconEyeOff, IconKey } from "@tabler/icons";

const ZendeskIntegration = (props: {
  integration: any;
  reloadIntegration: () => void;
  customizations: any;
}) => {
  const auth0 = useAuth0();
  const role = getConnectedUserRole(auth0);
  const canEdit = role === "admin";

  // State to store the token and toggle visibility
  const [tokenDoc, setTokenDoc] = useState<any>(null);
  const [tokenVisible, setTokenVisible] = useState<boolean>(false);

  // Fetch the token if canEdit is true
  useEffect(() => {
    if (canEdit) {
      getToken(auth0, "zendesk")
        .then((fetchedTokenDoc) => setTokenDoc(fetchedTokenDoc))
        .catch((error) => {
          console.error("Failed to fetch token:", error);
        });
    }
  }, [canEdit, auth0]);

  const handleCreateToken = async () => {
    try {
      const newTokenDoc = await createToken(
        auth0,
        "zendesk",
        props.integration.accountId
      );
      setTokenDoc(newTokenDoc);
    } catch (error) {
      console.error("Failed to create token:", error);
    }
  };

  const tabs = [{ name: "General" }, { name: canEdit ? "Tokens" : null }];

  const [activeTab, setActiveTab] = useState<string | null>("General");
  const integration = props.integration;
  const inSetupMode = !integration || integration.setupStep !== -1;

  if (inSetupMode) {
    return (
      <ZendeskSetup
        integration={integration}
        reloadIntegration={props.reloadIntegration}
        customizations={props.customizations}
      />
    );
  }

  const createdAt = new Date(integration.createdAt);
  const createdAtString = moment(createdAt).format("MMMM Do, YYYY");

  return (
    <>
      <Grid
        mx="sm"
        justify="space-between"
        align="flex-end"
        sx={{ borderBottom: "1px solid #F1F1F1" }}
      >
        <Grid.Col span="content" pb="0">
          <Tabs value={activeTab} onTabChange={setActiveTab}>
            <Tabs.List m="0" sx={{ borderBottom: 0 }}>
              {tabs
                .filter((t) => t.name)
                .map((tab) => (
                  <Tabs.Tab key={tab.name} value={`${tab.name}`}>
                    {tab.name}
                  </Tabs.Tab>
                ))}
            </Tabs.List>
          </Tabs>
        </Grid.Col>
      </Grid>

      <Box p={"xl"} mt={7} bg={"#F1F3F5"}>
        <ExplainCard />

        {activeTab === "General" && (
          <>
            <Paper mt={"44px"} shadow={"lg"} withBorder>
              <Grid p={0} m={0}>
                <Grid.Col span={2} p={"xl"}>
                  <Text weight={700} color={"#5C5CEA"} size={"md"}>
                    General
                  </Text>
                  <Text size={"sm"} mt={"sm"} mb={"sm"}>
                    Integration details
                  </Text>
                </Grid.Col>
                <Divider size="xs" orientation="vertical" />
                <Grid.Col span={"auto"} p={"xl"}>
                  <Grid>
                    <Grid.Col span={2}>
                      <Text mt={"xs"} color={"#161832"} weight={"500"}>
                        Domain:
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Text color={"rgba(22, 24, 50, 0.8)"} mt={"xs"}>
                        {integration.subdomain}
                      </Text>
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col span={2}>
                      <Text mt={"xs"} color={"#161832"} weight={"500"}>
                        Created At:
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Text color={"rgba(22, 24, 50, 0.8)"} mt={"xs"}>
                        {createdAtString}
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
              </Grid>
            </Paper>
          </>
        )}

        {activeTab === "Tokens" && (
          <>
            <Paper mt={"44px"} shadow={"lg"} withBorder>
              <Grid p={0} m={0}>
                <Grid.Col span={2} p={"xl"}>
                  <Text weight={700} color={"#5C5CEA"} size={"md"}>
                    Zendesk app
                  </Text>
                </Grid.Col>
                <Divider size="xs" orientation="vertical" />
                <Grid.Col span={"auto"} p={"xl"}>
                  {tokenDoc ? (
                    <>
                      <Grid>
                        <Grid.Col span={2}>
                          <Text mt={"xs"} color={"#161832"} weight={"500"}>
                            Token:
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={6}>
                          <Group noWrap position="left">
                            <ActionIcon
                              mt="xs"
                              onClick={() => setTokenVisible(!tokenVisible)}
                            >
                              {tokenVisible ? (
                                <IconEyeOff size={16} />
                              ) : (
                                <IconEye size={16} />
                              )}
                            </ActionIcon>
                            <Text sx={{
                              backgroundColor: "#fcfcfc",
                              padding:'0px 5px',
                              borderRadius: 4,
                              border: "1px solid #dee2e6"
                            }} color={"rgba(22, 24, 50, 0.8)"} mt={"xs"}>
                              {tokenVisible ? tokenDoc.token : "••••••••••"}
                            </Text>
                          </Group>
                        </Grid.Col>
                      </Grid>
                      <Grid>
                        <Grid.Col span={2}>
                          <Text mt={"xs"} color={"#161832"} weight={"500"}>
                            Created At:
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={6}>
                          <Text color={"rgba(22, 24, 50, 0.8)"} mt={"xs"}>
                            {moment(tokenDoc.createdAt).format("MMMM Do, YYYY")}
                          </Text>
                        </Grid.Col>
                      </Grid>
                    </>
                  ) : (
                    <Button
                      onClick={handleCreateToken}
                      mt="md"
                      color="indigo"
                      leftIcon={<IconKey size={16} />}
                    >
                      Create Token
                    </Button>
                  )}
                </Grid.Col>
              </Grid>
            </Paper>
          </>
        )}
      </Box>
    </>
  );
};

export default ZendeskIntegration;
