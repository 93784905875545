import { Box, Grid, ScrollArea, Text } from "@mantine/core";
import DiscoveryTextArea from "./DiscoveryTextArea";
import DiscoveryTabs from "./DiscoveryTabs";
import { useState } from "react";
import NewIdeaForm from "./NewIdeaForm";
import CustomDrawer from "./evidenceCard/CustomDrawer";

const Discovery = () => {
  const [newIdeaFormView, setNewIdeaFormView] = useState<boolean>(false);

  return (
    <ScrollArea h='calc(100vh - 63px)' mah={'calc(100vh - 63px)'} >
      <CustomDrawer />
      <Box h={52} px={16} py={12} sx={{ borderBottom: "1px solid #D8D8DB" }}>
        <Text color="#212529" fz={20} fw={600}>
          Idea Validation
        </Text>
      </Box>
      <Grid gutter={0} columns={120}>
        <Grid.Col span={38}>
          {newIdeaFormView ? (
            <NewIdeaForm setNewIdeaFormView={setNewIdeaFormView} />
          ) : (
            <DiscoveryTextArea setNewIdeaFormView={setNewIdeaFormView} />
          )}
        </Grid.Col>
        <Grid.Col span={82} bg='#F8F9FA'>
          <DiscoveryTabs />
        </Grid.Col>
      </Grid>
    </ScrollArea>
  );
};

export default Discovery;
