// src/components/settings/PropertyManager/index.tsx

import React, { useEffect } from "react";
import {
  Box,
  Group,
  Title,
  Button,
  Paper,
  Grid,
  Skeleton,
  ActionIcon,
  Text,
  Tooltip,
} from "@mantine/core";
import { PropertyGroupEditor } from "./PropertyGroupEditor";
import {
  CustomizationProvider,
  useCustomization,
} from "./_utils/CustomizationContext";
import { IconPlus, IconRun, IconSettings, IconX } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";
import { openConfirmModal } from "@mantine/modals";

const PropertyManager: React.FC = () => {
  const { groups, addGroup, runAggregations, loading, error } =
    useCustomization();

  const handleAddGroup = () => {
    // Create a new group with default naming
    addGroup(
      `defaultObject-${Object.keys(groups).length}`,
      `defaultAttribute-${Object.keys(groups).length}`
    );
  };

  console.log({ groups });

  const openConfirmation = () =>
    openConfirmModal({
      title: "Confirm Aggregations run",
      children: (
        <Text size="sm">
          Are you sure you want to run aggregations for all ideas?
        </Text>
      ),
      labels: { confirm: "Yes, run aggregations", cancel: "Cancel" },
      confirmProps: { color: "indigo" },
      onConfirm: runAggregations,
      onCancel: () => console.log("Aggregation cancelled by user."),
      centered: true
    });

  useEffect(() => {
    if (error) {
      showNotification({
        title: "Error",
        message: error,
        color: "red",
        icon: <IconX size={18} />,
        autoClose: 3000,
      });
    }
  }, [error]);

  return (
    <Box m="xl">
      <Group position="apart">
        <Title order={2} mb="lg">
          Property Customization
        </Title>
        <Group spacing={4} noWrap>
          <Tooltip label="Run aggregations">
            <ActionIcon
              onClick={openConfirmation}
              loading={loading.runAggregations}
              color="indigo"
              h={36}
              w={36}
            >
              <IconRun size={22} />
            </ActionIcon>
          </Tooltip>
          <Button
            color="indigo"
            onClick={handleAddGroup}
            rightIcon={<IconPlus size={14} />}
          >
            Add Property Group
          </Button>
        </Group>
      </Group>

      <Grid>
        {Object.entries(groups).map(([groupId, group]) => (
          <Grid.Col span={6} md={6} lg={4} key={groupId}>
            <Paper shadow="sm" p={0} withBorder mt="sm">
              <PropertyGroupEditor groupId={groupId} group={group} />
            </Paper>
          </Grid.Col>
        ))}
        {loading.fetch && Object.keys(groups).length === 0 && (
          <>
            {Array.from({ length: 4 }).map((_, index) => (
              <Grid.Col span={6} md={6} lg={4} key={index}>
                <Skeleton height={200} />
              </Grid.Col>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
};

const WrapperPropertyManager: React.FC = () => {
  return (
    <CustomizationProvider>
      <PropertyManager />
    </CustomizationProvider>
  );
};

export default WrapperPropertyManager;
