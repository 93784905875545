// import React, { useCallback, useContext, useEffect, useMemo } from "react";
// import {
//   Checkbox,
//   ScrollArea,
//   TextInput,
//   Text,
//   Highlight,
// } from "@mantine/core";
// import { IconListSearch } from "@tabler/icons";

// import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
// import { searchComponents, searchFilter } from "../../ideasHelpers";
// import FiltersContext from "../context/FiltersContext";
// import OperationSelect from "../OperationSelect";
// import AddFilterBtn from "../AddFilterBtn";
// import SyncContext from "../../../../context/SyncContext";
// import { Filter } from "../FiltersMenu";
// import {
//   getAccountTypesMapList,
//   getBusinessNameList,
//   prepareSortedList,
// } from "./CheckboxFilterHelpers";
// import FilterFooter from "../FilterFooter";

// interface CheckboxFilterProps {
//   initialfilters: Filter;
//   MenuType: "idea" | "evidence";
//   resetFilters: (
//     MenuType: "idea" | "evidence",
//     selectedParentFilter?: string | null
//   ) => void;
// }

// const CheckboxFilter: React.FC<CheckboxFilterProps> = ({
//   MenuType,
//   resetFilters,
// }) => {
//   const { components, allComponents, ideasFiltersConfig } = useContext(SyncContext);
//   const {
//     activeFilters,
//     setActiveFilters,
//     selectedParentFilter,
//     checkedComponents,
//     setCheckedComponents,
//     childFiltersValue,
//     childQuery,
//     setChildQuery,
//     query,
//     setQuery,
//     filteredComponents,
//     setFilteredComponents,
//     operator,
//     setOperator,
//     searchedOptions,
//     setSearchedOptions,
//     setOpenedMenuType,
//     setSelectedParentFilter,
//   } = useContext(FiltersContext);

//   const _selectedParentFilter: string[] = selectedParentFilter?.split(".") || [
//     "",
//   ];

//   console.log({_selectedParentFilter, MenuType})
//   const selectedFilter =
//     _selectedParentFilter && _selectedParentFilter.length === 2
//       ? //@ts-ignore
//       ideasFiltersConfig[MenuType][_selectedParentFilter[0]]?.childFilters[
//         _selectedParentFilter[1]
//       ]
//       : ideasFiltersConfig[MenuType][_selectedParentFilter[0]];

//   const selectedHasOperations = selectedFilter?.operations?.length > 0 || false;
//   const selectedOperators = selectedFilter?.operations;
//   const selectedOptions = selectedFilter?.options;

//   useEffect(() => {
//     const currentFilters: any = activeFilters.find(
//       (aF) => aF.parent === selectedParentFilter
//     );

//     if (currentFilters?.selected && selectedParentFilter && currentFilters) {
//       setCheckedComponents((prev) => ({
//         ...prev,
//         ...(currentFilters.selected
//           ? {
//             [selectedParentFilter]: currentFilters.selected.map((o: string) =>
//               o.toLowerCase()
//             ),
//           }
//           : {}),
//       }));
//     }
//   }, []);

//   useEffect(() => {
//     console.log({ checkedComponents });
//   }, [checkedComponents]);

//   console.log({ searchedOptions, selectedOptions, components });
//   const renderComponents = ({
//     components,
//     inner = false,
//     selectedParentFilter,
//   }: {
//     components: any[];
//     inner?: boolean;
//     selectedParentFilter: any;
//   }) => {
//     return (
//       components &&
//       components.length > 0 &&
//       components.map((component, index) => (
//         <React.Fragment
//           key={`${index}_${
//             inner ? component.childComponents?._id : component?._id
//           }`}
//         >
//           <Checkbox
//             key={inner ? component.childComponents?._id : component?._id}
//             sx={{
//               cursor: "pointer",
//               input: {
//                 ":checked": {
//                   backgroundColor: "#5B60E3",
//                   borderColor: "#5B60E3",
//                 },
//               },
//             }}
//             pr="sm"
//             pl={inner ? 35 : "sm"}
//             py={3}
//             value={
//               ["Product area"].includes(selectedParentFilter)
//                 ? component?._id
//                 : component?.value?.toLowerCase()
//             }
//             label={
//               <Text>
//                 <Highlight highlight={capitalizeFirstLetter(query)}>
//                   {capitalizeFirstLetter(component.name)}
//                 </Highlight>
//               </Text>
//             }
//           />

//           {/* If the component has children, render them with added padding */}
//           {component.childComponents &&
//             component.childComponents.length > 0 &&
//             renderComponents({
//               components: component.childComponents,
//               inner: true,
//               selectedParentFilter,
//             })}
//         </React.Fragment>
//       ))
//     );
//   };

//   const handleCheckedComponentsChange = (selectedArray: string[]) => {
//     if (
//       selectedParentFilter &&
//       ["Product area"].includes(selectedParentFilter)
//     ) {
//       // Finding the value which is the value of the checked product area is
//       //Doing it by finding the differnce between the last array (checkedComponents) to the current array (selectedArray)
//       const currentCheckedComponents =
//         checkedComponents[selectedParentFilter] || [];
//       const valueOnlyInCheckedComponenets: string[] =
//         currentCheckedComponents.filter(
//           (value) => !selectedArray.includes(value)
//         );
//       const valueOnlyInSelectedArray: string[] = selectedArray.filter(
//         (value) => !currentCheckedComponents.includes(value)
//       );
//       const selectedValue: string[] = valueOnlyInCheckedComponenets.concat(
//         valueOnlyInSelectedArray
//       ); // Always one value in this array

//       const selected = selectedValue[0]; // This is the checked product area
//       const arr: string[] = selectedArray;
//       const add: boolean = selectedArray.includes(selected); // Determines if we're clicking or unclicking product area
//       const addingOrSplicingSubComponents = (id: string) => {
//         const copy = [...components];
//         const filtered = copy.filter((c: any) => c._id === id);
//         filtered.forEach((c: any) => {
//           const childComponents: any[] = c.childComponents;
//           if (childComponents) {
//             if (add) {
//               childComponents.forEach((c: any) => {
//                 if (!arr.includes(c._id)) arr.push(c._id);
//               });
//             } else if (!add) {
//               childComponents.forEach((c: any) => {
//                 const index = arr.findIndex((a: any) => a === c._id);
//                 arr.splice(index, 1);
//               });
//             }
//           }
//         });
//       };
//       addingOrSplicingSubComponents(selected);
//       const namesArr: any[] = [];
//       allComponents.forEach((a: any) => {
//         const found = allComponents.find((r: any) => r === a._id);
//         namesArr.push(found?.name);
//       });
//       // setCheckedComponents(arr);
//       setCheckedComponents((prev) => ({
//         ...prev,
//         [selectedParentFilter]: arr,
//       }));
//     } else {
//       // setCheckedComponents(selectedArray)
//       console.log({ selectedArray, checkedComponents });
//       selectedParentFilter &&
//         setCheckedComponents((prev) => ({
//           ...prev,
//           [selectedParentFilter]: selectedArray,
//         }));
//     }
//   };

//   const componentsToRender = useMemo(() => {
//     switch (selectedParentFilter?.toLowerCase()) {
//     case "product area":
//       return query ? filteredComponents : components;
//     case "business name":
//       const businessNameList = getBusinessNameList(selectedOptions);
//       return query && searchedOptions && searchedOptions[selectedParentFilter]
//         ? searchedOptions[selectedParentFilter]
//         : businessNameList;
//     case "account.accounttypesmap":
//       const accountTypesMapList = getAccountTypesMapList(selectedOptions);
//       return query && searchedOptions && searchedOptions[selectedParentFilter]
//         ? searchedOptions[selectedParentFilter]
//         : accountTypesMapList;
//     default:
//       const sortedList = prepareSortedList(selectedOptions);
//       return query &&
//           selectedParentFilter &&
//           searchedOptions &&
//           searchedOptions[selectedParentFilter]
//         ? searchedOptions[selectedParentFilter]
//         : sortedList;
//     }
//   }, [
//     query,
//     selectedParentFilter,
//     filteredComponents,
//     components,
//     searchedOptions,
//     selectedOptions,
//   ]);

//   const handleInputChange = useCallback(
//     (event: React.ChangeEvent<HTMLInputElement>) => {
//       const newValue = event.currentTarget.value;
//       selectedParentFilter &&
//         setChildQuery((prev) => ({
//           ...prev,
//           [selectedParentFilter]: newValue,
//         }));
//       setQuery(newValue);

//       if (selectedParentFilter) {
//         const parentFilterLower = selectedParentFilter.toLowerCase();
//         if (parentFilterLower === "product area") {
//           const newFilteredComponents: any = searchComponents(
//             newValue,
//             components
//           );
//           setFilteredComponents(newFilteredComponents);
//         } else {
//           const listPreparation: any = {
//             "business name": () => getBusinessNameList(selectedOptions),
//             "account.accounttypesmap": () =>
//               getAccountTypesMapList(selectedOptions),
//             default: () => prepareSortedList(selectedOptions),
//           };

//           const list = (
//             listPreparation[parentFilterLower] || listPreparation["default"]
//           )();
//           if (list) {
//             const filteredOptions = searchFilter(list, newValue);
//             setSearchedOptions((prev) => ({
//               ...prev,
//               [selectedParentFilter]: filteredOptions,
//             }));
//           }
//         }
//       }
//     },
//     [selectedParentFilter, components, selectedOptions]
//   );

//   console.log({
//     selectedParentFilter,
//     childQuery,
//     query,
//     componentsToRender,
//     childFiltersValue,
//   });

//   return (
//     selectedParentFilter && (
//       <>
//         {selectedHasOperations && (
//           <OperationSelect
//             operations={selectedOperators}
//             onChange={(value) => {
//               setOperator((prev: any) => ({
//                 ...prev,
//                 [selectedParentFilter]: value,
//               }));
//             }}
//           />
//         )}
//         <TextInput
//           mx={5}
//           radius="md"
//           value={childQuery && childQuery[selectedParentFilter]}
//           placeholder={`Search ${_selectedParentFilter[_selectedParentFilter.length -1].toLocaleLowerCase()}`}
//           onChange={handleInputChange}
//           icon={<IconListSearch size={14.5} />}
//           sx={{
//             input: {
//               backgroundColor: "#F9FAFB",
//               ":focus": {
//                 borderColor: "#b1b1b1;",
//               },
//             },
//           }}
//         />
//         <ScrollArea style={{ height: 300 }}>
//           <Checkbox.Group
//             value={checkedComponents[selectedParentFilter]}
//             onChange={handleCheckedComponentsChange}
//             orientation="vertical"
//             spacing="xs"
//           >
//             {renderComponents({
//               selectedParentFilter,
//               components: componentsToRender,
//             })}
//           </Checkbox.Group>
//         </ScrollArea>
//         <FilterFooter
//           onClickClear={() => resetFilters(MenuType, selectedParentFilter)}
//           ApplyButton={
//             <AddFilterBtn
//               BtnText="Apply"
//               selectedParentFilter={selectedParentFilter}
//               childFiltersValue={childFiltersValue}
//               disabled={
//                 (!checkedComponents[selectedParentFilter] ||
//                   checkedComponents[selectedParentFilter]?.length == 0) ||
//                 ((selectedParentFilter !== null &&
//                   childFiltersValue[selectedParentFilter]?.length === 0) ||
//                   (selectedHasOperations && !operator))
//               }
//               onClick={() => {
//                 setActiveFilters((prevFilters: any) => [
//                   {
//                     ...ideasFiltersConfig[MenuType][selectedParentFilter],
//                     type: selectedFilter.type,
//                     parent: selectedParentFilter,
//                     operator: operator && operator[selectedParentFilter],
//                     selected: checkedComponents[selectedParentFilter],
//                     query: null,
//                   },
//                   ...prevFilters.filter(
//                     (prevF: any) => prevF.parent !== selectedParentFilter
//                   ),
//                 ]);
//                 setOpenedMenuType(null);
//                 setSelectedParentFilter(null);
//               }}
//             />
//           }
//         />
//       </>
//     )
//   );
// };

// export default CheckboxFilter;




// import React, { useCallback, useContext, useEffect, useMemo } from "react";
// import {
//   Checkbox,
//   ScrollArea,
//   TextInput,
//   Text,
//   Highlight,
// } from "@mantine/core";
// import { IconListSearch } from "@tabler/icons";

// import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
// import FiltersContext from "../context/FiltersContext";
// import OperationSelect from "../OperationSelect";
// import AddFilterBtn from "../AddFilterBtn";
// import SyncContext from "../../../../context/SyncContext";
// import { Filter } from "../FiltersMenu";
// import FilterFooter from "../FilterFooter";

// interface CheckboxFilterProps {
//   initialfilters: Filter;
//   MenuType: "idea" | "evidence";
//   resetFilters: (
//     MenuType: "idea" | "evidence",
//     selectedParentFilter?: string | null
//   ) => void;
// }

// const CheckboxFilter: React.FC<CheckboxFilterProps> = ({
//   MenuType,
//   resetFilters,
// }) => {
//   const { ideasFiltersConfig } = useContext(SyncContext);
//   const {
//     activeFilters,
//     setActiveFilters,
//     selectedParentFilter,
//     checkedComponents,
//     setCheckedComponents,
//     childFiltersValue,
//     childQuery,
//     setChildQuery,
//     query,
//     setQuery,
//     operator,
//     setOperator,
//     setOpenedMenuType,
//     setSelectedParentFilter,
//   } = useContext(FiltersContext);

//   const _selectedParentFilter: string[] = selectedParentFilter?.split(".") || [""];

//   // Function to recursively get the selected filter based on the path
//   const getSelectedFilter = (config: any, path: string[]): any => {
//     let current = config[MenuType];
  
//     for (const key of path) {
//       if (current && current[key]) {
//         current = current[key];
//       } else if (current && current.childFilters) {
//         // Try to find the filter in childFilters by key or by name (case-insensitive)
//         const keys = Object.keys(current.childFilters);
//         const matchingKey = keys.find(
//           (k) => k.toLowerCase().replace(/\s+/g, '-') === key.toLowerCase()
//         );
  
//         if (matchingKey) {
//           current = current.childFilters[matchingKey];
//         } else {
//           // Try to find by 'name' property
//           const matchingFilter = Object.values(current.childFilters).find(
//             (filter: any) => filter.name.toLowerCase() === key.toLowerCase()
//           );
//           if (matchingFilter) {
//             current = matchingFilter;
//           } else {
//             return undefined;
//           }
//         }
//       } else {
//         return undefined;
//       }
//     }
//     return current;
//   };
  
//   // console.log({ideasFiltersConfig, _selectedParentFilter})
//   const selectedFilter = getSelectedFilter(ideasFiltersConfig, _selectedParentFilter);

//   const selectedHasOperations = selectedFilter?.operations?.length > 0 || false;
//   const selectedOperators = selectedFilter?.operations;
//   const selectedOptions = selectedFilter?.options;
//   console.log("MenuType",{selectedFilter})

//   useEffect(() => {
//     const currentFilters: any = activeFilters.find(
//       (aF) => aF.parent === selectedParentFilter
//     );

//     if (currentFilters?.selected && selectedParentFilter && currentFilters) {
//       setCheckedComponents((prev) => ({
//         ...prev,
//         [selectedParentFilter]: currentFilters.selected.map((o: string) =>
//           o.toLowerCase()
//         ),
//       }));
//     }
//   }, []);

//   const renderOptions = (options: any) => {
//     console.log("renderOptions",{options})
//     if (Array.isArray(options)) {
//       return options.map((option: any, index: number) => (
//         <Checkbox
//           key={index}
//           sx={{
//             cursor: "pointer",
//             input: {
//               ":checked": {
//                 backgroundColor: "#5B60E3",
//                 borderColor: "#5B60E3",
//               },
//             },
//           }}
//           pr="sm"
//           pl={"sm"}
//           py={3}
//           value={option.value?.toLowerCase() || option.label?.toLowerCase()}
//           label={
//             <Text>
//               <Highlight highlight={capitalizeFirstLetter(query)}>
//                 {capitalizeFirstLetter(option.label)}
//               </Highlight>
//             </Text>
//           }
//         />
//       ));
//     } else if (typeof options === "object") {
//       return Object.entries(options).map(([key, value], index) => (
//         <Checkbox
//           key={index}
//           sx={{
//             cursor: "pointer",
//             input: {
//               ":checked": {
//                 backgroundColor: "#5B60E3",
//                 borderColor: "#5B60E3",
//               },
//             },
//           }}
//           pr="sm"
//           pl={"sm"}
//           py={3}
//           value={key}
//           label={
//             <Text>
//               <Highlight highlight={capitalizeFirstLetter(query)}>
//                 {capitalizeFirstLetter(value)}
//               </Highlight>
//             </Text>
//           }
//         />
//       ));
//     }
//     return null;
//   };

//   const handleCheckedComponentsChange = (selectedArray: string[]) => {
//     selectedParentFilter &&
//       setCheckedComponents((prev) => ({
//         ...prev,
//         [selectedParentFilter]: selectedArray.map((i:string) => i.toLowerCase()),
//       }));
//   };

//   const optionsToRender = useMemo(() => {
//     if (!selectedOptions) return [];

//     let optionsList:any[] = [];

//     if (Array.isArray(selectedOptions)) {
//       optionsList = selectedOptions.map((option: any) => ({
//         label: option.label.label || option.label,
//         value: option.value.label || option.value,
//       }));
//     } else if (typeof selectedOptions === "object") {
//       optionsList = Object.entries(selectedOptions).map(([key, value]) => ({
//         label: value,
//         value: key,
//       }));
//     }

//     if (query) {
//       optionsList = optionsList.filter((option: any) =>
//         option.label.toLowerCase().includes(query.toLowerCase())
//       );
//     }

//     return optionsList;
//   }, [selectedOptions, query]);

//   const handleInputChange = useCallback(
//     (event: React.ChangeEvent<HTMLInputElement>) => {
//       const newValue = event.currentTarget.value;
//       selectedParentFilter &&
//         setChildQuery((prev) => ({
//           ...prev,
//           [selectedParentFilter]: newValue,
//         }));
//       setQuery(newValue);
//     },
//     [selectedParentFilter]
//   );

//   return (
//     selectedParentFilter && (
//       <>
//         {selectedHasOperations && (
//           <OperationSelect
//             operations={selectedOperators}
//             onChange={(value) => {
//               setOperator((prev: any) => ({
//                 ...prev,
//                 [selectedParentFilter]: value,
//               }));
//             }}
//           />
//         )}
//         <TextInput
//           mx={5}
//           radius="md"
//           value={childQuery && childQuery[selectedParentFilter]}
//           placeholder={`Search ${_selectedParentFilter[_selectedParentFilter.length -1].toLocaleLowerCase()}`}
//           onChange={handleInputChange}
//           icon={<IconListSearch size={14.5} />}
//           sx={{
//             input: {
//               backgroundColor: "#F9FAFB",
//               ":focus": {
//                 borderColor: "#b1b1b1;",
//               },
//             },
//           }}
//         />
//         <ScrollArea style={{ height: 300 }}>
//           <Checkbox.Group
//             value={checkedComponents[selectedParentFilter]}
//             onChange={handleCheckedComponentsChange}
//             orientation="vertical"
//             spacing="xs"
//           >
//             {renderOptions(optionsToRender)}
//           </Checkbox.Group>
//         </ScrollArea>
//         <FilterFooter
//           onClickClear={() => resetFilters(MenuType, selectedParentFilter)}
//           ApplyButton={
//             <AddFilterBtn
//               BtnText="Apply"
//               selectedParentFilter={selectedParentFilter}
//               childFiltersValue={childFiltersValue}
//               disabled={
//                 (!checkedComponents[selectedParentFilter] ||
//                   checkedComponents[selectedParentFilter]?.length === 0) ||
//                 ((selectedParentFilter !== null &&
//                   childFiltersValue[selectedParentFilter]?.length === 0) ||
//                   (selectedHasOperations && !operator))
//               }
//               onClick={() => {
//                 setActiveFilters((prevFilters: any) => [
//                   {
//                     ...selectedFilter,
//                     type: selectedFilter.type,
//                     parent: selectedParentFilter,
//                     operator: operator && operator[selectedParentFilter],
//                     selected: checkedComponents[selectedParentFilter],
//                     query: null,
//                   },
//                   ...prevFilters.filter(
//                     (prevF: any) => prevF.parent !== selectedParentFilter
//                   ),
//                 ]);
//                 setOpenedMenuType(null);
//                 setSelectedParentFilter(null);
//               }}
//             />
//           }
//         />
//       </>
//     )
//   );
// };

// export default CheckboxFilter;

import React, { useCallback, useContext, useEffect, useMemo } from "react";
import {
  Checkbox,
  ScrollArea,
  TextInput,
  Text,
  Highlight,
  Stack,
} from "@mantine/core";
import { IconListSearch } from "@tabler/icons";

import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import FiltersContext from "../context/FiltersContext";
import OperationSelect from "../OperationSelect";
import AddFilterBtn from "../AddFilterBtn";
import SyncContext from "../../../../context/SyncContext";
import { Filter } from "../FiltersMenu";
import FilterFooter from "../FilterFooter";
import { findFiltersByPath } from "../../../../context/helpers";

interface CheckboxFilterProps {
  initialfilters: Filter;
  MenuType: "idea" | "evidence";
  resetFilters: (
    MenuType: "idea" | "evidence",
    selectedParentFilter?: string | null
  ) => void;
}

const CheckboxFilter: React.FC<CheckboxFilterProps> = ({
  MenuType,
  resetFilters,
}) => {
  const { ideasFiltersConfig } = useContext(SyncContext);
  const {
    activeFilters,
    setActiveFilters,
    selectedParentFilter,
    checkedComponents,
    setCheckedComponents,
    childFiltersValue,
    childQuery,
    setChildQuery,
    query,
    setQuery,
    operator,
    setOperator,
    setOpenedMenuType,
    setSelectedParentFilter,
  } = useContext(FiltersContext);

  console.log(selectedParentFilter)

  const _selectedParentFilter: string[] = selectedParentFilter?.split(".") || [""];

  // Function to recursively get the selected filter based on the path
  const getSelectedFilter = (config: any, path: string[]): any => {
    let current = config[MenuType];

    for (const key of path) {
      if (current && current[key]) {
        current = current[key];
      } else if (current && current.childFilters) {
        // Try to find the filter in childFilters by key or by name (case-insensitive)
        const keys = Object.keys(current.childFilters);
        const matchingKey = keys.find(
          (k) => k.toLowerCase().replace(/\s+/g, '-') === key.toLowerCase()
        );

        if (matchingKey) {
          current = current.childFilters[matchingKey];
        } else {
          // Try to find by 'name' property
          const matchingFilter = Object.values(current.childFilters).find(
            (filter: any) => filter.name.toLowerCase() === key.toLowerCase()
          );
          if (matchingFilter) {
            current = matchingFilter;
          } else {
            return undefined;
          }
        }
      } else {
        return undefined;
      }
    }
    return current;
  };

  const selectedFilter = getSelectedFilter(ideasFiltersConfig, _selectedParentFilter);

  const selectedHasOperations = selectedFilter?.operations?.length > 0 || false;
  const selectedOperators = selectedFilter?.operations;
  const selectedOptions = selectedFilter?.options;

  useEffect(() => {
    const currentFilters: any = activeFilters.find(
      (aF) => aF.parent === selectedParentFilter
    );

    if (currentFilters?.selected && selectedParentFilter && currentFilters) {
      setCheckedComponents((prev) => ({
        ...prev,
        [selectedParentFilter]: currentFilters.selected.map((o: string) =>
          o.toLowerCase()
        ),
      }));
    }
  }, []);

  // Recursive function to render options, including childComponents
  const renderOptions = (options: any, parentPath: string[] = []) => {
    if (!options) return null;

    return options.map((option: any, index: number) => {
      const currentPath = [...parentPath, option.value?.toLowerCase() || option.label?.toLowerCase()];
      const hasChildren = option.childComponents && option.childComponents.length > 0;

      return (
        <div key={option.value || option.label || index} style={{ paddingLeft: parentPath.length * 20 }}>
          <Checkbox
            my={5}
            sx={{
              cursor: "pointer",
              input: {
                ":checked": {
                  backgroundColor: "#5B60E3",
                  borderColor: "#5B60E3",
                },
              },
            }}
            pr="sm"
            pl={"sm"}
            py={3}
            value={option.value?.toLowerCase() || option.label?.toLowerCase()}
            label={
              <Text>
                <Highlight highlight={capitalizeFirstLetter(query)}>
                  {capitalizeFirstLetter(option.label)}
                </Highlight>
              </Text>
            }
          />
          {hasChildren && renderOptions(option.childComponents, currentPath)}
        </div>
      );
    });
  };

  // Function to find an option (and its children) by value
  const findOptionByValue = (optionsList: any[], value: string): any => {
    for (const option of optionsList) {
      if ((option.value?.toLowerCase() || option.label?.toLowerCase()) === value) {
        return option;
      }
      if (option.childComponents) {
        const found = findOptionByValue(option.childComponents, value);
        if (found) return found;
      }
    }
    return null;
  };

  // Function to get all child values (recursive)
  const getAllChildValues = (option: any): string[] => {
    let values = [];
    if (option.value || option.label) {
      values.push((option.value?.toLowerCase() || option.label?.toLowerCase()));
    }
    if (option.childComponents) {
      for (const child of option.childComponents) {
        values = values.concat(getAllChildValues(child));
      }
    }
    return values;
  };

  const handleCheckedComponentsChange = (selectedArray: string[]) => {
    if (selectedParentFilter) {
      const prevSelectedArray = checkedComponents[selectedParentFilter] || [];

      // Find out which checkbox was toggled
      const addedValues = selectedArray.filter(value => !prevSelectedArray.includes(value));
      const removedValues = prevSelectedArray.filter(value => !selectedArray.includes(value));

      const toggledValue = addedValues.length > 0 ? addedValues[0] : removedValues[0];
      const isAdding = addedValues.length > 0;

      // Now we need to find if the toggledValue has childComponents
      const toggledOption = findOptionByValue(optionsToRender, toggledValue);

      if (toggledOption) {
        const allValues = getAllChildValues(toggledOption);

        if (isAdding) {
          // Add all child values to selectedArray
          allValues.forEach((val) => {
            if (!selectedArray.includes(val)) {
              selectedArray.push(val);
            }
          });
        } else {
          // Remove all child values from selectedArray
          selectedArray = selectedArray.filter(value => !allValues.includes(value));
        }
      }

      // Update the state
      setCheckedComponents((prev) => ({
        ...prev,
        [selectedParentFilter]: selectedArray,
      }));
    }
  };

  const optionsToRender = useMemo(() => {
    if (!selectedOptions) return [];

    let optionsList: any[] = [];

    // console.log({selectedOptions})

    if (Array.isArray(selectedOptions)) {
      optionsList = selectedOptions.map((option: any) => ({
        label: option?.label?.label || option.label,
        value: option?.value?.label || option.value,
        childComponents: option.childComponents || [],
      }));
    } else if (typeof selectedOptions === "object") {
      optionsList = Object.entries(selectedOptions).map(([key, value]: [string, any]) => ({
        label: value.name || value.label || key,
        value: value.value || key,
        childComponents: value.childComponents || [],
      }));
    }

    if (query) {
      const lowerQuery = query.toLowerCase();
      const filterOptions = (opts: any[]): any[] => {
        return opts
          .filter((option) => option.label.toLowerCase().includes(lowerQuery))
          .map((option) => ({
            ...option,
            childComponents: filterOptions(option.childComponents || []),
          }));
      };
      optionsList = filterOptions(optionsList);
    }

    return optionsList;
  }, [selectedOptions, query]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.currentTarget.value;
      selectedParentFilter &&
        setChildQuery((prev) => ({
          ...prev,
          [selectedParentFilter]: newValue,
        }));
      setQuery(newValue);
    },
    [selectedParentFilter]
  );

  return (
    selectedParentFilter && (
      <>
        {selectedHasOperations && (
          <OperationSelect
            operations={selectedOperators}
            onChange={(value) => {
              setOperator((prev: any) => ({
                ...prev,
                [selectedParentFilter]: value,
              }));
            }}
          />
        )}
        <TextInput
          mx={5}
          radius="md"
          value={childQuery && childQuery[selectedParentFilter]}
          placeholder={`Search ${_selectedParentFilter[_selectedParentFilter.length - 1].toLocaleLowerCase()}`}
          onChange={handleInputChange}
          icon={<IconListSearch size={14.5} />}
          sx={{
            input: {
              backgroundColor: "#F9FAFB",
              ":focus": {
                borderColor: "#b1b1b1;",
              },
            },
          }}
        />
        <ScrollArea   style={{ height: selectedParentFilter ==='evidence_priority'? 150: 280 }} /* mah={300} */>
        
          <Checkbox.Group
            mt={8}
            value={checkedComponents[selectedParentFilter]}
            onChange={handleCheckedComponentsChange}
          >
            {renderOptions(optionsToRender)}
          </Checkbox.Group>
        
        </ScrollArea>
        <FilterFooter
          onClickClear={() => resetFilters(MenuType, selectedParentFilter)}
          ApplyButton={
            <AddFilterBtn
              BtnText="Apply"
              selectedParentFilter={selectedParentFilter}
              childFiltersValue={childFiltersValue}
              disabled={
                (!checkedComponents[selectedParentFilter] ||
                  checkedComponents[selectedParentFilter]?.length === 0) ||
                ((selectedParentFilter !== null &&
                  childFiltersValue[selectedParentFilter]?.length === 0) ||
                  (selectedHasOperations && !operator))
              }
              onClick={() => {
                const parentFilterObj = findFiltersByPath(ideasFiltersConfig, `${MenuType}.${selectedParentFilter}`);
                
                setActiveFilters((prevFilters: any) => [
                  {
                    ...parentFilterObj,
                    type: parentFilterObj?.type,
                    parent: selectedParentFilter,
                    operator: operator && operator[selectedParentFilter],
                    selected: checkedComponents[selectedParentFilter],
                    query: null,
                  },
                  ...prevFilters.filter(
                    (prevF: any) => prevF.parent !== selectedParentFilter
                  ),
                ]);
                setOpenedMenuType(null);
                setSelectedParentFilter(null);
              }}
            />
          }
        />
      </>
    )
  );
};

export default CheckboxFilter;