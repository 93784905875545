import { Box, Checkbox, Flex, Menu, Text } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import IdeaContext from '../../IdeaContext';

import { calcTrend, calcTrendWeeks } from './TrendGraphHelpers';
import { IconCaretDown, IconCheck } from '@tabler/icons'; 
import FiltersContext from '../../../ideas/filters/context/FiltersContext';
import Trend from './Trend';
import { findFilterByName } from '../../../../context/helpers';
import SyncContext from '../../../../context/SyncContext';

const TrendGraph = () => {
  const {
    requestsTrend = [],
    matchesTrend = [],
    /* requestsMatchesTrend = [],  */
    quarters = [],
    activeInboxTab = [],
    showmatches = [],
    showValidated = [], 
    weeks  = []
  } = useContext(IdeaContext);

  const { activeFilters ,setActiveFilters } = useContext(FiltersContext);
  const { ideasFiltersConfig }  = useContext(SyncContext);
  const [trendArr, setTrendArr] = useState<any[]>([]);
  const [trendArrWeeks, setTrendArrWeeks] = useState<any[]>([]);
  const [allQuarterFilterValues, setAllQuarterFilterValues] = useState<any[]>([]);
  const [allWeekFilterValues, setAllWeekFilterValues] = useState<any[]>([]);
  const [allQuartersChecked, setAllQuartersChecked] = useState(false);
  const [allWeeksChecked, setAllWeeksChecked] = useState(false);
  const [selectedQuarters, setSelectedQuarters] = useState<string[]>([]);
  const [selectedWeeks, setSelectedWeeks] = useState<string[]>([]);
  const [graphSelection, setGraphSelection] = useState('Past 18 Months')
  const [moreThanThirtyEvidenceWeeks, setMoreThanThirtyEvidenceWeeks] = useState(false);
  const [moreThanThirtyEvidenceQuarter, setMoreThanThirtyEvidenceQuarter] = useState(false);

  const handleDisplayAll = (click: boolean , type: string) => {

    if(type === 'Past 18 Months'){
      const quarterFilterConfig = findFilterByName('quarter', 'evidence', ideasFiltersConfig)
      console.log("TrendGraph",{quarterFilterConfig})

      setActiveFilters((prevFilters) => {

        if (click){
          setSelectedQuarters(allQuarterFilterValues)
        }
        else if(!click){
          setSelectedQuarters([])
        }
  
        const index = prevFilters.findIndex((filter) => filter.parent === quarterFilterConfig.parent);
        if (index !== -1) {
          // Update the existing filter
          const updatedFilters = [...prevFilters];
          updatedFilters[index] = {
            ...quarterFilterConfig,
            ...updatedFilters[index],
            selected: click ? allQuarterFilterValues : [],
          };
          return updatedFilters;
        } else {
          // Add a new filter if it does not exist (optional based on your logic)
          return [
            ...prevFilters,
            {
              ...quarterFilterConfig,
              selected: click ? allQuarterFilterValues : [],
            },
          ];
        }
      });

    }

    else if(type === 'Past 3 Months'){
      const weekFilterConfig = findFilterByName('week', 'evidence', ideasFiltersConfig)
      console.log("TrendGraph",{weekFilterConfig})
      setActiveFilters((prevFilters) => {

        if (click){
          setSelectedWeeks(allWeekFilterValues)
        }
        else if(!click){
          setSelectedWeeks([])
        }
  
        const index = prevFilters.findIndex((filter) => filter.parent === weekFilterConfig.parent);
        if (index !== -1) {
          // Update the existing filter
          const updatedFilters = [...prevFilters];
          updatedFilters[index] = {
            ...updatedFilters[index],
            ...weekFilterConfig,
            selected: click ? allWeekFilterValues : [],
          };
          return updatedFilters;
        } else {
          // Add a new filter if it does not exist (optional based on your logic)
          return [
            ...prevFilters,
            {
              ...weekFilterConfig,
              selected: click ? allWeekFilterValues : [],
            },
          ];
        }
      });
    } 
  };
  


  function arraysHaveSameValues<T>(arr1: T[], arr2: T[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
  
    return sortedArr1.every((value, index) => value === sortedArr2[index]);
  }

  useEffect(() => {
    const assigned = requestsTrend.filter(
      (r: any) => r.status === "validated"
    );

    const pending = requestsTrend.filter(
      (r: any) => r.status === "new"
    );
    const suggested = activeInboxTab ? 
      [...pending/* , ...requestsMatchesTrend */] :
      (showValidated && showmatches) ?
        [...pending, ...matchesTrend/* , ...requestsMatchesTrend */] : (!showValidated && showmatches) ?

          [...matchesTrend] : []

    const validated = showValidated ? [...assigned] : []

    const trendGraphArr = calcTrend(validated, suggested, quarters)
    setTrendArr(trendGraphArr?.graphDataArr)
    setMoreThanThirtyEvidenceQuarter(trendGraphArr?.moreThanThirty)
    const trendGraphArrWeeks = calcTrendWeeks(validated, suggested, weeks)

   
    setTrendArrWeeks(trendGraphArrWeeks?.graphDataArr)
    setMoreThanThirtyEvidenceWeeks(trendGraphArrWeeks?.moreThanThirty)

  }, [requestsTrend ,matchesTrend, /* requestsMatchesTrend, */ showValidated, showmatches, activeInboxTab, quarters, weeks]);

  useEffect(() => {

    const pushed = [...quarters, 'older']
    const lowerCased = pushed.map((p: string)=> p = p.toLocaleLowerCase())
    const lowerCasedWeeks = weeks.map((p: string)=> p = p.toLocaleLowerCase())

    setAllQuarterFilterValues(lowerCased)

    const weeksLowerCased = weeks.map((p: string)=> p = p.toLocaleLowerCase())

    setAllWeekFilterValues(weeksLowerCased)

    const quarterFilter = activeFilters.find((filter: any)=> filter.parent === 'quarter')

    if(!quarterFilter){
      setSelectedQuarters([])
    }

    if(quarterFilter){

      const selectedQuartersFilter = quarterFilter?.selected || []

      const allSelected = arraysHaveSameValues(selectedQuartersFilter, lowerCased) // Fix for 3 months

      setAllQuartersChecked(allSelected)

    }

    const weekFilter = activeFilters.find((filter: any)=> filter.parent === 'Week')

    if(!weekFilter){
      setSelectedWeeks([])
    }

    if(weekFilter){

      const selectedWeeksFilter = weekFilter?.selected || []

      const allSelected = arraysHaveSameValues(selectedWeeksFilter, lowerCasedWeeks) // Fix when chnging graph selection

      setAllWeeksChecked(allSelected)

    }
    
  }, [quarters, activeFilters, weeks]);


  return (
    <Box>
      <Box>
        <Flex mt={46} ml={24}>
          <Text fw={600} fz={14} color="#212529" mr={4}>
          Creation date:
          </Text>
          <Menu>
            <Menu.Target>
              <Flex sx={{cursor: 'pointer'}}>
                <Text fw={600} fz={14} color="#212529" mr={2.2}>
                  {graphSelection}
                </Text>
                <Box>
                  <IconCaretDown color='black' fill='black' size={16} />
                </Box>
              </Flex>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={()=> {
                setGraphSelection('Past 18 Months')
                handleDisplayAll(false, 'Past 3 Months')
              }}>
                <Flex>
                  <Text mr={3}>
                  Past 18 Months
                  </Text>
                  {graphSelection === 'Past 18 Months' && <IconCheck size={16}/>}
                </Flex>
              </Menu.Item>
              <Menu.Item onClick={()=> {
                setGraphSelection('Past 3 Months')
                handleDisplayAll(false, 'Past 18 Months')
              }}>
                <Flex>
                  <Text mr={3}>
                  Past 3 Months
                  </Text>
                  {graphSelection === 'Past 3 Months' && <IconCheck size={16}/>}
                </Flex>
              
              </Menu.Item>

            </Menu.Dropdown>
          </Menu>

        </Flex>
        <Flex justify='space-between' >


          <Flex ml={24} mt='md' align='center'>
            {graphSelection === 'Past 18 Months' ? 
              <Checkbox
                indeterminate={!allQuartersChecked && selectedQuarters.length !== 0} 
                checked={allQuartersChecked}
                onChange={(event) => {
                  setAllQuartersChecked(event.currentTarget.checked);
                  handleDisplayAll(event.currentTarget.checked, graphSelection);
                }}
                color='indigo' 
                mr='xs'/>
              :

              <Checkbox
                indeterminate={!allWeeksChecked && selectedWeeks.length !== 0} 
                checked={allWeeksChecked}
                onChange={(event) => {
                  setAllWeeksChecked(event.currentTarget.checked);
                  handleDisplayAll(event.currentTarget.checked, graphSelection);
                }}
                color='indigo' 
                mr='xs'/>
            } 
            <Text fz={12}>
            Display all evidence
            </Text>
          </Flex>
          <Flex ml="10px" mt='md' align='center' mr={100}>
            
            <Text mr={5}  color="#888D9B" fz="12px" fw={600}>
                Assigned
            </Text>
            <Box
              sx={{
                width: 5.4,  // Width of the dot
                height: 5.4, // Height of the dot
                borderRadius: '50%', // Make it round
                backgroundColor: "#5C5CEB", // Change color as needed
              }}
              mr={8}
            />
            <Text  color="#888D9B" fz="12px" fw={600} mr={5} >
                Suggested
            </Text>
            <Box
              
              sx={{
                width: 5.4,  // Width of the dot
                height: 5.4, // Height of the dot
                borderRadius: '50%', // Make it round
                backgroundColor: "rgba(92, 92, 235, 0.6)", 
              }}
            />
          </Flex>
        </Flex>
      </Box>
      <Trend 
        dataArr={graphSelection === 'Past 18 Months' ? trendArr : trendArrWeeks}
        selectedValues={graphSelection === 'Past 18 Months' ? selectedQuarters : selectedWeeks} 
        setSelectedValues={graphSelection === 'Past 18 Months' ? setSelectedQuarters : setSelectedWeeks}
        quarter={graphSelection === 'Past 18 Months'}
        moreThanThirty={graphSelection === 'Past 18 Months' ? moreThanThirtyEvidenceQuarter : moreThanThirtyEvidenceWeeks}
      />
  
    </Box>
  );
};

export default TrendGraph;