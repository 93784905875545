import {Paper, Text} from "@mantine/core";
import React from "react";

export const ExplainCard = () => {
  return <Paper mt={"lg"} withBorder  shadow={"lg"}>
    <Text p={"20px"} color={'rgba(22, 24, 50, 0.5)'}>With the Gong integration, you can access all call transcripts related to your CRM objects (i.e. accounts, opportunities, etc.) and deepen your understanding of product gaps and user needs. Follow-up on product requests and commitments that block your deals pipeline and keep your customers informed about what they care most about.

    </Text>
    <Text p={"20px"} color={'rgba(22, 24, 50, 0.5)'}>
      Learn more about the Bagel-Gong integration.
    </Text>
  </Paper>
}