import React, {useContext, useEffect, useState} from "react";
import {
  Table,
  TextInput,
  Text,
  Button,
  ActionIcon,
  Select,
  Paper,
  Group,
  Box,
  Title,
  Divider,
  Center,
} from "@mantine/core";
import { IconCheck, IconDeviceFloppy, IconError404, IconExclamationCircle, IconPencil, IconTrash, IconX } from "@tabler/icons";

import { useForm } from "@mantine/form";
import { useListState } from "@mantine/hooks";
import { jiraSearcherKeys, jiraTypes } from "../../../../types/types";
import { createJiraCustomField, deleteJiraCustomField, getJiraCustomFields } from "../../../../api/integrations";
import { useAuth0 } from "@auth0/auth0-react";
import { showNotification } from "@mantine/notifications";
import SyncContext from "../../../../context/SyncContext";

export type CustomField = {
  _id?: string;
  description: string;
  name: string;
  searcherKey: string;
  type: string;
  dataAccessor: string;
  state?: string;
};

export const CustomFieldRow = ({
  field,
  createField,
  updateField,
  deleteField,
  isEditing,
  setEditingId,
  dataAccessors,
  customFieldsHandlers,
}: any) => {
  const form = useForm({
    initialValues: field.state === "new" ? field : {
      _id: field._id,
      dataAccessor: field.dataAccessor,
      ...field.field
    },
  });

  const onSubmit = () => {
    if (field.state === "new") {
      createField(form.values);
    } else {
      updateField(field._id, form.values);
    }
    setEditingId(null);
  };

  const isInEditMode = isEditing || field.state === "new";

  return (
    <tr>
      {field.state === "new" ? (
        <>
          {/* Edit Mode */}
          <td>
            <Group>
              <ActionIcon
                variant="outline"
                onClick={() =>
                  field.state === "new"
                    ? customFieldsHandlers.filter(
                      (item: any) => field._id !== item._id
                    )
                    : setEditingId(null)
                }
              >
                <IconX size={16} />
              </ActionIcon>
            </Group>
          </td>
          <td>
            <TextInput variant="unstyled" placeholder="Insert name" {...form.getInputProps("name")} />
          </td>
          <td>
            <TextInput
              variant="unstyled"
              placeholder="Insert small description"
              {...form.getInputProps("description")}
            />
          </td>
          <td>
            <Select
              size="xs"
              data={jiraTypes}
              placeholder="Select field type"
              {...form.getInputProps("type")}
            />
          </td>
          <td>
            <Select
              size="xs"
              placeholder="Select field searcher key"
              disabled={!form.values.type}
              data={
                form.values.type
                  ? jiraSearcherKeys.filter((searcherKey: any) =>
                    searcherKey.jiraTypes.includes(form.values.type)
                  )
                  : jiraSearcherKeys
              }
              {...form.getInputProps("searcherKey")}
            />
          </td>
          <td>
            <Select
              size="xs"
              data={dataAccessors}
              placeholder="Select dataAccessor (bagel data)"
              {...form.getInputProps("dataAccessor")}
            />
          </td>
          <td>
            <Group noWrap position="right" spacing={6}>
              <ActionIcon onClick={onSubmit} color="blue" variant="filled">
                { isEditing ? <IconDeviceFloppy size={16} /> : <IconCheck size={16} /> }
              </ActionIcon>
            </Group>
          </td>
        </>
      ) : (
        <>
          {/* View Mode */}
          <td style={{ width: 0, padding: 0 }}></td>
          <td style={{ textAlign: "left" }}>
            <strong>{field.field.name}</strong>
          </td>
          <td style={{ textAlign: "left", padding: "3px 10px" }}>
            {field.field.description}
          </td>
          <td style={{ 
            textAlign: "left", 
            maxWidth: "200px", // or whatever maximum width you want
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}>{field.field.type}</td>
          <td style={{ 
            textAlign: "left", 
            maxWidth: "200px", // or whatever maximum width you want
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}>{field.field.searcherKey}</td>
          <td style={{ textAlign: "left" }}>{field.dataAccessor}</td>
          <td>
            {/* <Group noWrap position="right" spacing={6}>
              <ActionIcon
                onClick={() => setEditingId(field._id)}
                color="blue"
                variant="outline"
              >
                <IconPencil size={16} /> 
              </ActionIcon>
              <ActionIcon
                onClick={() => deleteField(field._id)}
                color="red"
                variant="outline"
              >
                <IconTrash size={16} />
              </ActionIcon>
            </Group> */}
          </td>
        </>
      )}
    </tr>
  );
};

export const SetupCustomField = ({
  integration,
  customization,
  variant,
  reloadIntegration,
}: {
  integration: any;
  customization?: any;
  variant?: "wizard" | "settings";
  reloadIntegration: () => void;
}) => {
  const auth0 = useAuth0();
  const { allAggregationProperties } = useContext(SyncContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [editingId, setEditingId] = useState<string | null>(null);

  const dataAccessors = allAggregationProperties
    .filter((property: any) => !property.groupBy) // Exclude properties with groupBy
    .map((property: any) => {
      const propertyName = property.propertyName || property.label; // Use propertyName or fallback to label
      return {
        value: `${propertyName}.total`,
        label: `Total ${property.label}`,
      };
    });
  
  const [customFields, customFieldsHandlers] = useListState<CustomField>();

  const addNewField = () => {
    customFieldsHandlers.append({
      _id: `${Date.now()}`, // Temp ID, can be replaced when saving to backend
      description: "",
      name: "",
      searcherKey: "",
      type: "",
      dataAccessor: "",
      state: "new",
    });
  };

  const createField = async (newField: CustomField) => {
    const customFieldData = {...newField};
    delete customFieldData.state;
    delete customFieldData._id;

    await createJiraCustomField(customFieldData, auth0)
      .then(({data}) => {
        customFieldsHandlers.applyWhere(
          (item: any) => item._id == newField._id,
          () => ({ ...data.insertedItem })
        );
        console.log({ createJiraCustomField: data });
      })
      .catch((e) => {
        console.log({createJiraCustomField: e})

        showNotification({
          title: "Error",
          color: "red",
          message: e.response.statusText,
          icon: <IconExclamationCircle size="1.1rem" />,
          autoClose: 1200
        })

      })
      .finally(() => setLoading(false));
  };

  const getCustomFields = async () => {
    await getJiraCustomFields(auth0)
      .then(({ data }) => customFieldsHandlers.setState(data?.fields))
      .catch((e) => console.log(e));
  };

  const updateField = async (customFieldId: string) => {
    await deleteJiraCustomField(customFieldId, auth0)
      .then(({ data }) => console.log(data?.fields))
      .catch((e) => console.log(e));
  };

  const deleteField = async (customFieldId: string) => {
    await deleteJiraCustomField(customFieldId, auth0)
      .then(({ data }) => {
        customFieldsHandlers.filter(
          (item: any) => customFieldId !== item._id
        )
        console.log(data?.fields)})
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getCustomFields();
  }, []);

  return (
    <Paper mt={"24px"} shadow={"lg"} withBorder>
      <Box p="sm">
        <Group position="apart">
          <Text size="md" weight={500}>
            Custom Fields
          </Text>
          <Button compact onClick={addNewField}>Add New</Button>
        </Group>
      </Box>
      <Divider />
      <Box 
        sx={{overflow:"scroll"}}>
        <Table
          sx={{
            "tbody tr td": {
              height: "50px",
            },
          }}
        >
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Description</th>
              <th>Type</th>
              <th>Searcher Key</th>
              <th>Data Accessor</th>
              <th style={{ textAlign: "right" }}></th>
            </tr>
          </thead>
          <tbody>
            {customFields?.map((field: any) => (
              <CustomFieldRow
                key={field._id}
                field={field}
                createField={createField}
                updateField={updateField}
                deleteField={deleteField}
                isEditing={editingId === field._id}
                setEditingId={setEditingId}
                dataAccessors={dataAccessors}
                customFieldsHandlers={customFieldsHandlers}
              />
            ))}
          </tbody>
        </Table>
        {customFields.length == 0 && <Center w="100%" p="md"><Text>there's no custom fields yet...</Text></Center>}
      </Box>
    </Paper>
  );
};
