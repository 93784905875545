import React, { useContext } from 'react';
import { Box, Group, TextInput, ActionIcon, Transition, Stack } from "@mantine/core";
import { IconSearch, IconX } from "@tabler/icons";
import IdeasContext from '../IdeasContext';

interface FilterPanelProps {
  toggle: () => void;
  search: string;
  setSearch: (search: string) => void;
  searchOpened: boolean;
  setSearchOpened: (opened: boolean) => void;  // Add this line in your interface
}

const SearchPanel: React.FC<FilterPanelProps> = ({ toggle, search, setSearch, searchOpened, setSearchOpened }) =>{

  const { setSortStatus } = useContext(IdeasContext)
  const clearSearch = () => {
    setSearch(''); // Clear the search input field
    setSortStatus({ columnAccessor: "none", direction: "asc" }); // Optionally reset the sorting
  };

  return (
    <Box p={0} mr={3.8}>
      <Group noWrap>
 
        <Group>
          <Transition mounted={searchOpened} transition="fade"  duration={500} timingFunction="ease-in-out">
            {(styles) => (
              <div style={styles} >
                <TextInput
                  value={search}
                  onChange={(event) => {
                    setSearch(event.currentTarget.value)}
                  }
                  /* variant="filled" */
                  size="xs"
                  rightSection={
                    search && (
                      <ActionIcon
                        onClick={clearSearch}
                        size={20}
                        sx={{ cursor: 'pointer' }}
                      >
                        <IconX size={16} />
                      </ActionIcon>
                    )
                  }
                />
              </div>
            )}
          </Transition>
          <ActionIcon onClick={() => {
            if(!searchOpened){
              setSortStatus({ columnAccessor: "none", direction: "asc" })
            }
            else if(searchOpened){
              setSortStatus({ columnAccessor: "updatedAt", direction: "asc" })
            }
            setSearchOpened(!searchOpened)
          }}>
            <Stack align='center' justify='center' h={32} w={32} sx={{border: '1.4px solid #ddd2e6', borderRadius: 6}}>
              <IconSearch size={15} />
            </Stack>
            
          </ActionIcon>
        </Group>
      </Group>
    </Box>
  );

} 
     
export default SearchPanel;



{/* <Box mb="1.5px">
        <Tooltip label="Filters" withArrow>
          <ActionIcon onClick={toggle}>
            <IconFilter strokeWidth={2} size={22} color="black" />
          </ActionIcon>
        </Tooltip>
      </Box> */}