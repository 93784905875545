import { useContext,useEffect } from "react";
import { Box, Flex, Grid, ScrollArea, Stack, Text } from "@mantine/core";
import FiltersContext from "../../ideas/filters/context/FiltersContext";
import SyncContext from "../../../context/SyncContext";
import DiscoveryContext from "../DiscoveryContext";
import { filterRequests } from "../../ideaNiv/NewIdeaView";
import { Loader } from "../../../icons/x-symbol-svgrepo-com";
import DiscoveryZeroState from "../DiscoveryZeroState";
import Impact from "../../ideaNiv/impact";
import BusinessSources from "../../ideaNiv/right-bar/perspective/BusinessSources";
import EvidenceList from "../EvidenceList";
import TrendGraphDiscovery from "./trend-graph-discovery/TrendGraphDiscovery";
import EvidencesHeaderDiscovery from "./evidencesTopBar/EvidencesHeaderDiscovery";

const EvidenceTab = () => {

  const { activeFilters } = useContext(FiltersContext)
  const { ideasFiltersConfig }  = useContext(SyncContext);
  const { matches, requestsMatches, setDisplayedMatches, setDisplayedRequestsMatches, setMatchesTrend, setRequestsMatchesTrend, loadingEvidenceMatches, displayedMatches, displayedRequestsMatches, type } =
    useContext(DiscoveryContext);

  useEffect(() => {
    const filteredMatches = filterRequests(matches, activeFilters, ideasFiltersConfig);
    const filteredRequestsMatches = filterRequests(requestsMatches, activeFilters, ideasFiltersConfig);
    setDisplayedMatches(filteredMatches);
    setDisplayedRequestsMatches(filteredRequestsMatches);
    setMatchesTrend(filteredMatches);
    setRequestsMatchesTrend(filteredRequestsMatches);  
  }, [activeFilters, type, matches, requestsMatches]);


  return (
    <Box w="100%" bg='#F8F9FA' h='100%'>
      {loadingEvidenceMatches ? (
        <Box w="99%" pr="20px" pl="8px" mt={8}>
          <Stack align="center" mt={80}>
            <Box bg='rgba(92, 92, 235, 0.1)' w={353} p={24} sx={{borderRadius: 10}} mb={30}>
              <Text>
              Please wait while Bagel AI calculates matches for this product idea
              </Text>
            </Box>
            <Loader/>
          </Stack>
        </Box>
      ) : ((displayedMatches?.length + displayedRequestsMatches?.length) === 0 && activeFilters?.length === 0)? (
        <Box h='100%'>

          <DiscoveryZeroState text="There is no evidence matching your description. Please update the product idea to receive more relevant results." />
        </Box>
      ) : (
        <Box /* pl={6} */>
          <Flex
            sx={{ borderBottom: "1px solid #D8D8DB" }}
            pr={36}
            pb={6}
            pt={6}
            pl={6}
            w="100%"
            wrap='wrap'
            justify='space-between'
          >    
            <Box pl={2}>
              <Impact ideaPage={false}/>
            </Box> 
            
            <ScrollArea w='50%' mah={260} pb='md'>
              <BusinessSources ideaPage={false}/>
            </ScrollArea>
          
          </Flex>
          <Box   w='100%' pt='lg' pr={32} pl={26}>
            <TrendGraphDiscovery/> 
          </Box>
          <Box pl={6} pt={2}> 
            <EvidencesHeaderDiscovery />
            <EvidenceList />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EvidenceTab;
