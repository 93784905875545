import React, { useContext } from 'react';

import { Filter } from '../FiltersMenu';
import FiltersContext from '../context/FiltersContext';
import OperationSelect from '../OperationSelect';
import AddFilterBtn from '../AddFilterBtn';
import FilterFooter from '../FilterFooter';
import SyncContext from '../../../../context/SyncContext';
import { findFiltersByPath } from '../../../../context/helpers';

interface BooleanFilterProps {
  filters?: Filter;
  MenuType: "idea" | "evidence";
  // activeFilters: ActiveFilter[];
  // setActiveFilters: (obj: any) => void;
}

const BooleanFilter: React.FC<BooleanFilterProps> = ({
  MenuType
}) => {
  const {
    setActiveFilters,
    operator,
    setOperator,
    selectedParentFilter,
    childFiltersValue,
    rangeDates  } = useContext(FiltersContext);
  const _selectedParentFilter: string[] = selectedParentFilter?.split('.') || [''];
  const {ideasFiltersConfig } = useContext(SyncContext);

  const selectedFilter = (_selectedParentFilter && _selectedParentFilter.length === 2)
  //@ts-ignore
    ? ideasFiltersConfig[MenuType][_selectedParentFilter[0]]?.childFilters[_selectedParentFilter[1]]
    : ideasFiltersConfig[MenuType][_selectedParentFilter[0]]

  const selectedHasOperations = selectedFilter?.operations?.length > 0 || false;
  
  

  return (
    selectedParentFilter && <>
      {selectedHasOperations && (
        <OperationSelect
          operations={selectedFilter.operations}
          onChange={(value) => {
            setOperator((prev: any) => ({
              ...prev,
              [selectedParentFilter]: value,
            }));
          }}
        />
      )}

      <FilterFooter
        ApplyButton={
          <AddFilterBtn
            BtnText={`Apply`}
            selectedParentFilter={selectedParentFilter}
            childFiltersValue={childFiltersValue}
            disabled={
              !(operator && operator[selectedParentFilter])
            }
            onClick={() => {
              const parentFilterObj = findFiltersByPath(ideasFiltersConfig, `${MenuType}.${selectedParentFilter}`);
              
              setActiveFilters((prevFilters: any) => [
                {
                  ...parentFilterObj,
                  type: parentFilterObj?.type,
                  parent: selectedParentFilter,
                  operator: operator && operator[selectedParentFilter],
                  selected: rangeDates,
                  query: 0,
                },
                ...prevFilters.filter(
                  (prevF: any) => prevF.parent !== selectedParentFilter
                ),
              ]);
              // resetFilters();
            }}
          />
        }
      />

      
    </>
  );
};

export default BooleanFilter;
