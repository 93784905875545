import { Flex, Stack, Tooltip, Text } from "@mantine/core"
import { SourceIcon } from "../../../../../../utils/SourceIcon"

const CallTitle = ( { cxItem, origin } : { cxItem: any, origin: string } ) => {

  return (
    <Flex align="center" h={30} mx="xs"  w="100%" p='lg' mt={16}>
      {origin && (
        <Tooltip label={origin || "N/A"}>
          <Stack h={20} w={20} justify="center" align="center">
            <SourceIcon sourceName={origin?.toLowerCase() || "N/A"} />
          </Stack>
        </Tooltip>
      )}
      <Flex pl={25} align="center">
        <Text fz={17} maw={470} w={470} fw={600} lineClamp={1}>
          {`Call Transcript : ${cxItem?.title || 'N/A'}`} 
        </Text>
      </Flex>
    </Flex>
  )
}

export default CallTitle