import { useAuth0 } from "@auth0/auth0-react";
import {
  Menu,
  Text,
  TextInput,
  Box,
  ScrollArea,
  createStyles,
  ActionIcon,
  Tooltip,
  Paper,
  Collapse,
  Stack,
} from "@mantine/core";
import { IconSearch, IconPlus } from "@tabler/icons";
import { useEffect, useState } from "react";
import { addRequestsToIdea, searchAll, SearchResults } from "../../../Api";
import { itemPropsByType } from "../../../utils";
import { AttachForm } from "./AttachForm";
import { getFeedbackItemsFromSearchResults } from "../../../utils/feedbackItemFromSearchResults";
import { Loader } from "../../../icons/x-symbol-svgrepo-com";

const useStyles = createStyles((theme) => ({
  item: {
    border: "1px solid #000",
    "&[data-hovered]": {
      backgroundColor: "#f5f5f5",
      color: theme.white,
    },
  },
  tabs: {
    "&[data-active]": {
      borderColor: "#5B60E3",
      color: "#5B60E3",
      "&:hover": {
        borderColor: "#5B60E3",
      },
    },
  },
}));

export function AddRequest({
  requestId,
  idea,
  attachedRequestIds,
  addElementToRequestsList,
  requestsList,
  setRequestsList,
  onRequestUpdated,
  customizations,
}: {
  requestId: string;
  idea: any;
  attachedRequestIds: any[];
  addElementToRequestsList: (x: any) => void;
  requestsList: any[];
  setRequestsList: React.Dispatch<React.SetStateAction<any[]>>;
  onRequestUpdated: () => void;
  customizations: any;
}) {
  const { classes } = useStyles();
  const auth0 = useAuth0();
  const [query, setQuery] = useState<string>("");
  const [opened, setOpened] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [validateLoading, setValidateLoading] = useState<any>({});
  const [searchResults, setSearchResults] = useState<any[]>(
    requestsList || []
  );

  const [closeOnBlur, setCloseOnBlur] = useState<any>(true);
  const SALESFORCE = "salesforce"

  const handleAttach = (request: any) => {
    setValidateLoading((prev: any) => ({
      ...prev,
      [request.type === "manual" ? "manual" : request.itemId]: true,
    }));
    if (request.itemType === "account") {
      if (!request.origin) {//if this is a cxItem account and not a cxOrg
        // check if the request has salesforce id
        let hasSalesforce = request.sfId
        if (!hasSalesforce) {
          // support for sources on cxOrgs
          request?.sources?.forEach((source: any) => {
            if (source.origin === SALESFORCE) {
              hasSalesforce = true
            }
          })
        }
        if (hasSalesforce) {
          request.origin = SALESFORCE
        } else {
          request.origin = request?.sources?.length > 0 ? request?.sources[0]?.origin : undefined
        }
      }
    }

    const newRequest: any = {
      title: request.title,
      type: request.type,
      componentId: request.componentId,
      origin: request.origin,
      originId: request.originId,
      orgId: request.company?._id || request.orgId,
      contactId: request.contactId,
      internalContactId: request.internalContactId,
      priority: request.priority,
      committedDate: request.committed,
      text: request.text,
      textId: request.textItem?._id || null,
      businessName: request.itemType !== "conversation" ? 'PM Feedback' : null
    };
    if (request.type !== "manual" && request.type !== "internal") {
      newRequest.itemId = request._id;
    }
    addRequestsToIdea(auth0, requestId, newRequest)
      .then((res) => {
        setOpened({});
        setQuery("");
        setSearchResults(requestsList || []);
      })
      .catch(() => onRequestUpdated() )
      .finally(() => {
        setValidateLoading((prev: any) => ({
          ...prev,
          [request.type === "manual" ? "manual" : newRequest.itemId]: false,
          
        }));
        onRequestUpdated();
      });
    setOpen(false)
  };
  const doSearch = async () => {
    searchAll(query, auth0)
      .then((results: SearchResults) => {
        const merged = getFeedbackItemsFromSearchResults(results);
        const filtered = merged.filter(
          (item) =>
            attachedRequestIds.find(
              (feedback) => feedback.itemId === item._id
            ) === undefined
        );
        setSearchResults(filtered);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (query !== "") {
        doSearch();
      } else {
        setSearchResults(requestsList || []);
      }
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [query]);
  
  return (
    <Menu
      opened={open} 
      onChange={setOpen}
      classNames={classes}
      shadow="md"
      width={600}
      position="left"
      withArrow
      closeOnClickOutside={closeOnBlur}
      
    >
      <Menu.Target>
        <Tooltip label="Add new evidence" position="left">
          <ActionIcon
            mb="md"
            sx={{
              backgroundColor: "#5b60e3",
              color: "#fff",
              ":hover": {
                backgroundColor: "rgba(86, 91, 216, 1)",
                color: "#fff",
              },
            }}
          >
            <IconPlus size={20} />
          </ActionIcon>
        </Tooltip>
      </Menu.Target>

      <Menu.Dropdown p={0}>
        <Box mx="sm" mt="sm">
          <TextInput
            placeholder="Type here"
            icon={<IconSearch size={14} />}
            value={query}
            onChange={(event) => setQuery(event.currentTarget.value)}
          />
          <ScrollArea style={{ height: 300, overflowX: "unset"}}>
            <Box w="561px">
              {searchResults.length > 0 ? searchResults.map((item: any) => {
                const ItemProps: any = itemPropsByType[item.itemType];
                return (
                  <Paper
                    sx={{
                      border: "1px solid #E5E7EB",
                      ":hover": {
                        borderColor: "#5B60E3",
                      },
                      cursor: "pointer",
                    }}
                    my="xs"
                    key={item._id}
                  >
                    <Box
                      p="xs"
                      onClick={(e: any) => {
                        if (
                          !Array(...e.target.classList).includes(
                            "mantine-Spoiler-control"
                          )
                        ) {
                          setOpened((prevState: any) => ({
                            // ...prevState,
                            [item._id]: !prevState[item._id] || false,
                          }));
                        }
                      }}
                    >
                      <ItemProps item={item} query={query ?? ''}/>
                    </Box>
                    <Collapse in={opened[item._id]}>
                      <AttachForm
                        item={item}
                        idea={idea}
                        handleAttach={handleAttach}
                        loading={validateLoading[item._id]}
                        customizations={customizations}
                        query={query}
                        setCloseOnBlur={setCloseOnBlur}
                      />
                    </Collapse>
                  </Paper>
                );
              }) : query !== '' && 
              <Stack h={300} justify="center" align="center">
                <Loader height={74} width={74} />
                <Text align="center">
                Loading search results
                </Text>
              </Stack>}
            </Box>
          </ScrollArea>
        </Box>
        {searchResults.length > 0 && (
          <Box
            px="sm"
            py="xs"
            sx={{
              borderTop: "1px solid #e5e7eb",
            }}
          >
            <Text size="sm">{searchResults.length} results found</Text>
          </Box>
        )}
      </Menu.Dropdown>
    </Menu>
  );
}
