export function formatNumberValueIdeas(num: number, prefix = "$"): string {
  // Format the number with up to 2 decimal places
  const formatted = num.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 1 });
    
  // Remove trailing zeros from the decimal part
  
  const withDollarSign = `${prefix}${formatted.replace(/(\.\d*?[1-9])0+$/, '$1').replace(/\.$/, '')}`
  
  return withDollarSign;
}

export function formatMonetaryValueIdeas(num: number, prefix = "$") {
  if (num < 10000) {
    return formatNumberValueIdeas(num, prefix);
  } else if (num < 1000000) {
    return `${prefix}${Math.round(num / 1000)?.toLocaleString()}k`;
  } else {
    return `${prefix}${(num / 1000000)?.toFixed(1)?.toLocaleString()}M`;
  }
}
  
export function formatBigMonetaryValueIdeas(num: number, prefix = "$") {
  if (num <= 99000000) {
    return formatMonetaryValueIdeas(num, prefix);
  } else if (num > 99000000){
    return `${prefix}${Math.round(num / 1000000)?.toLocaleString()}M`;
  }
}

export const getNestedValue = (obj: Record<string, any>, path: string): any => {
  return path
    .split(".")
    .reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : null), obj);
};