import { Tooltip, Group, Text } from "@mantine/core";
import { getNestedFieldValue } from "../../../ideaNiv/helpers/calculateImpactResults";
import { OverflownText } from "../../../OverflownText";
import { SourceIcon } from "../../../../utils/SourceIcon";

export const DeliveryStatusCell: React.FC<any> = ({
  data,
  col,
  renderParams,
}) => {
  const value = getNestedFieldValue(data, col.accessor);
  const productItem = data.productItem;

  if (!productItem) {
    return <Text>{renderParams.default}</Text>;
  }

  return (
    <Group noWrap spacing={6} miw={180}>
      <Tooltip label={"Click to open"} withArrow>
        <Group
          noWrap
          spacing={6}
          sx={{
            "&:hover": {
              backgroundColor: "#F8F8FB",
              borderRadius: "4px",
            },
            padding: "2px 4px",
          }}
        >
          <SourceIcon sourceName={productItem.origin} width={14} height={14} />

          <Text
            component="a"
            href={productItem.link}
            target="_blank"
            rel="noopener noreferrer"
            lineClamp={1}
            size={"sm"}
            color="indigo"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {productItem.itemKey}
          </Text>
        </Group>
      </Tooltip>
      <OverflownText
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        overTooltip={renderParams.tooltip}
      >
        ({value})
      </OverflownText>
    </Group>
  );
};
