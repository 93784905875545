import React, { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import {
  Modal,
  TextInput,
  Select,
  Button,
  Collapse,
  Group,
  Divider,
  Paper,
  Code,
  Box
} from "@mantine/core";
import {
  AggregationOperation,
  ExtendedIdeaProperty,
} from "./types";
import { IconArrowBackUp, IconChevronDown, IconChevronUp, IconDeviceFloppy, IconPlus, IconX } from "@tabler/icons";
import { ObjectAttributeSelect } from "./ObjectAttributeSelect";

interface PropertyEditorFormProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (
    values: ExtendedIdeaProperty
  ) => void;
  initialValues?: ExtendedIdeaProperty; // Original property fields
  editProperty: ExtendedIdeaProperty | null
  parentGroup: {
    object: string;
    attribute: string;
  };
  loading:boolean
}

const operations: AggregationOperation[] = [
  "concat",
  "sum",
  "count",
  "avg",
  "min",
  "max",
];

export const PropertyEditorForm: React.FC<PropertyEditorFormProps> = ({
  opened,
  onClose,
  onSubmit,
  initialValues,
  editProperty,
  parentGroup,
  loading
}) => {
  const camelCaseRegex = /^[a-z][a-zA-Z0-9]*$/;
  // console.log({parentGroup, initialValues})

  const form = useForm<ExtendedIdeaProperty>({
    initialValues: {
      label: initialValues?.label ?? "",
      propertyName: initialValues?.propertyName ?? "",
      operation: initialValues?.operation ?? "sum",
      groupObject: initialValues?.groupObject,
      groupAttribute: initialValues?.groupAttribute,
      conditionObject: initialValues?.conditionObject,
      conditionObjectAttribute: initialValues?.conditionObjectAttribute,
      condition: initialValues?.condition,
      conditionValue: initialValues?.conditionValue,
      parentGroup: {
        object: parentGroup.object,
        attribute: parentGroup.attribute,
      },
      parentGroupInit: {
        object: parentGroup.object,
        attribute: parentGroup.attribute,
      },
    },
    validate: {
      propertyName: (value) =>
        camelCaseRegex.test(value)
          ? null
          : "Property name must be in camelCase format",
    },
  });

  const [groupByOpen, setGroupByOpen] = useState(false);
  const [conditionOpen, setConditionOpen] = useState(false);
  const [defaultValueOpen, setDefaultValueOpen] = useState(false);

  useEffect(() => {
    if (opened) {
      form.setValues({
        label: initialValues?.label ?? "",
        propertyName: initialValues?.propertyName ?? "",
        operation: initialValues?.operation ?? "sum",
        groupObject: initialValues?.groupObject,
        groupAttribute: initialValues?.groupAttribute,
        conditionObject: initialValues?.conditionObject,
        conditionObjectAttribute: initialValues?.conditionObjectAttribute,
        condition: initialValues?.condition,
        conditionValue: initialValues?.conditionValue,
        parentGroup: {
          object: parentGroup.object,
          attribute: parentGroup.attribute,
        },
        parentGroupInit: {
          object: parentGroup.object,
          attribute: parentGroup.attribute,
        },
      });
      // also close any open collapses:
      if( initialValues?.conditionValue ){
        setConditionOpen(true);
      }else{
        setConditionOpen(false);
      }

      if(initialValues?.groupObject || initialValues?.groupAttribute){
        setGroupByOpen(true);
      }else{
        setGroupByOpen(false);
      }

      if(initialValues?.fieldDefaultValue && initialValues?.fieldDefaultValue !== ''){
        setDefaultValueOpen(true);
      }else{
        setDefaultValueOpen(false);
      }

    }
  }, [opened, initialValues, parentGroup]);

  return (
    <Modal opened={opened} onClose={onClose} title={`${editProperty ? `Edit - ${editProperty.label}` : 'Add new property'}`}>
      <Paper>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <ObjectAttributeSelect
            objectValue={form.values.parentGroup.object || ""}
            onObjectChange={(val) =>
              form.setFieldValue("parentGroup.object", val)
            }
            attributeValue={form.values.parentGroup.attribute || ""}
            onAttributeChange={(val) =>
              form.setFieldValue("parentGroup.attribute", val)
            }
            objectPlaceholder="(Optional) e.g. myObject"
            attributePlaceholder="(Optional) e.g. myAttribute"
            disabled
          />

          <TextInput
            label="Label"
            {...form.getInputProps("label")}
            required
            mt="md"
          />
          <TextInput
            label="Target Property Name"
            {...form.getInputProps("propertyName")}
            required
            mt="md"
          />
          <Select
            label="Operation"
            data={operations}
            {...form.getInputProps("operation")}
            mt="md"
          />

          <Divider mt="sm" label="Optional Configurations" />

          <Box mt="xs">
            <Group position="apart">
              <Button
                variant="subtle"
                compact
                color="indigo"
                mb={3}
                onClick={() => {setConditionOpen((o) => !o) 
                  console.log({values: form.values})
                }}
                rightIcon={
                  conditionOpen ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />
                }
              >
                {conditionOpen ? "Hide Condition" : "Add Condition"}
              </Button>
              {conditionOpen && <Button
                variant="subtle"
                compact
                color="indigo"
                mb={3}
                onClick={() => form.setValues({
                  condition: null,
                  conditionValue: ''
                })}
                rightIcon={
                  <IconX size={14} />
                }
              >
                Clear Condition
              </Button>}
            </Group>
            <Collapse in={conditionOpen} sx={{
              border:"1px solid #cdd4da",
              borderRadius: 4,
              padding: 5
            }}>
              <Group grow>
                {/* Replace the old Group + Select code with your ObjectAttributeSelect */}
                {/* <ObjectAttributeSelect
                objectValue={form.values.conditionObject || ""}
                onObjectChange={(val) =>
                  form.setFieldValue("conditionObject", val)
                }
                attributeValue={form.values.conditionObjectAttribute || ""}
                onAttributeChange={(val) =>
                  form.setFieldValue("conditionObjectAttribute", val)
                }
                objectPlaceholder="Select object"
                attributePlaceholder="Select attribute"
              /> */}
                <Code>{`${form.values.parentGroup.object}.${form.values.parentGroup.attribute}`}</Code>
              </Group>
              <Group grow mt="sm">
                <Select
                  data={[
                    { label: "equals", value: "$eq" },
                    { label: "not equals", value: "$ne" },
                    { label: "greater than", value: "$gte" },
                    { label: "less than", value: "$lte" },
                  ]}
                  {...form.getInputProps("condition")}
                  placeholder="Select condition"
                />
                <TextInput
                  placeholder="Enter value"
                  {...form.getInputProps("conditionValue")}
                />
              </Group>
            </Collapse>
          </Box>

          <Box mt="xs">
            <Group position="apart">
              <Button
                variant="subtle"
                compact
                color="indigo"
                mb={3}
                onClick={() => setGroupByOpen((o) => !o)}
                rightIcon={
                  groupByOpen ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />
                }
              >
                {groupByOpen ? "Hide GroupBy" : "Add GroupBy"}
              </Button>
              {groupByOpen && <Button
                variant="subtle"
                compact
                color="indigo"
                mb={3}
                onClick={() => form.setValues({
                  groupObject: null,
                  groupAttribute: null
                })}
                rightIcon={
                  <IconX size={14} />
                }
              >
                Clear GroupBy
              </Button>}
            </Group>
            <Collapse in={groupByOpen} sx={{
              border:"1px solid #cdd4da",
              borderRadius: 4,
              padding: 5
            }}>
              <ObjectAttributeSelect
                objectValue={form.values.groupObject || ""}
                onObjectChange={(val) => form.setFieldValue("groupObject", val)}
                attributeValue={form.values.groupAttribute || ""}
                onAttributeChange={(val) =>
                  form.setFieldValue("groupAttribute", val)
                }
                objectPlaceholder="(Optional) e.g. myObject"
                attributePlaceholder="(Optional) e.g. myAttribute"
              />
            </Collapse>
          </Box>

          <Box mt="xs">
            <Group position="apart">
              <Button
                variant="subtle"
                compact
                color="indigo"
                mb={3}
                onClick={() => setDefaultValueOpen((o) => !o)}
                rightIcon={
                  defaultValueOpen ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />
                }
              >
                {defaultValueOpen ? "Hide Default Value" : "Add Default Value"}
              </Button>
              {defaultValueOpen && <Button
                variant="subtle"
                compact
                color="indigo"
                mb={3}
                onClick={() => form.setValues({
                  fieldDefaultValue: ''
                })}
                rightIcon={
                  <IconX size={14} />
                }
              >
                Clear default value
              </Button>}
            </Group>
            <Collapse in={defaultValueOpen} sx={{
              border:"1px solid #cdd4da",
              borderRadius: 4,
              padding: 5
            }}>
              <TextInput
                placeholder="Field Default Value"
                {...form.getInputProps("fieldDefaultValue")}
              />
            </Collapse>
          </Box>
          

          <Button loading={loading} type="submit" mt="lg" fullWidth color="indigo" rightIcon={editProperty ? <IconDeviceFloppy size={16}/> : <IconPlus size={16}/>}>
            {`${editProperty ? `Save Property` : 'Create new property'}`}
          </Button>
        </form>
      </Paper>
    </Modal>
  );
};
