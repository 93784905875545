export const TAG_COLORS = {
  gray: '#585C68',
  pink: '#B11254',
  purple: '#A21DAF',
  violet: '#7A1CCC',
  blue: '#015C99',
  teal: '#096860',
  green: '#3A6C02',
  orange: '#985602',
  red: '#BF3A0C',
};
  