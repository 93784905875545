
import Search from "./Search"
import { HeaderSearchIcon } from "../../../icons/x-symbol-svgrepo-com"
import { useState } from "react";

const HeaderSearch = ( { hovered } : { hovered: boolean } ) => {


  const [query, setQuery] = useState<string>("");

  return (
    <>
      { (hovered || query !== '') ? <Search  query={query} setQuery={setQuery}/> : <HeaderSearchIcon /> }
    </>
  )
}

export default HeaderSearch