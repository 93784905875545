import { Box, Stack } from "@mantine/core";
import { IconArrowDown, IconArrowUp } from "@tabler/icons";
import { useContext } from "react";
import IdeasContext from "../IdeasContext";

export const FlippedArrow = () => {
  const { sortStatus } = useContext(IdeasContext);
  /* console.log(sortStatus) */
  const direction = sortStatus?.directionStatus /* || sortStatus?.direction || 'asc' */;
  
  return (
    <Stack>
      {direction === 'asc' ? (
        <IconArrowUp size={14} strokeWidth={1.7} style={{ color: 'rgba(10, 10, 10, 1)', /* marginTop: 4.6 */ }} />
      ) : (
        <IconArrowDown size={14} strokeWidth={1.7} style={{ color: 'rgba(10, 10, 10, 1)', /* marginTop: 4.6 */ }} />
      )}
    </Stack>
  );
};