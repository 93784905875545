import { useContext } from 'react'
import IdeaContext from '../../IdeaContext';
import { Box, Text, Flex, Menu, Stack} from '@mantine/core';
import { IconCaretDown } from '@tabler/icons';
import PerspectiveList from './PerspectiveList';
import SyncContext from "../../../../context/SyncContext";
import DiscoveryContext from '../../../Discovery/DiscoveryContext';

const BusinessSources = ({ideaPage}:{ideaPage: boolean}) => {


  const ContextToUse: React.Context<any> = 
  ideaPage ? IdeaContext : DiscoveryContext;


  const {
    type,
    setType
  } = useContext(ContextToUse);

  const { perspectiveConfigurations } = useContext(SyncContext);

  return (
    <Stack pl={38} pr={85.4} align='stretch'>
      <Box mb={ ideaPage ? 16 : 0 } mt={26} py={2}>
        <Flex>
          <Text fz="14px" fw={600} mr='4px'>
            Perspective:
          </Text>
        
          <Menu position='bottom-start' withinPortal>
            <Menu.Target>
              <Flex sx={{cursor: 'pointer'}}>
              
                <Text fz="14px" mr='4px'>
                  {perspectiveConfigurations?.find((i: any) => i.key == type)?.label}
                </Text>
                <IconCaretDown fill='black' size={14}/>
              </Flex>
           
            </Menu.Target>
            <Menu.Dropdown>
              {perspectiveConfigurations.map((perspective:any) => (
                <Menu.Item
                  key={perspective.key}
                  onClick={()=>{
                    setType(perspective.key)
                  }}
                >
                  {perspective.label}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>  
        </Flex>   
      </Box>
      <PerspectiveList ideaPage={ideaPage}/>
    </Stack>
  );
};

export default BusinessSources