import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import {Box, Button, Grid, Paper, Select, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { JiraIntegrationUpdate, updateJiraIntegration} from "../../../../api/integrations";
import JiraIcon from "../../../../utils/icons/JiraIcon";

export const SetupSolutions = (props: {
  integration: any;
  customization?: any;
  variant?: "wizard" | "settings";
  reloadIntegration: () => void;
}) => {
  const auth0 = useAuth0();
  const form = useForm<{
    component?: string,
    subComponent?: string,
  }>({
    initialValues: {
      component: props.customization?.fields?.component?.name || undefined,
      subComponent: props.customization?.fields?.subComponent?.name|| undefined
    },

    // validate: {
    //   component: (value) =>
    //     value !== null
    //       ? null
    //       : "please select the list for ideas",
    // },
  });
  const [loading, setLoading] = useState<boolean>(false);

  const isWizard = !props.variant || props.variant === "wizard";
  const saveSolutionsClicked = () => {
    if (form.isValid()) {
      setLoading(true);
      console.log(form.values);
      const update: JiraIntegrationUpdate = {
        solutionFields: { ...form.values }
      };

      if (isWizard) {
        update.setupStep = 3;
      }
      updateJiraIntegration(props.integration._id, update, auth0)
        .then(() => {
          props.reloadIntegration();
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
  };

  const solutionListId = props.integration.solutionListId
  const listFields = (props.integration.listsMeta && props.integration.listsMeta[solutionListId] && props.integration.listsMeta[solutionListId].fieldsMap) ? Object.values(props.integration.listsMeta[solutionListId].fieldsMap) : []
  const fieldsSection = (
    <form onSubmit={form.onSubmit(saveSolutionsClicked)}>
      <Box>
        <Text mt="20px" color={"rgba(22, 24, 50, 0.8)"}>
          Select product area field to use
        </Text>
        <Grid mt={"15px"}>
          <Grid.Col span={"content"} pl={"25px"}>
            <JiraIcon width={"30"} height={"auto"}/>
          </Grid.Col>
          <Grid.Col span={"content"}>
            <Select
              ml={"md"}
              placeholder="Select product area field"
              searchable
              clearable
              data={listFields.map((field: any) => { return {label: field.name, value: field.id}})}
              {...form.getInputProps("component")}
            />
          </Grid.Col>
        </Grid>
      </Box>
      <Box>
        <Text mt="20px" color={"rgba(22, 24, 50, 0.8)"}>
          Select sub-product area field to use
        </Text>
        <Grid mt={"15px"}>
          <Grid.Col span={"content"} pl={"25px"}>
            <JiraIcon width={"30"} height={"auto"}/>
          </Grid.Col>
          <Grid.Col span={"content"}>
            <Select
              ml={"md"}
              placeholder="Select sub-product area area field"
              searchable
              clearable
              data={listFields.map((field: any) => { return {label: field.name, value: field.id}})}
              {...form.getInputProps("subComponent")}
            />
          </Grid.Col>
        </Grid>
      </Box>
      <Button
        mt={"md"}
        ml={isWizard ? "0" : "lg"}
        loading={loading}
        type="submit"
      >
        Save
      </Button>
    </form>
  )


  return (
    <>
      {isWizard ? (
        fieldsSection
      ) : (
        <Paper mt={"44px"} shadow={"lg"} withBorder>
          <Grid p={0} m={0}>
            <Grid.Col span={2} p={"xl"}>
              <Text weight={700} color={"#5C5CEA"} size={"md"}>
                Mapping Rules
              </Text>
              <Text size={"sm"} mt={"sm"} mb={"sm"}>
                Configure field mapping rules
              </Text>
            </Grid.Col>
            <Grid.Col
              span={"content"}
              p={"xl"}
              sx={{borderLeft: "1px solid rgb(222, 226, 230)"}}
            >
              {fieldsSection}
            </Grid.Col>
          </Grid>
        </Paper>
      )}
    </>
  )
}