import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Modal,
  ScrollArea,
  Text,
  Tooltip
} from "@mantine/core";
import { IconArrowBackUp, IconCheck } from "@tabler/icons";
import {ProductArea} from "../../../api/product-areas";

function AreaReorderModal({
  openAreaReorderModal,
  setOpenAreaReorderModal,
  handleToggleIndentation,
  setChildItem,
  childItem,
  elements,
  element,
}: any) {
  const [selectedArea, setSelectedArea] = useState<{
    id: string;
    position: "above" | "below" | "inside";
    name: string;
    parent: boolean;
  } | null>(null);

  const [hoveredLine, setHoveredLine] = useState<{
    id: string;
    position: "above" | "below" | "inside";
  } | null>(null);

  const filterCurrentArea = (productAreasList: any[]) => {
    return productAreasList.filter((a: any) => {
      return (
        a._id !== element._id
      );
    })

  }

  const [filteredAreas, setFilteredAreas] = useState(
    filterCurrentArea(elements)
  );


  const handlePositionSelect = useCallback(
    (id: string, position: "above" | "below" | "inside", name: string, parent: boolean) => {
      setSelectedArea({ id, position, name, parent });
    },
    []
  );

  const handleClose = () => {
    setSelectedArea(null);
    setHoveredLine(null);
    setOpenAreaReorderModal(false);
  };

  useEffect(() => {
    if (openAreaReorderModal) {
      setSelectedArea(null);
      setHoveredLine(null);
    }
  }, [openAreaReorderModal]);
  

  const handleApply = () => {
    if (selectedArea) {
      let moveAfter = "";
      let parentId;
      if (selectedArea.parent) { 
        moveAfter = selectedArea.id
        parentId = ""; 
      } else {
        for (let i = 0; i < filteredAreas.length; i++) {
          const area = filteredAreas[i];
          if(!area.childComponents.length && selectedArea.id == area._id){
            parentId = area._id; 
          } else {
            for (let j = 0; j < area.childComponents.length; j++) {
              const subArea = area.childComponents[j];
              if (subArea._id === selectedArea.id) {
                setChildItem(false)
                childItem = false
                parentId = area._id;
                moveAfter = selectedArea.id
                break;
              }
            }
          }
          if (parentId) break; 
        }
      }
      handleToggleIndentation(childItem, element._id, parentId, moveAfter);
    }
    handleClose();
  };

  const sortedSelectableAreas = React.useMemo(() => {
    return filteredAreas.flatMap((area: any) => [
      { id: area._id, position: "inside", name: area.name, parent: false },  
      ...area.childComponents.map((subArea: any) => ({
        id: subArea._id,
        position: "below",
        name: subArea.name,
        parent: false,
      })),  
      { id: area._id, position: "below", name: area.name, parent: true },  
    ]);
  }, [filteredAreas]);
  
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const currentIndex = sortedSelectableAreas.findIndex(
        (a: any) => a.id === selectedArea?.id && a.position === selectedArea?.position
      );
  
      if (e.key === "ArrowDown") {
        let nextIndex = currentIndex + 1;
        if (nextIndex >= sortedSelectableAreas.length) nextIndex = 0; 
        const nextArea = sortedSelectableAreas[nextIndex];
        handlePositionSelect(nextArea.id, nextArea.position, nextArea.name, nextArea.parent);
      }
  
      if (e.key === "ArrowUp") {
        let prevIndex = currentIndex - 1;
        if (prevIndex < 0) prevIndex = sortedSelectableAreas.length - 1; 
        const prevArea = sortedSelectableAreas[prevIndex];
        handlePositionSelect(prevArea.id, prevArea.position, prevArea.name, prevArea.parent);
      }
    };
  
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedArea, sortedSelectableAreas, handlePositionSelect]);
  

  const isApplyDisabled = (element.childComponents?.length > 0  && selectedArea?.parent === false)

  return (
    <Modal
      opened={openAreaReorderModal}
      onClose={handleClose}
      title={
        <Group position="apart" py={4}>
          <Text fw={600} size={20} color="#212529">
            Move <Text span>{element.name}</Text>
          </Text>
        </Group>
      }
      sx={{
        ".mantine-Paper-root ":{
          minWidth: "496px",
          minHeight: "596px",
        },
        ".mantine-Modal-header ":{
          height: "76px",
        },
        ".mantine-Modal-inner":{
          minHeight: "100%",
          maxHeight: "none",
          overflowY: "scroll",
        }
      }}
    >
      <Box  mx="-md" mt="4px" mb="8px" bg="#F8F9FA">
        <ScrollArea style={{ height: "70vh", background: "#F8F9FA" }}>
          <Divider mb="8px" size={2} color="#D8D8DB" />
          {filteredAreas.map((area: any, index: any) => (
            <Box key={index}>
              <Box px="md">
                <Text fw={600} size={14} style={{ lineHeight: 1, marginTop: '12px' }}>
                  {area.name}
                </Text>

                <Box
                  pl="2rem"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: element.childComponents?.length > 0 ? "not-allowed" : "pointer",
                    width: "230px",
                  }}
                  onMouseEnter={() => setHoveredLine({ id: area._id, position: "inside" })}
                  onMouseLeave={() => setHoveredLine(null)}
                  onClick={() => {
                    if (element.childComponents?.length > 0) return; 
                    handlePositionSelect(area._id, "inside", area.name, false);
                  }}
                >
                  <Box
                    style={{
                      width: "11px",
                      height: "11px",
                      borderRadius: "50%",
                      backgroundColor:
                        selectedArea?.id === area._id && selectedArea?.position === "inside" && element.childComponents?.length > 0
                          ? "#D8D8DB"  
                          : hoveredLine?.id === area._id && hoveredLine?.position === "inside"
                            ? element.childComponents?.length > 0
                              ? "#D8D8DB"  
                              : "#5C5CEB"  
                            : selectedArea?.id === area._id && selectedArea?.position === "inside"
                              ? "#5C5CEB"  
                              : "transparent",
                      marginRight: "-4px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  />
                  <Box
                    style={{
                      height: "2px",
                      flexGrow: 1,
                      borderRadius: "4px",
                      backgroundColor:
                        selectedArea?.id === area._id && selectedArea?.position === "inside" && element.childComponents?.length > 0
                          ? "#D8D8DB"
                          : hoveredLine?.id === area._id && hoveredLine?.position === "inside"
                            ? element.childComponents?.length > 0
                              ? "#D8D8DB"  
                              : "#5C5CEB"  
                            : selectedArea?.id === area._id && selectedArea?.position === "inside"
                              ? "#5C5CEB"  
                              : "transparent",
                    }}
                  />
                  {element.childComponents?.length > 0 && hoveredLine?.id === area._id && hoveredLine?.position === "inside" && (
                    <Tooltip label="This area cannot be convert to sub-area as it has sub-areas itself." position="top" withArrow sx={(theme) => ({
                      wordWrap: "break-word", 
                      whiteSpace: "normal",   
                      maxWidth: 300,          
                    })}>
                      <Box
                        style={{
                          backgroundColor: "#D8D8DB",
                          width: "100%",
                          height: "2px", 
                          borderRadius: "4px",
                          display: "inline-block",
                          position: "relative",
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>

                {filterCurrentArea(area.childComponents).map((subArea: any, subIndex: any) => (
                  <Box key={subIndex} pl="2rem">
                    <Text size={14} style={{ lineHeight: 1 }}>{subArea.name}</Text>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: element.childComponents?.length > 0 ? "not-allowed" : "pointer",
                        width: "200px",
                      }}
                      onMouseEnter={() => setHoveredLine({ id: subArea._id, position: "below" })}
                      onMouseLeave={() => setHoveredLine(null)}
                      onClick={() => handlePositionSelect(subArea._id, "below", subArea.name, false)}
                    >
                      <Box
                        style={{
                          width: "11px",
                          height: "11px",
                          borderRadius: "50%",
                          backgroundColor:
                          selectedArea?.id === subArea._id && selectedArea?.position === "below" && element.childComponents?.length > 0
                            ? "#D8D8DB"  
                            : hoveredLine?.id === subArea._id && hoveredLine?.position === "below"
                              ? element.childComponents?.length > 0
                                ? "#D8D8DB"  
                                : "#5C5CEB"
                              : selectedArea?.id === subArea._id && selectedArea?.position === "below"
                                ? "#5C5CEB"  
                                : "transparent",
                          marginRight: "-4px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      />
                      <Box
                        style={{
                          height: "2px",
                          marginTop: "5px",marginBottom: "5px",
                          flexGrow: 1,
                          borderRadius: "4px",
                          backgroundColor:
                            selectedArea?.id === subArea._id && selectedArea?.position === "below" && element.childComponents?.length > 0
                              ? "#D8D8DB"  
                              : hoveredLine?.id === subArea._id && hoveredLine?.position === "below"
                                ? element.childComponents?.length > 0
                                  ? "#D8D8DB"  
                                  : "#5C5CEB"  
                                : selectedArea?.id === subArea._id && selectedArea?.position === "below"
                                  ? "#5C5CEB"  
                                  : "transparent",  
                        }}
                      />
                      {element.childComponents?.length > 0 && hoveredLine?.id === subArea._id && hoveredLine?.position === "below" && (
                        <Tooltip
                          label="This area cannot be convert to sub-area as it has sub-areas itself."
                          position="top"
                          withArrow
                          sx={(theme) => ({
                            wordWrap: "break-word", 
                            whiteSpace: "normal",   
                            maxWidth: 300,          
                          })}
                        >
                          <Box
                            style={{
                              backgroundColor: "#D8D8DB",
                              width: "100%",
                              height: "2px",
                              borderRadius: "4px",
                              display: "inline-block",
                              position: "relative",
                            }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                ))}

                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "200px",
                  }}
                  onMouseEnter={() => setHoveredLine({ id: area._id, position: "below" })}
                  onMouseLeave={() => setHoveredLine(null)}
                  onClick={() => handlePositionSelect(area._id, "below", area.name, true)}
                >
                  <Box
                    style={{
                      width: "11px",
                      height: "11px",
                      borderRadius: "50%",
                      backgroundColor:
                        selectedArea?.id === area._id && selectedArea?.position === "below"
                          ? "#5C5CEB"
                          : hoveredLine?.id === area._id && hoveredLine?.position === "below"
                            ? "#5C5CEB"
                            : "transparent",
                      marginRight: "-4px"
                    }}
                  />
                  <Box
                    style={{
                      height: "2px",
                      flexGrow: 1,
                      borderRadius: "4px",
                      backgroundColor:
                        selectedArea?.id === area._id && selectedArea?.position === "below"
                          ? "#5C5CEB"
                          : hoveredLine?.id === area._id && hoveredLine?.position === "below"
                            ? "#5C5CEB"
                            : "transparent",
                    }}
                  />
                </Box>
              </Box>
              <Divider mt="5px" mb="5px" color="#D8D8DB" />
            </Box>
          ))}
        </ScrollArea>
      </Box>
      <Flex
        justify="space-between"
        mx="-md"
        my="-md"
        pt={8}
        pb={8}
        px={16}
        bg="#F8F9FA"
        style={{ borderTop: "1px solid #D8D8DB", position: "relative" }}
      >
        <Button
          variant="subtle"
          color="#5C5CEB"
          style={{ paddingLeft: 5 }}
          onClick={() => setOpenAreaReorderModal(false)}
        >
          <IconArrowBackUp color="#5C5CEB" size={16} />
          <Text color="#5C5CEB" size="12px" mx={4}>
            Cancel changes
          </Text>
        </Button>
        {isApplyDisabled ? (
          <Tooltip
            label="This area cannot be convert to sub-area as it has sub-areas itself."
            position="top"
            withArrow
            sx={(theme) => ({
              wordWrap: "break-word", 
              whiteSpace: "normal",   
              maxWidth: 300,          
            })}
          >
            <div>
              <Button
                onClick={handleApply}
                disabled={selectedArea === null || isApplyDisabled}
                style={{
                  background: "#5C5CEB",
                  borderRadius: 4,
                  width: 69,
                  height: 32,
                  padding: 8,
                }}
              >
                <IconCheck size={16} />
                <Text size={12}>Apply</Text>
              </Button>
            </div>
          </Tooltip>
        ) : (
          <Button
            onClick={handleApply}
            disabled={selectedArea === null || isApplyDisabled}
            style={{
              background: "#5C5CEB",
              borderRadius: 4,
              border: "1px",
              width: 69,
              height: 32,
              padding: 8,
            }}
          >
            <IconCheck size={16} />
            <Text size={12}>Apply</Text>
          </Button>
        )}

      </Flex>
    </Modal>
  );
}

export default AreaReorderModal;