import {
  Autocomplete,
  Avatar,
  Button,
  Group,
  Text,
  TextInput,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import React, { forwardRef, useContext } from "react";
import { SourceIcon } from "../../../utils/SourceIcon";
import { useForm } from "@mantine/form";
import { useAuth0 } from "@auth0/auth0-react";
import { showNotification } from "@mantine/notifications";
import SyncContext from "../../../context/SyncContext";
import { createProductArea } from "../../../api/product-areas";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  picture: string;
  name: string;
  email: string;
}

interface DomainItemProps extends React.ComponentPropsWithoutRef<"div"> {
  name: string;
  value: string;
}

function AddAreaRow({
  members,
  setAddIsOpen,
  setAddFormContent,
  domains
}: {
  members: any;
  setAddIsOpen: any;
  setAddFormContent: any;
  domains: any
}) {

  const { allComponents } = useContext(SyncContext);
  const auth0 = useAuth0();
  const saveNotification = () => {
    showNotification({
      title: "Your settings have been saved!",
      message: "",
      icon: <SourceIcon sourceName="tick-circle" />,
      autoClose: 1200
    });
  };

  const form = useForm({
    initialValues: {
      name: "",
      description: "",
      ownerId: "",
      ownerName: "",
      domain: ""
    },
  });

  const sendSavedData = async (updateData: any) => {
    try {
      const index = allComponents[allComponents.length-1]?.index
      updateData.index = index ? index + 1 : 0

      await createProductArea(auth0, updateData).then(() => {
        setAddFormContent(form.values);
        saveNotification();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleSave = (formValues: any) => {
    sendSavedData(formValues);
    setAddIsOpen(false);
  };

  const handleRemoveAddRow = (formValues: any) => {
    if (formValues.name.length === 0 && formValues.description.length === 0) {
      setAddIsOpen(false);
    } else {
      if (
        confirm("Are you sure you want to delete this product area?") === true
      ) {
        form.reset();
        setAddIsOpen(false);
      }
    }
  };

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ picture, name, email, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        {members && (
          <Group noWrap>
            <Avatar src={picture} radius="xl" />

            <div>
              <Text size="sm">{name}</Text>
              <Text size="xs" opacity={0.65}>
                {email}
              </Text>
            </div>
          </Group>
        )}
      </div>
    )
  );

  const DomainItem = forwardRef<HTMLDivElement, DomainItemProps>(
    ({ name, value, ...others }: DomainItemProps, ref) => (
      <div ref={ref} {...others}>
        {members && (
          <Group noWrap>
            <div>
              <Text size="sm">{name}</Text>
              <Text size="xs" opacity={0.65}>
                {value}
              </Text>
            </div>
          </Group>
        )}
      </div>
    )
  );

  return (
    <tr key={"AddAreaRow"}>
      <td style={{ width: "30px" }}></td>
      <td
        width={"150px"}
        style={{
          textAlign: "left",
        }}
        {...form.getInputProps("name")}
      >
        <TextInput
          {...form.getInputProps("name")}
          placeholder="Add product area here..."
          icon={
            <Text fz="lg" c="red">
              *
            </Text>
          }
        />
      </td>
      <td width={"150px"} style={{ textAlign: "left" }}>
        <TextInput
          {...form.getInputProps("description")}
          placeholder="Add description here..."
        />
      </td>
      <td width={"150px"}>
        {members && members.length > 0 && (
          <Autocomplete
            placeholder="Select owner"
            itemComponent={SelectItem}
            value={form.values.ownerName || ""}
            onChange={(val) => {
              form.setFieldValue("ownerName", val);
            }}
            onItemSubmit={(item) => {
              form.setFieldValue("ownerId", item._id);
              form.setFieldValue("ownerName", item.name);
            }}
            data={members}
            nothingFound={
              <Button fullWidth disabled leftIcon={<IconPlus size={18} />}>
                Invite member
              </Button>
            }
            filter={(value, user) =>
              (user.name &&
                user.name.toLowerCase().includes(value.toLowerCase().trim())) ||
              (user.email &&
                user.email.toLowerCase().includes(value.toLowerCase().trim()))
            }
            error={form.errors.ownerName || form.errors.ownerId}
          />
        )}
      </td>
      <td width={"150px"}>
        {domains && domains.length > 0 && (
          <Autocomplete
            placeholder="Select domain"
            itemComponent={DomainItem}
            value={form.values.domain || ""}
            onChange={(val) => {
              form.setFieldValue("domain", val);
            }}
            onItemSubmit={(item) => {
              form.setFieldValue("domain", item.name);
              // updateDomain(form.values, element._id, item.name);
            }}
            data={domains}
            nothingFound={<Text>No domains were found...</Text>}
            filter={(value, item) =>
              (item.name &&
                item.name.toLowerCase().includes(value.toLowerCase().trim())) ||
              (item.value &&
                item.value.toLowerCase().includes(value.toLowerCase().trim()))
            }
            error={form.errors.ownerName || form.errors.ownerId}
          />
        )}
      </td>
      <td width={"60px"}>
        <Button
          variant="subtle"
          color="dark"
          aria-label="Save"
          onClick={() => handleSave(form.values)}
          disabled={form.values.name.length > 0 ? false : true}
        >
          Save
        </Button>

        <Button
          variant="subtle"
          color="dark"
          leftIcon={<SourceIcon sourceName={"bin"} />}
          aria-label="Remove"
          onClick={() => handleRemoveAddRow(form.values)}
        >
          Remove
        </Button>
      </td>
    </tr>
  );
}

export default AddAreaRow;
