// src/components/settings/PropertyManager/PropertyGroupEditor.tsx

import React, { useState } from "react";
import {
  Button,
  Card,
  Text,
  Group,
  ActionIcon,
  Paper,
  Box,
} from "@mantine/core";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons";
import { PropertyEditorForm } from "./PropertyEditorForm";
import { ExtendedIdeaProperty, PropertyGroup } from "./types";
import { ObjectAttributeSelect } from "./ObjectAttributeSelect";
import { useCustomization } from "./_utils/CustomizationContext";
import { openConfirmModal } from "@mantine/modals";

interface PropertyGroupEditorProps {
  groupId: string;
  group: PropertyGroup;
}

export const PropertyGroupEditor: React.FC<PropertyGroupEditorProps> = ({
  groupId,
  group,
}) => {
  const { deleteGroup, addPropertyToGroup, updateProperty, deleteProperty, updateGroupKey, loading } = useCustomization();
  const [formOpen, setFormOpen] = useState(false);
  const [editProperty, setEditProperty] = useState<ExtendedIdeaProperty | null>(null);
  const [actionOnPropertyName, setActionOnPropertyName] = useState<string | null>(null);

  // If group has at least one property, disable editing object/attribute
  const isGroupLocked = group.properties.length > 0;

  const handleSubmitProperty = async (property: ExtendedIdeaProperty) => {
    setActionOnPropertyName(property.propertyName)
    if (editProperty) {
      // Update existing property
      await updateProperty(editProperty.propertyName, property);
    } else {
      // Add new property
      await addPropertyToGroup(groupId, property);
    }

    // Close modal & reset
    setFormOpen(false);
    setEditProperty(null);
  };

  const handleEditProperty = (property: ExtendedIdeaProperty) => {
    setActionOnPropertyName(property.propertyName)
    setEditProperty(property);
    setFormOpen(true);
  };

  const handleDeleteProperty = async (propertyName: string) => {
    openConfirmModal({
      title: "Confirm Property Delete",
      children: (
        <Text size="sm">
          Are you sure you want to Delete Property - {propertyName}?
        </Text>
      ),
      labels: { confirm: `Yes, Delete ${propertyName}`, cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        setActionOnPropertyName(propertyName)
        await deleteProperty(propertyName)
      },
      onCancel: () => console.log("Delete cancelled by user."),
      centered: true
    });
  };

  const handleDeleteGroup = async () => {
    await deleteGroup(groupId);
  };

  return (
    <Paper p={0} m={0} >
      {/* Header bar with Object/Attribute and the group action icons */}
      <Group
        sx={{ borderBottom: "1px solid #dee2e6" }}
        position="apart"
        p="xs"
        bg="#f6f6f6"
        noWrap
      >
        <ObjectAttributeSelect
          objectValue={group.object}
          onObjectChange={(object) => {
            updateGroupKey(groupId, object, group.attribute)
          }}
          attributeValue={group.attribute}
          onAttributeChange={(attribute) => {
            updateGroupKey(groupId, group.object, attribute)
          }}
          disabled={isGroupLocked}
        />
        <Group>
          <ActionIcon disabled={isGroupLocked} color="red" onClick={handleDeleteGroup}>
            <IconTrash />
          </ActionIcon>
        </Group>
      </Group>
      <Box m="sm">
        {/* List of existing properties */}
        {group.properties.map((property) => (
          <Card shadow="sm" p="sm" withBorder key={property.propertyName} mt="xs">
            <Group position="apart">
              <div>
                <Text>{property.label}</Text>
                <Text size="xs" color="dimmed">
                  {property.propertyName} ({property.operation})
                </Text>
              </div>
              <Group noWrap spacing={4}>
                <ActionIcon
                  color="indigo"
                  onClick={() => handleEditProperty(property)}
                >
                  <IconEdit />
                </ActionIcon>
                <ActionIcon
                  color="red"
                  onClick={() => handleDeleteProperty(property.propertyName)}
                  loading={loading.delete && actionOnPropertyName == property.propertyName}
                >
                  <IconTrash />
                </ActionIcon>
              </Group>
            </Group>
          </Card>
        ))}

        {/* "Add Property" at the bottom, full width */}
        <Button
          mt="md"
          variant="outline"
          color="indigo"
          fullWidth
          rightIcon={<IconPlus size={14} />}
          disabled={groupId.includes('default')}
          onClick={() => {
            setFormOpen(true);
            setEditProperty(null);
          }}
        >
          Add Property
        </Button>

        <PropertyEditorForm
          opened={formOpen}
          onClose={() => {
            setFormOpen(false);
            setEditProperty(null);
          }}
          onSubmit={handleSubmitProperty}
          parentGroup={{
            object: group.object,
            attribute: group.attribute,
          }}
          initialValues={editProperty || undefined}
          editProperty={editProperty}
          loading={loading.add || loading.update}
        />
      </Box>
    </Paper>
  );
};